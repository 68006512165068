import React, { useState } from "react";
import Image30 from "../../images/food-image.svg";
import circleIcon from "../../images/circle1.svg";
import documentsIcon from "../../images/documents.svg";
import howIcon from "../../images/Time.svg";
import Price from "../../images/Price Tag.svg";
// import Affidavit from '../senior/SeniorCitizen';

const GST = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [current, setCurrent] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isCompleted, setIsCompleted] = useState(false);
  const closePopup = () => {
    setShowPopup(false);
    setCurrentStep(1);
    setIsCompleted(false);
  };

  // Navigate steps
  const nextStep = () => {
    if (currentStep < 2) setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const finishSubmission = () => {
    setIsCompleted(true);
  };

  const [otp, setOtp] = useState(["", "", "", ""]);
  const [resendCountdown, setResendCountdown] = useState(30);
  const [isResending, setIsResending] = useState(false);

  // Handle OTP input
  const handleChange = (value, index) => {
    if (!/^\d*$/.test(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically move to the next input
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);
    }
  };

  // Resend OTP functionality
  const handleResend = () => {
    setIsResending(true);
    setTimeout(() => {
      setResendCountdown(30); // Reset countdown
      setIsResending(false);
    }, 1000); // Simulate resend delay
  };

  // Verify OTP
  const handleVerify = () => {
    const enteredOtp = otp.join("");
    if (enteredOtp.length === 4) {
      alert(`OTP Verified: ${enteredOtp}`);
    } else {
      alert("Please enter a valid 4-digit OTP.");
    }
  };

  // Countdown timer for resend button
  React.useEffect(() => {
    if (resendCountdown > 0) {
      const timer = setInterval(
        () => setResendCountdown(resendCountdown - 1),
        1000
      );
      return () => clearInterval(timer);
    }
  }, [resendCountdown]);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      <div
        style={{
          background: "linear-gradient(182.42deg, #FCA505 2.01%, #FFFFFF)",
          minHeight: "60vh",
          paddingTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 20px",
        }}
      >
        <div style={{ flex: 1, textAlign: "left", fontWeight: "bold" }}>
          <h2
            style={{
              marginLeft: "10%",
              marginBottom: "60px",
              fontWeight: "bold",
            }}
          >
            GST Registration Online
          </h2>

          {/* Form section */}
        </div>

        <div>
          <img
            src={Image30}
            alt="Lease Agreement"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      </div>

      <div style={{ marginTop: "-37px", marginLeft: "171px" }}>
        <div style={{ marginBottom: "20px" }}>
          <label
            htmlFor="applyFor"
            style={{
              display: "block",
              fontWeight: "bold",
              color: "#1A76D8",
              fontSize: "25px",
            }}
          >
            Documents Required For GST Certificate (Soft Copy)
          </label>
          <ul
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(1, 1fr)",
              columnGap: "20px",
              rowGap: "20px",
              listStyleType: "disc",
              paddingLeft: "20px",
              whiteSpace: "nowrap",
              margin: "20px 0",
            }}
          >
            <li style={{ fontWeight: "600" }}>Aadhar Card</li>
            <li style={{ fontWeight: "600" }}>Pan Card</li>
            <li style={{ fontWeight: "600" }}>Bank Account Proof</li>
            <li style={{ fontWeight: "600" }}>One Passport Size Photo</li>
            <li style={{ fontWeight: "600" }}>
              Address proof of business premises Electricity bill from owner /
              Rent Agreement / NOC
            </li>
          </ul>
        </div>
      </div>

      <div
        className="content-section"
        style={{
          backgroundColor: "#fffff",
          padding: "30px 15px",
          borderRadius: "10px",
          margin: "3% auto",
          marginRight: "72%",
        }}
      >
        <div className="row justify-content-center">
          {/* Main Column for Vertical Layout */}
          <div className="col-12 col-md-8 position-relative">
            {/* First Section: Documents */}
            <div className="text-center mb-5">
              <div style={{ position: "relative" }}>
                <img
                  src={circleIcon}
                  alt="Circle Background"
                  className="img-fluid"
                />
                <img
                  src={documentsIcon}
                  alt="Documents Icon"
                  style={{
                    position: "absolute",
                    top: "45%",
                    left: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
            </div>

            <div
              style={{
                borderLeft: "3px solid #007BFF",
                height: "100%",
                // margin: '0 auto',
                width: "4px",
                marginTop: "-20%",
                marginLeft: "50%",
              }}
            ></div>

            {/* Second Section: How It Works */}
            <div className="text-center mb-5">
              <div style={{ position: "relative" }}>
                <img
                  src={circleIcon}
                  alt="Circle Background"
                  className="img-fluid"
                />
                <img
                  src={howIcon}
                  alt="How It Works Icon"
                  style={{
                    position: "absolute",
                    top: "45%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
            </div>

            {/* Blue Line */}
            <div
              style={{
                borderLeft: "3px solid #007BFF",
                height: "150%",
                // margin: '0 auto',
                marginLeft: "49.8%",
                marginTop: "-48%",
                width: "4px",
              }}
            ></div>

            {/* Third Section */}
            <div className="text-center mb-5">
              <div style={{ position: "relative" }}>
                <img
                  src={circleIcon}
                  alt="Circle Background"
                  className="img-fluid"
                />
                <img
                  src={Price}
                  alt="How It Works Icon"
                  style={{
                    position: "absolute",
                    top: "45%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <>
          <div
            className="mb-5"
            style={{
              marginTop: "-45%",
              marginLeft: "70%",
            }}
          >
            <h4
              style={{
                color: "#007BFF",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
            >
              How It Works
            </h4>
            <ul
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                columnGap: "40px",
                rowGap: "20px",
                listStyleType: "disc",
                paddingLeft: "20px",
                whiteSpace: "nowrap",
                margin: "20px 0",
              }}
            >
              <li style={{ fontWeight: "bold" }}>Register Online</li>
              <li style={{ fontWeight: "bold" }}>Upload Documents</li>
              <li style={{ fontWeight: "bold" }}>Payment</li>
              <li style={{ fontWeight: "bold" }}>Get delivered</li>
            </ul>
          </div>
          <div style={{ marginLeft: "72%", marginTop: "36%" }}>
            <h4
              style={{
                color: "#007BFF",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
            >
              Time Duration
            </h4>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "20px",
                lineHeight: "1.8",
                whiteSpace: "nowrap",
              }}
            >
              <li style={{ fontWeight: "bold", marginBottom: "10px" }}>
                1-2 working days
              </li>
            </ul>
          </div>
          <div style={{ marginLeft: "72%", marginTop: "53%" }}>
            <h4
              style={{
                color: "#007BFF",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
            >
              Charges
            </h4>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "20px",
                lineHeight: "1.8",
                whiteSpace: "nowrap",
              }}
            >
              <li style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Rs 500
              </li>
              <li style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Rs 99 as booking/consulting charge. Need to pay while submitting
                online form
              </li>
              {/* <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Note: Additional charges for stamp paper</li> */}
            </ul>
          </div>
        </>
      </div>
      <div>
        {/* Get Quotes Button */}
        <div style={{ textAlign: "center", marginTop: "4%" }}>
          <button
            style={{
              backgroundColor: "#FCA505",
              color: "#000000",
              padding: "12px 50px",
              border: "none",
              borderRadius: "30px",
              fontWeight: "bold",
              cursor: "pointer",
              fontSize: "16px",
              marginRight: "40%",
              marginTop: "-30%",
            }}
            onClick={() => setShowPopup(true)}
          >
            CONTINUE
          </button>
        </div>
        {/* Modal Popup */}
        {showPopup && (
          <div
            style={{
              position: "fixed",
              top: "100px",
              left: "0",
              width: "100%",
              height: "86%",
              backgroundColor: "rgba(26, 118, 216, 0.9)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "9999",
            }}
          >
            <div
              style={{
                backgroundColor: "#FFFFFF",
                padding: "40px",
                borderRadius: "28px",
                width: "70%",
                maxHeight: "90%", // Maximum height of the popup
                overflowY: "auto", // Enable scrolling if content overflows
                textAlign: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              {/* Stepper */}

              {!isCompleted ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "30px",
                    }}
                  >
                    {Array.from({ length: 2 }).map((_, index) => (
                      <React.Fragment key={index}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            flex: 1,
                          }}
                        >
                          <button
                            onClick={() => setCurrentStep(index + 1)} // Make step clickable
                            style={{
                              width: "50px",
                              height: "50px",
                              backgroundColor:
                                currentStep >= index + 1 ? "#4285F4" : "#ccc",
                              color: "white",
                              borderRadius: "50%",
                              lineHeight: "50px",
                              fontWeight: "bold",
                              border: "none",
                              cursor: "pointer",
                              outline: "none",
                            }}
                          >
                            {index + 1}
                          </button>
                          {/* Optional Step Labels */}
                          {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step {index + 1}</span> */}
                        </div>

                        {/* Add Connection Divider Between Steps */}
                        {index < 1 && (
                          <div
                            style={{
                              height: "2px",
                              flex: 1,
                              backgroundColor:
                                currentStep > index + 1 ? "#4285F4" : "#ccc",
                              alignSelf: "center",
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </div>

                  <div style={{ marginBottom: "20px" }}>
                    {currentStep === 1 && (
                      <div style={{ textAlign: "center" }}>
                        <h4 style={{ color: "#1A76D8", fontWeight: "600" }}>
                          Application
                        </h4>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "10px",
                            marginBottom: "20px",
                            textAlign: "start",
                          }}
                        >
                          <div style={{ width: "45%" }}>
                            <label
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#333",
                              }}
                            >
                              Name<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              placeholder=""
                              style={{
                                width: "100%",
                                padding: "8px",
                                marginTop: "5px",
                                border: "2px solid #FCA505",
                                borderRadius: "10px",
                              }}
                            />
                          </div>
                          <div style={{ width: "45%" }}>
                            <label
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#333",
                              }}
                            >
                              Mobile Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              placeholder=""
                              style={{
                                width: "100%",
                                padding: "8px",
                                marginTop: "5px",
                                border: "2px solid #FCA505",
                                borderRadius: "10px",
                              }}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "10px",
                            marginBottom: "20px",
                            textAlign: "start",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <label
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#333",
                              }}
                            >
                              Email Id<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              placeholder=""
                              style={{
                                width: "100%",
                                padding: "8px",
                                marginTop: "5px",
                                border: "2px solid #FCA505",
                                borderRadius: "10px",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "10px",
                            marginBottom: "20px",
                            textAlign: "start",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <label
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#333",
                              }}
                            >
                              Address<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              placeholder=""
                              style={{
                                width: "100%",
                                padding: "8px",
                                marginTop: "5px",
                                border: "2px solid #FCA505",
                                borderRadius: "10px",
                              }}
                            />
                          </div>
                        </div>
                        <p
                          style={{
                            marginTop: "20px",
                            fontSize: "14px",
                            textAlign: "left",
                          }}
                        >
                          By clicking submit, you agree to our{" "}
                          <a
                            href="/terms"
                            style={{
                              color: "#007BFF",
                              textDecoration: "underline",
                            }}
                          >
                            Terms & Conditions
                          </a>{" "}
                          and{" "}
                          <a
                            href="/privacy-policy"
                            style={{
                              color: "#007BFF",
                              textDecoration: "underline",
                            }}
                          >
                            Privacy Policy
                          </a>
                          .
                        </p>
                      </div>
                    )}

                    {currentStep === 2 && (
                      <div style={{ textAlign: "center", padding: "20px" }}>
                        <h4
                          style={{
                            color: "#007BFF",
                            fontWeight: "bold",
                            display: "inline-block",
                            marginRight: "10px",
                          }}
                        >
                          OTP sent on 97******8
                        </h4>
                        <a
                          href="#"
                          onClick={() => setCurrent(1)} // Redirect to step 1
                          style={{
                            fontSize: "14px",
                            color: "#007BFF",
                            textDecoration: "none",
                          }}
                        >
                          Edit
                        </a>

                        <div style={{ margin: "20px 0" }}>
                          <label
                            style={{
                              display: "block",
                              fontWeight: "500",
                              marginBottom: "10px",
                            }}
                          >
                            Enter OTP <span style={{ color: "red" }}>*</span>
                          </label>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                          >
                            {otp.map((digit, index) => (
                              <input
                                key={index}
                                id={`otp-input-${index}`}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={(e) =>
                                  handleChange(e.target.value, index)
                                }
                                onKeyDown={(e) => handleBackspace(e, index)}
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  textAlign: "center",
                                  fontSize: "18px",
                                  border: "1px solid #ccc",
                                  borderRadius: "5px",
                                }}
                              />
                            ))}
                          </div>
                        </div>

                        <p style={{ fontSize: "14px", color: "#888" }}>
                          {resendCountdown > 0 ? (
                            <>Not Received? Resend in {resendCountdown}s</>
                          ) : (
                            <a
                              href="#"
                              onClick={handleResend}
                              style={{
                                textDecoration: "none",
                                color: isResending ? "#ccc" : "#007BFF",
                                pointerEvents: isResending ? "none" : "auto",
                              }}
                            >
                              Resend OTP
                            </a>
                          )}
                        </p>

                        <button
                          onClick={handleVerify}
                          style={{
                            backgroundColor: "#FFA500",
                            color: "#00000",
                            border: "none",
                            borderRadius: "5px",
                            padding: "10px 20px",
                            fontSize: "16px",
                            cursor: "pointer",
                          }}
                        >
                          VERIFY
                        </button>
                      </div>
                    )}
                  </div>
                  <div>
                    {currentStep < 1 ? (
                      <button
                        onClick={nextStep}
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#FCA505",
                          color: "#000000",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        onClick={finishSubmission}
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#FCA505",
                          color: "#000000",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        NEXT
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <div style={{ textAlign: "center", padding: "20px" }}>
                  <h4
                    style={{
                      color: "#007BFF",
                      fontWeight: "bold",
                      display: "inline-block",
                      marginRight: "10px",
                    }}
                  >
                    OTP sent on 97******8
                  </h4>
                  <a
                    href="#"
                    onClick={() => setCurrent(1)} // Redirect to step 1
                    style={{
                      fontSize: "14px",
                      color: "#007BFF",
                      textDecoration: "none",
                    }}
                  >
                    Edit
                  </a>

                  <div style={{ margin: "20px 0" }}>
                    <label
                      style={{
                        display: "block",
                        fontWeight: "500",
                        marginBottom: "10px",
                      }}
                    >
                      Enter OTP <span style={{ color: "red" }}>*</span>
                    </label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          id={`otp-input-${index}`}
                          type="text"
                          maxLength="1"
                          value={digit}
                          onChange={(e) => handleChange(e.target.value, index)}
                          onKeyDown={(e) => handleBackspace(e, index)}
                          style={{
                            width: "50px",
                            height: "50px",
                            textAlign: "center",
                            fontSize: "18px",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                          }}
                        />
                      ))}
                    </div>
                  </div>

                  <p style={{ fontSize: "14px", color: "#888" }}>
                    {resendCountdown > 0 ? (
                      <>Not Received? Resend in {resendCountdown}s</>
                    ) : (
                      <a
                        href="#"
                        onClick={handleResend}
                        style={{
                          textDecoration: "none",
                          color: isResending ? "#ccc" : "#007BFF",
                          pointerEvents: isResending ? "none" : "auto",
                        }}
                      >
                        Resend OTP
                      </a>
                    )}
                  </p>

                  <button
                    onClick={handleVerify}
                    style={{
                      backgroundColor: "#FFA500",
                      color: "#00000",
                      border: "none",
                      borderRadius: "5px",
                      padding: "10px 20px",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                  >
                    VERIFY
                  </button>
                </div>
              )}

              {/* Close Button */}
              <button
                onClick={closePopup}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "none",
                  border: "none",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              >
                &times;
              </button>
            </div>
          </div>
        )}
      </div>

      <br></br>
    </>
  );
};

export default GST;
