import React, { useState } from 'react';
import Image30 from '../../images/leaseimage.svg'; 
import circleIcon from '../../images/circle1.svg'; 
import documentsIcon from '../../images/documents.svg'; 
import howIcon from '../../images/Time.svg'; 
import Price from '../../images/Price Tag.svg';

const Lease = () => {
    
    const [openIndex, setOpenIndex] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [isCompleted, setIsCompleted] = useState(false);
    const closePopup = () => {
        setShowPopup(false);
        setCurrentStep(1);
        setIsCompleted(false);
      };
    
      // Navigate steps
      const nextStep = () => {
        if (currentStep < 7) setCurrentStep(currentStep + 1);
      };
    
      const prevStep = () => {
        if (currentStep > 1) setCurrentStep(currentStep - 1);
      };

      const finishSubmission = () => {
        setIsCompleted(true); 
      };



      const faqs = [
        {
            question: "Benefits of Lease Agreement?",
            answer: (
              <ul style={{ listStyleType: 'disc' }}>
                <li>Minimizes the conflicts between a tenant and the owner</li>
                <li>Rental/lease agreement acts as an address proof</li>
                <li>Acts as a proof for Bank loans</li>
                <li>Helps in investment & loan</li>
                <li>Vehicle registration</li>
              </ul>
            ),
          },
        {
            question: "Difference between comprehensive and third-party liability?",
            answer: "Comprehensive insurance covers both third-party liabilities and damage to your own vehicle, while third-party liability only covers damage caused to others.",
        },
        {
            question: "What is the different between rental/lease agreement?",
            answer: "Leasing is defined as a contract between lessor and lessee whereby the lessor buys the asset and lets the lessee use the asset for a particular period. Renting is to allow the other party to occupy or use the asset for a short time, in return for a fixed payment.",
        },
        {
            question: "what is stamp paper?",
            answer: "Stamp papers which are used for execution of documents are called as Non-Judicial Stamp Papers. ... Stamp Duty paid in respect of Non-Judicial Stamp Paper is paid under The Indian Stamp Act, 1899 and Stamp Duty paid in respect of Judicial Stamp Paper is paid under the Court Fees Act, 1870",
        },
        {
            question: "Do I need to visit any office to get lease agreement?",
            answer: "No its completely online process once you registered in our website we create draft and it will be print on stamp paper, and it get dispatched to address.",
        },
        {
            question: "How will I get my lease agreement?",
            answer: "It will get dispatched through courier.",
        },
        {
            question: "What is the value of stamp paper for lease agreement?",
            answer: "The Rental/Lease agreement must be printed on a Non-Judicial Stamp Paper with a value of Rs.100/- or more.",
        },
        {
            question: "What is validity of lease agreement?",
            answer: "In the usual practice, a landlord and a tenant make a rent agreement for a period of 11 months, with an option for a periodic renewal.",
        },
        {
            question: "what is Notarized lease agreement?",
            answer: "A notarized agreement is a document that has been marked with a notary stamp, which indicates that the signature on the document is legal. A notary officers is the witness when you sign and then places the stamp near your signature.",
        },
        {
            question: "Is agreement required to be notarised?",
            answer: "A contract typically does not have to be notarized. A notary public  provides an acknowledgment that the signature appearing on the document is that of the person whose signature it purports to be. There is a requirement that some documents be notarized, such as a real property deed.",
        },
    ];

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };


    return (
        <>
            <div style={{
                background: 'linear-gradient(182.42deg, #FCA505 2.01%, #FFFFFF)',
                minHeight: '60vh',
                paddingTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 20px'
            }}>
                <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold' }}>

                    <h2 style={{marginLeft:"25%", marginBottom:"60px",fontWeight:"bold"}}>Lease Agreement Online</h2>
                    <p>"Find Peace of Mind with our Comprehensive lease Agreement</p>
                    <p>Services - Simplifying the Lease Agreement Process!"</p>
                </div>
                <div>
                    <img src={Image30} alt="Lease Agreement" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
            </div>

            <div style={{ padding: '10px', textAlign: 'left',marginLeft:"8%" }}>
                <h2 style={{ color: '#1A76D8', fontWeight: 'bold', marginLeft:"20px" }}>What We Do!</h2>
                <p style={{ textAlign:'left', padding:"2%", fontWeight:"600"}}>
                    <span style={{  }}>Make My Documents Online Agreements Service.</span><br />
                    <span style={{ }}>Service available only in Karnataka.</span><br />
                    <span style={{  }}>Draft will be shared to your mail id within 60min.</span>
                </p>
            </div>

            <div
                className="content-section"
                style={{
                    backgroundColor: '#fffff',
                    padding: '30px 15px',
                    borderRadius: '10px',
                    margin: '-1% auto',
                    marginRight:'72%',
                }}
            >
                <div className="row justify-content-center">
                    {/* Main Column for Vertical Layout */}
                    <div className="col-12 col-md-8 position-relative">
                        {/* First Section: Documents */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={documentsIcon}
                                    alt="Documents Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div> 
                        </div>
                    
           
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '100%',
                                // margin: '0 auto',
                                width: '4px',
                                marginTop:'-20%',
                                marginLeft:'50%'
                            }}
                        ></div>

                        {/* Second Section: How It Works */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={howIcon}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>

                        {/* Blue Line */}
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '150%',
                                // margin: '0 auto',
                                marginLeft:'49.8%',
                                marginTop:'-48%',
                                width: '4px',
                            }}
                        ></div>

                        {/* Third Section */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={Price}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>
                       
            
                    </div>
                </div>
             <>
             <div
    className="mb-5"
    style={{
        marginTop: '-45%',
        marginLeft: '70%',
    }}
>
    <h4
        style={{
            color: '#007BFF',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
        }}
    >
        How It Works
    </h4>
    <ul
        style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)', 
            columnGap: '40px', 
            rowGap: '20px', 
            listStyleType: 'disc', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
        <li style={{ fontWeight: 'bold' }}>Register Online</li>
        <li style={{ fontWeight: 'bold' }}>Drafting</li>
        <li style={{ fontWeight: 'bold' }}>Review Drafting</li>
        <li style={{ fontWeight: 'bold' }}>Payment</li>
        <li style={{ fontWeight: 'bold' }}>Doorstep Delivery</li>
    </ul>
</div>
                <div style={{marginLeft:'72%',marginTop:'36%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>Time Duration</h4>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>1-2 working days</li>
                    </ul>
                </div>
                <div style={{marginLeft:'72%',marginTop:'53%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>Charges</h4>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Rs 300</li>
        <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Rs 50 as booking/consulting charge. Need to pay while submitting online form</li>
        <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Note: Additional charges for stamp paper</li>
       
                    </ul>
                </div>
                </>
            </div>
            <div>
   
   {/* Get Quotes Button */}
   <div style={{ textAlign: "center", marginTop: "4%" }}>
     <button
       style={{
         backgroundColor: "#FCA505",
         color: "#000000",
         padding: "12px 50px",
         border: "none",
         borderRadius: "30px",
         fontWeight: "bold",
         cursor: "pointer",
         fontSize: "16px",
         marginRight:"40%",
         marginTop:"-30%",
       }}
       onClick={() => setShowPopup(true)}
     >
       CONTINUE
     </button>
   
   
 </div>
   {/* Modal Popup */}
   {showPopup && (
     <div
       style={{
        position: "fixed",
            top: "100px",
            left: "0",
            width: "100%",
            height: "86%",
            backgroundColor: "rgba(26, 118, 216, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "9999",
       }}
     >
       <div
         style={{
           backgroundColor: "#FFFFFF",
           padding: "40px",
           borderRadius: "28px",
           width: "70%",
           maxHeight: "90%", // Maximum height of the popup
           overflowY: "auto", // Enable scrolling if content overflows
           textAlign: "center",
           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
         }}
       >
         {/* Stepper */}
       

         {!isCompleted ? (
           <>
   <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
  {Array.from({ length: 7 }).map((_, index) => (
    <React.Fragment key={index}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <button
          onClick={() => setCurrentStep(index + 1)} // Make step clickable
          style={{
            width: "50px",
            height: "50px",
            backgroundColor: currentStep >= index + 1 ? "#4285F4" : "#ccc",
            color: "white",
            borderRadius: "50%",
            lineHeight: "50px",
            fontWeight: "bold",
            border: "none",
            cursor: "pointer",
            outline: "none",
          }}
        >
          {index + 1}
        </button>
        {/* Optional Step Labels */}
        {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step {index + 1}</span> */}
      </div>

      {/* Add Connection Divider Between Steps */}
      {index < 6 && (
        <div
          style={{
            height: "2px",
            flex: 1,
            backgroundColor: currentStep > index + 1 ? "#4285F4" : "#ccc",
            alignSelf: "center",
          }}
        />
      )}
    </React.Fragment>
  ))}
</div>



      
         <div style={{ marginBottom: "20px" }}>
         {currentStep === 1 && (
  <div style={{ textAlign: "center" }}>
   
    <h4 style={{ color: "#00000", fontWeight: "400" }}>
      Agreement
    </h4>


    <h5 style={{ color: "#007BFF", fontWeight: "bold" }}>
      I Want to get
      <span style={{ color: "red" }}>*</span>
    </h5>

    {/* Radio Buttons */}
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "4%",
        width: "fit-content",
        gap: "20px",
      }}
    >
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="flatHouseLeaseAgreement"
          style={{ marginRight: "10px" }}
        />
        Flat/House Lease Agreement
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopLeaseAgreement"
          style={{ marginRight: "10px" }}
        />
        Commercial Office/Shop Lease Agreement
      </label>
    </div>
  </div>
)}



{currentStep === 2 && (
  <div style={{ textAlign: "center" }}>
    {/* Step 3 Heading */}
    <h5 style={{ color: "#007BFF", fontWeight: "bold" }}>
      I Am
      <span style={{ color: "red" }}>*</span>
    </h5>

  
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "4%",
        width: "fit-content",
        gap: "20px",
      }}
    >
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="identityOption"
          value="owner"
          style={{ marginRight: "10px" }}
        />
        Owner
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="identityOption"
          value="tenant"
          style={{ marginRight: "10px" }}
        />
        Tenant
      </label>
    </div>

    {/* Required Stamp Paper Dropdown */}
    <div style={{ marginBottom: "33px", textAlign: "left" , marginTop:"60px" }}>
      <label
        htmlFor="stampPaper"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Required Stamp Paper
        <span style={{ color: "red" }}>*</span>
      </label>
      <select
        id="stampPaper"
        style={{
          width: "100%",
          padding: "10px",
          fontSize: "16px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      >
        <option value="">Choose</option>
        <option value="100Rs">100Rs</option>
        <option value="200Rs">200Rs</option>
        <option value="20Rs">500Rs</option>
        <option value="20Rs">400Rs</option>
        <option value="20Rs">300Rs</option>
      </select>
    </div>
  </div>
)}


{currentStep === 3 && (
  <div style={{ textAlign: "center" }}>
    {/* Step 3 Heading */}
    <p style={{ color: "#1A76D8", fontWeight: "600" }}>
      Owner's Details<span style={{ color: "red" }}>*</span>
    </p>

    {/* Owner's Name & Age */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="ownerName"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Owner's Name & Age
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        id="ownerName"
        placeholder="Enter Owner's Name & Age"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    {/* Owner's Father's Name */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="ownerFatherName"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Owner's Father's Name
      </label>
      <input
        type="text"
        id="ownerFatherName"
        placeholder="Enter Owner's Father's Name"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    {/* Owner's Address */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="ownerAddress"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Owner's Address
        <span style={{ color: "red" }}>*</span>
      </label>
      <textarea
        id="ownerAddress"
        placeholder="Enter Owner's Address"
        style={{
          width: "100%",
          height: "90px", // Increased height for address
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    
  </div>
)}


{currentStep === 4 && (
           <div style={{ textAlign: "center" }}>
           {/* Step 3 Heading */}
           <p style={{ color: "#1A76D8", fontWeight: "600" }}>
            Tenant Details<span style={{ color: "red" }}>*</span>
           </p>
       
           {/* Owner's Name & Age */}
           <div style={{ marginBottom: "20px", textAlign: "left" }}>
             <label
               htmlFor="TenantName"
               style={{
                 display: "block",
                 marginBottom: "10px",
                 fontSize: "16px",
                 fontWeight: "500",
               }}
             >
               Tenant Name & Age
               <span style={{ color: "red" }}>*</span>
             </label>
             <input
               type="text"
               id="ownerName"
               placeholder="Enter Tenant Name & Age"
               style={{
                 width: "100%",
                 height: "45px",
                 padding: "10px",
                 fontSize: "16px",
                 border: "2px solid #FCA505",
                 borderRadius: "4px",
               }}
             />
           </div>
       
           {/* Owner's Father's Name */}
           <div style={{ marginBottom: "20px", textAlign: "left" }}>
             <label
               htmlFor="TenantFatherName"
               style={{
                 display: "block",
                 marginBottom: "10px",
                 fontSize: "16px",
                 fontWeight: "500",
               }}
             >
               Tenant Father's Name
             </label>
             <input
               type="text"
               id="TenantFatherName"
               placeholder="Enter Owner's Father's Name"
               style={{
                 width: "100%",
                 height: "45px",
                 padding: "10px",
                 fontSize: "16px",
                 border: "2px solid #FCA505",
                 borderRadius: "4px",
               }}
             />
           </div>
       
           {/* Owner's Address */}
           <div style={{ marginBottom: "20px", textAlign: "left" }}>
             <label
               htmlFor="TenantAddress"
               style={{
                 display: "block",
                 marginBottom: "10px",
                 fontSize: "16px",
                 fontWeight: "500",
               }}
             >
               Tenant Address
               <span style={{ color: "red" }}>*</span>
             </label>
             <textarea
               id="TenantAddress"
               placeholder="Enter Tenant Address"
               style={{
                 width: "100%",
                 height: "90px", // Increased height for address
                 padding: "10px",
                 fontSize: "16px",
                 border: "2px solid #FCA505",
                 borderRadius: "4px",
               }}
             />
           </div>
       
           
         </div>
)}
          {currentStep === 5 && (
           <div style={{ textAlign: "center" }}>
          
           <p style={{ color: "#1A76D8", fontWeight: "600" }}>
            Other Info<span style={{ color: "red" }}>*</span>
           </p>
       
           {/* Owner's Name & Age */}
           <div style={{ marginBottom: "20px", textAlign: "left" }}>
             <label
               htmlFor="ShifterDate"
               style={{
                 display: "block",
                 marginBottom: "10px",
                 fontSize: "16px",
                 fontWeight: "500",
               }}
             >
               Shifted Date
               <span style={{ color: "red" }}>*</span>
             </label>
             <input
               type="text"
               id="ownerName"
               placeholder=""
               style={{
                 width: "100%",
                 height: "45px",
                 padding: "10px",
                 fontSize: "16px",
                 border: "2px solid #FCA505",
                 borderRadius: "4px",
               }}
             />
           </div>
       
           {/* Owner's Father's Name */}
           
       
           {/* Owner's Address */}
           <div style={{ marginBottom: "20px", textAlign: "left" }}>
             <label
               htmlFor="ShiftingAddress"
               style={{
                 display: "block",
                 marginBottom: "10px",
                 fontSize: "16px",
                 fontWeight: "500",
               }}
             >
               Shifting Address
               <span style={{ color: "red" }}>*</span>
             </label>
             <textarea
               id="TenantAddress"
               placeholder=""
               style={{
                 width: "100%",
                 height: "90px", // Increased height for address
                 padding: "10px",
                 fontSize: "16px",
                 border: "2px solid #FCA505",
                 borderRadius: "4px",
               }}
             />
           </div>

           <div style={{ marginBottom: "20px", textAlign: "left" }}>
             <label
               htmlFor="SecurityDeposit"
               style={{
                 display: "block",
                 marginBottom: "10px",
                 fontSize: "16px",
                 fontWeight: "500",
               }}
             >
               Security Deposit 
             </label>
             <input
               type="text"
               id="TenantFatherName"
               placeholder="Enter Owner's Father's Name"
               style={{
                 width: "100%",
                 height: "45px",
                 padding: "10px",
                 fontSize: "16px",
                 border: "2px solid #FCA505",
                 borderRadius: "4px",
               }}
             />
           </div>
           <div style={{ marginBottom: "20px", textAlign: "left" }}>
             <label
               htmlFor="MonthlyRent"
               style={{
                 display: "block",
                 marginBottom: "10px",
                 fontSize: "16px",
                 fontWeight: "500",
               }}
             >
               Monthly Rent 
             </label>
             <input
               type="text"
               id="TenantFatherName"
               placeholder=""
               style={{
                 width: "100%",
                 height: "45px",
                 padding: "10px",
                 fontSize: "16px",
                 border: "2px solid #FCA505",
                 borderRadius: "4px",
               }}
             />
           </div>
           
         </div>
)}
{currentStep === 6 && (
  <div style={{ textAlign: "center" }}>
    <div style={{ marginBottom: "20px" }}>
      <label style={{ fontSize: "20px", fontWeight: "600" }}>
       Advance Paid Through  <span style={{ color: "red" }}>*</span>
      </label>
      <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "4%",
        width: "fit-content",
        gap: "20px",
      }}
    >
      <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="identityOption"
          value="owner"
          style={{ marginRight: "10px" }}
        />
        Cash
      </label>
      <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="identityOption"
          value="tenant"
          style={{ marginRight: "10px" }}
        />
        Cheque 
      </label>
      <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="identityOption"
          value="tenant"
          style={{ marginRight: "10px" }}
        />
        Net Banking 
      </label>
    </div>
    </div>

    <div style={{ textAlign: "left" }}>
      <label style={{ fontSize: "16px", fontWeight: "bold" }}>
         Water Charges<span style={{ color: "red" }}>*</span>
      </label>
      <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "4%",
        width: "fit-content",
        gap: "9px",
        marginTop:"20px"
      }}
    >
      <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="identityOption"
          value="owner"
          style={{ marginRight: "10px" }}
        />
        Incuded in rent 
      </label>
      <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="identityOption"
          value="tenant"
          style={{ marginRight: "10px" }}
        />
        Excluded
      </label>
    </div>

    </div>

    <div style={{ marginBottom: "40px" ,textAlign:"left", marginTop:"26px"}}>
      <label style={{ fontSize: "16px", fontWeight: "bold" }}>
         Painting Charges<span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        placeholder="Enter painting charges"
        style={{
          width: "100%",
          padding: "10px",
          marginTop: "10px",
          border: "1px solid orange",
          borderRadius: "4px",
        }}
      />
    </div>

    <div style={{ marginBottom: "40px" ,textAlign:"left"}}>
      <label style={{ fontSize: "16px", fontWeight: "bold" }}>
       Accommodation  <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        placeholder="Enter accommodation details"
        style={{
          width: "100%",
          padding: "10px",
          marginTop: "10px",
          border: "1px solid orange",
          borderRadius: "4px",
        }}
      />
    </div>

    <div style={{ marginBottom: "40px" ,textAlign:"left", marginTop:"-4px"}}>
      <label style={{ fontSize: "16px", fontWeight: "bold" }}>
       Appliances/Fittings Details  <span style={{ color: "red" }}>*</span> 
      </label>
      <textarea
        placeholder="Enter appliances and fitting details"
        style={{
          width: "100%",
          padding: "10px",
          marginTop: "10px",
          border: "1px solid orange",
          borderRadius: "4px",
          minHeight: "100px",
        }}
      />
    </div>
  </div>
)}


          {currentStep === 7 && (
         <div style={{ textAlign: "center" }}>
         {/* Step 3 Heading */}
         <p style={{ color: "#1A76D8", fontWeight: "600" }}>
           Contact Details<span style={{ color: "red" }}>*</span>
         </p>
            

         <div style={{ marginBottom: "20px", textAlign: "left" }}>
           <label
             htmlFor="ownerAddress"
             style={{
               display: "block",
               marginBottom: "10px",
               fontSize: "16px",
               fontWeight: "500",
             }}
           >
             Shipping Address
             <span style={{ color: "red" }}>*</span>
           </label>
           <textarea
             id="ownerAddress"
             placeholder=""
             style={{
               width: "100%",
               height: "90px", // Increased height for address
               padding: "10px",
               fontSize: "16px",
               border: "2px solid #FCA505",
               borderRadius: "4px",
             }}
           />
         </div>
         {/* Owner's Name & Age */}
         <div style={{ marginBottom: "20px", textAlign: "left" }}>
           <label
             htmlFor="ownerName"
             style={{
               display: "block",
               marginBottom: "10px",
               fontSize: "16px",
               fontWeight: "500",
             }}
           >
             Mobile Number
             <span style={{ color: "red" }}>*</span>
           </label>
           <input
             type="text"
             id="ownerName"
             placeholder=""
             style={{
               width: "100%",
               height: "45px",
               padding: "10px",
               fontSize: "16px",
               border: "2px solid #FCA505",
               borderRadius: "4px",
             }}
           />
         </div>
     
         {/* Owner's Father's Name */}
         <div style={{ marginBottom: "20px", textAlign: "left" }}>
           <label
             htmlFor="ownerFatherName"
             style={{
               display: "block",
               marginBottom: "10px",
               fontSize: "16px",
               fontWeight: "500",
             }}
           >
            Email ID
           </label>
           <input
             type="text"
             id="ownerFatherName"
             placeholder=""
             style={{
               width: "100%",
               height: "45px",
               padding: "10px",
               fontSize: "16px",
               border: "2px solid #FCA505",
               borderRadius: "4px",
             }}
           />
         </div>
     
         {/* Owner's Address */}
       
     
         
       </div>
          )}

         </div>
         <div>
          
           {currentStep < 7 ? (
             <button
               onClick={nextStep}
               style={{
                 padding: "10px 20px",
                 backgroundColor: "#FCA505",
                 color: "#000000",
                 border: "none",
                 borderRadius: "5px",
                 cursor: "pointer",
               }}
             >
               Next
             </button>
           ) : (
             <button
             onClick={finishSubmission}
               style={{
                 padding: "10px 20px",
                 backgroundColor: "FCA505",
                 color: "#000000",
                 border: "none",
                 borderRadius: "5px",
                 cursor: "pointer",
               }}
             >
               SUBMIT
             </button>
           )} 
         </div>
         </>
         ) : (
             <div>
             {/* Green Checkmark Animation */}
             <div
               style={{
                 margin: "20px auto",
                 width: "100px",
                 height: "100px",
                 border: "5px solid green",
                 borderRadius: "50%",
                 display: "flex",
                 justifyContent: "center",
                 alignItems: "center",
               }}
             >
               <span
                 style={{
                   fontSize: "50px",
                   color: "green",
                 }}
               >
                 ✓
               </span>
             </div>

             {/* Thank-You Message */}
             <h2 style={{ color: "#007BFF", fontWeight: "bold", marginTop: "20px" }}>Thank You!</h2>
             <p style={{ marginTop: "10px", fontSize: "16px", color: "#555" }}>
               Your submission has been received.
             </p>
             <p style={{ marginTop: "10px", fontSize: "14px", color: "#555" }}>
               We appreciate your interest and will get back to you as soon as possible.
             </p>
           </div>
         )}

         {/* Close Button */}
         <button
           onClick={closePopup}
           style={{
             position: "absolute",
             top: "10px",
             right: "10px",
             background: "none",
             border: "none",
             fontSize: "20px",
             cursor: "pointer",
           }}
         >
           &times;
         </button>
       </div>
     </div>
   )}
 </div>

 <div className="faq-section" style={{ margin: '50px auto', padding: '20px', background: '#FFFFFF', borderRadius: '10px', width: '80%' }}>
            <h4 style={{ textAlign: 'center', fontWeight: 'bold', color: '#007BFF', marginBottom: '20px' }}>FAQs</h4>
            <p style={{ textAlign: 'center', fontWeight: '500', marginBottom: '30px' }}>Need help? Contact us for any queries related to us</p>
            <div className="faq-list">
                {faqs.map((faq, index) => (
                    <div key={index} className="faq-item" style={{ marginBottom: '10px' }}>
                        <button
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                padding: '10px 20px',
                                border: '1px solid #007BFF',
                                borderRadius: '5px',
                                background: '#F9F9F9',
                                fontWeight: '500',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleToggle(index)}
                        >
                            {faq.question}
                            <span style={{ fontWeight: 'bold', fontSize: '16px', marginLeft: '10px' }}>
                                {openIndex === index ? '▲' : '▼'}
                            </span>
                        </button>
                        {openIndex === index && (
                            <div
                                style={{
                                    marginTop: '10px',
                                    padding: '10px 20px',
                                    background: '#F3F3F3',
                                    borderRadius: '5px',
                                    color: '#333',
                                }}
                            >
                                {faq.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
<br></br>
            <>
            <p>Welcome to Make My Documents, your one-stop destination for all your Lease agreement needs. 
            We understand that renting a property can be a complex process, which is why we offer a hassle-free 
            solution to help you secure your Lease agreement quickly and easily.</p>

            <p>Our team of legal experts has extensive experience in drafting lease agreements that meet all legal requirements. 
            We provide comprehensive Lease agreement services, including new lease agreements, 
            Lease agreement renewals, and Lease agreement amendments. We also offer customized lease agreement 
            solutions tailored to your unique needs.</p>

            <p>At Make My Documents, we take pride in providing exceptional customer service. We understand that 
            your time is valuable, which is why we offer a seamless online Lease agreement application process.</p>

            <p>Our online Lease agreement form allows you to apply from the comfort of your own home, and our team will 
            guide you through every step of the process. Our lease agreement services include all the necessary legal clauses 
            and terms to protect both the landlord and the tenant. We also provide guidance on security deposits, 
            rent payment schedules, and any other special requirements you may have.</p>

            <p>Our Lease agreement services are competitively priced, so you won't have to break the bank to secure your 
            lease agreement. Plus, our fast turnaround times mean that you can have your lease agreement in hand as soon as possible.</p>

            <p>Whether you need a lease agreement for your house, room, or commercial property, Make My Documents has 
            got you covered. We offer e-registration of lease agreements, lease agreements, and notarized lease 
            agreements to suit your specific requirements. Our services also include guidance on stamp duty for 
            lease agreements, rent agreement validity, lease agreement cancellation, and lease agreement renewal.</p>

            <p>If you're in need of a lease agreement, look no further than Make My Documents. Contact us today to 
            learn more about our lease agreement services and to schedule an appointment with one of our legal experts. 
            We look forward to helping you secure your lease agreement and enjoy a hassle-free renting experience.</p>

            <p>We also ensure that our lease agreement services are compliant with the latest legal and regulatory requirements, 
            giving you peace of mind. Our easy-to-use online platform and step-by-step support make the entire process 
            smooth and efficient. At Make My Documents, we are committed to delivering reliable, transparent, and customer-focused 
            solutions to cater to your lease agreement needs. With our expertise and dedication, you can trust us to simplify your 
            lease agreement process, saving you time and effort.</p>
        </>
        </div>

        
        
        </>
    );
};

export default Lease;
