import React, { useState } from 'react';
import './InsurancePage.css'; 
import checklistIcon from '../images/notebook.svg';
import vehiclesIcon from '../images/Twowheeler.svg';
import circleIcon from '../images/circle1.svg'; 
import documentsIcon from '../images/documents.svg'; 
import howIcon from '../images/how.svg'; 
import thirdImage from '../images/third.svg'; 

const TwoWheeler = () => {

    const [openIndex, setOpenIndex] = useState(null); 
    const [showPopup, setShowPopup] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [isCompleted, setIsCompleted] = useState(false);
    const closePopup = () => {
        setShowPopup(false);
        setCurrentStep(1);
        setIsCompleted(false);
      };
    
      // Navigate steps
      const nextStep = () => {
        if (currentStep < 3) setCurrentStep(currentStep + 1);
      };
    
      const prevStep = () => {
        if (currentStep > 1) setCurrentStep(currentStep - 1);
      };

      const finishSubmission = () => {
        setIsCompleted(true); 
      };
    


    const faqs = [
        {
            question: "What is Car Insurance?",
            answer: "Car Insurance not only provides financial protection to the car but also provides cover for injuries to driver, passengers or pedestrians, and their property. It pays for damages to your car due to accidents, vandalism, theft, fire, man-made/ natural disasters, and third-party liability. Considering the exorbitant repair costs these days even a minor damage can cost a fortune. The Motor Vehicles Act also requires every vehicle on the road to at least have a valid third-party liability cover, i.e. cover that pays for bodily injury, loss of life and damage to property of a third person that was caused by an accident with your car.",
        },
        {
            question: "Which Documents are Required to buy a car insurance plan?",
            answer: "To buy the policy individuals would have to submit the RC book of the car, invoice of the new car or existing policy document for an old car, No claim bonus certificate of the existing policy if required.",
        },
        {
            question: "What is Covered Under car insurance?",
            answer: "The IRDAI revises premium every year, considering the ratio of claims made and loss for providers. Because of this, third party insurance rates for cars and bikes have become costly from April 1st, with the insurance rate rising up to 40%.  ",
        },
        {
            question: "What is not Covered Under car insurance?",
            answer: "Normal wear and tear and general ageing of the insured vehicle.",
        },
        {
            question: "What are the benefits of buying car insurance plans?",
            answer: "A car insurance policy is not only legally mandatory, it is beneficial too. Here’s how",
        },
        {
            question: "What is Multi-Year Two-Wheeler Insurance? What are the benefits?",
            answer: "Multi-Year Insurance allows you to cover your bike for multiple years in one go, saving you from annual renewals and potential premium hikes.",
        },
        {
            question: "How much will I get paid for a bike damage claim?",
            answer: "The compensation depends on the extent of damage, policy terms, and the bike's insured declared value (IDV).",
        },
        {
            question: "What are the types of claims in a bike insurance policy?",
            answer: "The two main types of claims are cashless claims (settled directly with the workshop) and reimbursement claims (you pay first, then get reimbursed).",
        },
    ];

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle the dropdown
    };


    return (
        <div
            className="container-fluid"
            style={{
                background: 'linear-gradient(182.42deg, #FCA505 2.01%, #FFFFFF)',
                minHeight: '60vh',
                paddingTop: '20px',
            }}
        >
            {/* Top Section */}
            <div className="row justify-content-start align-items-center" style={{ minHeight: '40vh' }}>
                {/* Left side: Text content */}
                <div className="col-12 col-md-6 text-left">
                    <div className="text-content">
                        <h1>Protect Your Four-Wheeler with Ease</h1>
                        <p style={{ fontWeight: '500' }}>Insurance applications made simple and stress-free.</p>
                    </div>
                    <img src={checklistIcon} alt="Checklist Icon" className="img-fluid checklist-icon" />
                </div>

                {/* Right side: Vehicles and Phone Icon */}
                <div className="col-12 col-md-6 text-center">
                    <img src={vehiclesIcon} alt="Vehicles and Phone" className="img-fluid" />
                </div>
            </div>

            {/* Content Section */}
            <div
                className="content-section"
                style={{
                    backgroundColor: '#fffff',
                    padding: '30px 15px',
                    borderRadius: '10px',
                    margin: '14% auto',
                    marginRight:'72%',
                }}
            >
                <div className="row justify-content-center">
                    {/* Main Column for Vertical Layout */}
                    <div className="col-12 col-md-8 position-relative">
                        {/* First Section: Documents */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={documentsIcon}
                                    alt="Documents Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div> 
                        </div>
                    
           
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '100%',
                                // margin: '0 auto',
                                width: '4px',
                                marginTop:'-20%',
                                marginLeft:'50%'
                            }}
                        ></div>

                        {/* Second Section: How It Works */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={howIcon}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>

                        {/* Blue Line */}
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '150%',
                                // margin: '0 auto',
                                marginLeft:'49.8%',
                                marginTop:'-20%',
                                width: '4px',
                            }}
                        ></div>

                        {/* Third Section */}
                        <div className="text-center">
                            <div style={{ position: 'relative' }}>
                    
                                <img
                                    src={thirdImage}
                                    alt="Third Section Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '60%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
                           
                        </div>
                       
            
                    </div>
                </div>
             <>
                <div className="mb-5"   style={{
                        marginTop: '-38%',
                        marginLeft: '71%',
                    }}>
                     <h4
        style={{
            color: '#007BFF',
            fontWeight: 'bold',
            whiteSpace: 'nowrap', // Prevents text from wrapping
        }}
    >Documents Required For Four-Wheeler Insurance</h4>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px', lineHeight: '1.8', whiteSpace: 'nowrap', fontSize:'bold' }}>
                    <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Registration Copy</li>
                    <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Old Policy Details If It's Renew</li>
                    </ul>
                </div>

                {/* How It Works Section */}
                <div style={{marginLeft:'72%',marginTop:'33%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>How It Works</h4>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Register Online</li>
        <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Get Quotation Via E-mail / WhatsApp</li>
        <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Compare Policies</li>
        <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Make Payment</li>
        <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Download Your Policy Instantly</li>
                    </ul>
                </div>
                </>
            </div>
     <div>
      {/* Get Quotes Button */}
      <div style={{ textAlign: "center", marginTop: "-10%" }}>
        <button
          style={{
            backgroundColor: "#FCA505",
            color: "#000000",
            padding: "12px 50px",
            border: "none",
            borderRadius: "30px",
            fontWeight: "bold",
            cursor: "pointer",
            fontSize: "16px",
            marginRight:"40%",
            marginTop:"-30%",
          }}
          onClick={() => setShowPopup(true)}
        >
          Get Quotes
        </button>
      </div>

      {/* Modal Popup */}
      {showPopup && (
        <div
          style={{
            position: "fixed",
            top: "100px",
            left: "0",
            width: "100%",
            height: "86%",
            backgroundColor: "rgba(26, 118, 216, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "9999",
          }}
        >
          <div
            style={{
              backgroundColor: "#FFFFFF",
              padding: "40px",
              borderRadius: "28px",
              width: "70%",
              maxHeight: "90%", // Maximum height of the popup
              overflowY: "auto", // Enable scrolling if content overflows
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            {/* Stepper */}

            {!isCompleted ? (
              <>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: currentStep >= 1 ? "#4285F4" : "#ccc",
                    color: "white",
                    borderRadius: "50%",
                    lineHeight: "60px",
                    fontWeight: "bold",
                  }}
                >
                  1
                </div>
                {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step 1</span> */}
              </div>
              <div
                style={{
                  height: "2px",
                  flex: 1,
                  backgroundColor: currentStep >= 2 ? "#4285F4" : "#ccc",
                  alignSelf: "center",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: currentStep >= 2 ? "#4285F4" : "#ccc",
                    color: "white",
                    borderRadius: "50%",
                    lineHeight: "50px",
                    fontWeight: "bold",
                  }}
                >
                  2
                </div>
                {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step 2</span> */}
              </div>
              <div
                style={{
                  height: "2px",
                  flex: 1,
                  backgroundColor: currentStep >= 3 ? "#4285F4" : "#ccc",
                  alignSelf: "center",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: currentStep === 3 ? "#4285F4" : "#ccc",
                    color: "white",
                    borderRadius: "50%",
                    lineHeight: "50px",
                    fontWeight: "bold",
                  }}
                >
                  3
                </div>
                {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step 3</span> */}
              </div>
            </div>

            {/* Popup Content Based on Step */}
            <div style={{ marginBottom: "20px" }}>
            {currentStep === 1 && (
  <div style={{ textAlign: "center" }}>
    <p style={{fontWeight:"600"}}>Buy Private Car insurance, the smart way,</p>

    {/* Heading: I Want to* */}
    <h4 style={{ color: "#007BFF", fontWeight: "bold" }}>
      I Want to
      <span style={{ color: "red" }}>*</span>
    </h4>

    {/* Radio Buttons */}
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft:"4%",
        // margin: "30px auto",
        width: "fit-content", 
        gap: "20px", 
      }}
    >
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="policyOption"
          value="buyNewPolicy"
          style={{ marginRight: "10px" }}
        />
        Buy New Policy
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="policyOption"
          value="renewExistingPolicy"
          style={{ marginRight: "10px" }}
        />
        Renew Existing Policy
      </label>
    </div>
  </div>
)}


{currentStep === 2 && (
  <div style={{ textAlign: "center" }}>
    {/* Step 2 Heading */}
    <p>Buy Insurance, the smart way</p>

    {/* Subheading */}
    <h3 style={{ color: "#007BFF", fontWeight: "600", margin: "20px 0" }}>
      Enter Your Four Wheeler Details
    </h3>

    {/* Registration Input */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="registrationNumber"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Registration
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        id="registrationNumber"
        placeholder="Enter Registration Number"
        style={{
          width: "100%",
          padding: "10px",
          fontSize: "16px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      />
    </div>

    {/* Registration Date Input */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="registrationDate"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Registration Date
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="date"
        id="registrationDate"
        style={{
          width: "100%",
          padding: "10px",
          fontSize: "16px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      />
    </div>
  </div>
)}

{currentStep === 3 && (
        <div style={{ textAlign: "center" }}>
          <p>Buy Insurance, the smart way</p>

          <h3 style={{ color: "#007BFF", fontWeight: "600", margin: "20px 0" }}>
            Contact Details
          </h3>

          {/* Form Fields */}
          <div style={{ marginBottom: "20px", textAlign: "left" }}>
            <label
              htmlFor="name"
              style={{
                display: "block",
                marginBottom: "10px",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              placeholder="Enter Your Name"
              style={{
                width: "100%",
                height: "45px",
                padding: "10px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          </div>

          {/* Mobile Number */}
          <div style={{ marginBottom: "20px", textAlign: "left" }}>
            <label
              htmlFor="mobileNumber"
              style={{
                display: "block",
                marginBottom: "10px",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Mobile Number
            </label>
            <input
              type="text"
              id="mobileNumber"
              placeholder="Enter Your Mobile Number"
              style={{
                width: "100%",
                height: "45px",
                padding: "10px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          </div>

          {/* Email ID */}
          <div style={{ marginBottom: "20px", textAlign: "left" }}>
            <label
              htmlFor="email"
              style={{
                display: "block",
                marginBottom: "10px",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Email ID
            </label>
            <input
              type="email"
              id="email"
              placeholder="Enter Your Email ID"
              style={{
                width: "100%",
                height: "45px",
                padding: "10px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          </div>

          {/* Address */}
          <div style={{ marginBottom: "20px", textAlign: "left" }}>
            <label
              htmlFor="address"
              style={{
                display: "block",
                marginBottom: "10px",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Address
            </label>
            <textarea
              id="address"
              placeholder="Enter Your Address"
              style={{
                width: "100%",
                height: "70px",
                padding: "10px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          </div>

          {/* State Dropdown */}
          <div style={{ marginBottom: "20px", textAlign: "left" }}>
            <label
              htmlFor="state"
              style={{
                display: "block",
                marginBottom: "10px",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              State
            </label>
            <select
              id="state"
              style={{
                width: "100%",
                height: "45px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            >
              <option value="Andhra Pradesh">Andhra Pradesh</option>
              <option value="Bihar">Bihar</option>
              <option value="Delhi">Delhi</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">Kerala</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Tamil Nadu">Tamil Nadu</option>
              <option value="Uttar Pradesh">Uttar Pradesh</option>
              <option value="West Bengal">West Bengal</option>
            </select>
          </div>

          {/* District Dropdown */}
          <div style={{ marginBottom: "20px", textAlign: "left" }}>
            <label
              htmlFor="district"
              style={{
                display: "block",
                marginBottom: "10px",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              District
            </label>
            <select
              id="district"
              style={{
                width: "100%",
                height: "45px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            >
              <option value="Bagalkote">Bagalkote</option>
              <option value="Bangalore">Bangalore</option>
              <option value="Mysore">Mysore</option>
              <option value="Dharwad">Dharwad</option>
              <option value="Belgaum">Belgaum</option>
              <option value="Gulbarga">Gulbarga</option>
              <option value="Mangalore">Mangalore</option>
              <option value="Udupi">Udupi</option>
              <option value="Hubli">Hubli</option>
            </select>
          </div>

          {/* Terms and Conditions */}
          <p style={{ marginTop: "20px", fontSize: "14px", textAlign: "left" }}>
            By clicking submit, you agree to our{" "}
            <a href="/terms" style={{ color: "#007BFF", textDecoration: "underline" }}>
              Terms & Conditions
            </a>{" "}
            and{" "}
            <a
              href="/privacy-policy"
              style={{ color: "#007BFF", textDecoration: "underline" }}
            >
              Privacy Policy
            </a>.
          </p>
        </div>
      )}

            </div>

            {/* Stepper Navigation */}
            <div>
              {/* {currentStep > 1 && (
                <button
                  onClick={prevStep}
                  style={{
                    marginRight: "10px",
                    padding: "10px 20px",
                    backgroundColor: "#4285F4",
                    color: "#FFFFFF",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Previous
                </button>
              )} */}
              {currentStep < 3 ? (
                <button
                  onClick={nextStep}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#FCA505",
                    color: "#000000",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Next
                </button>
              ) : (
                <button
                onClick={finishSubmission}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "FCA505",
                    color: "#000000",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  SUBMIT
                </button>
              )} 
            </div>
            </>
            ) : (
                <div>
                {/* Green Checkmark Animation */}
                <div
                  style={{
                    margin: "20px auto",
                    width: "100px",
                    height: "100px",
                    border: "5px solid green",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "50px",
                      color: "green",
                    }}
                  >
                    ✓
                  </span>
                </div>

                {/* Thank-You Message */}
                <h2 style={{ color: "#007BFF", fontWeight: "bold", marginTop: "20px" }}>Thank You!</h2>
                <p style={{ marginTop: "10px", fontSize: "16px", color: "#555" }}>
                  Your submission has been received.
                </p>
                <p style={{ marginTop: "10px", fontSize: "14px", color: "#555" }}>
                  We appreciate your interest and will get back to you as soon as possible.
                </p>
              </div>
            )}

            {/* Close Button */}
            <button
              onClick={closePopup}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "none",
                border: "none",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>

                       {/* FAQ Section */}
 <div className="faq-section" style={{ margin: '50px auto', padding: '20px', background: '#FFFFFF', borderRadius: '10px', width: '80%' }}>
            <h4 style={{ textAlign: 'center', fontWeight: 'bold', color: '#007BFF', marginBottom: '20px' }}>FAQs</h4>
            <p style={{ textAlign: 'center', fontWeight: '500', marginBottom: '30px' }}>Need help? Contact us for any queries related to us</p>
            <div className="faq-list">
                {faqs.map((faq, index) => (
                    <div key={index} className="faq-item" style={{ marginBottom: '10px' }}>
                        <button
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                padding: '10px 20px',
                                border: '1px solid #007BFF',
                                borderRadius: '5px',
                                background: '#F9F9F9',
                                fontWeight: '500',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleToggle(index)}
                        >
                            {faq.question}
                            <span style={{ fontWeight: 'bold', fontSize: '16px', marginLeft: '10px' }}>
                                {openIndex === index ? '▲' : '▼'}
                            </span>
                        </button>
                        {openIndex === index && (
                            <div
                                style={{
                                    marginTop: '10px',
                                    padding: '10px 20px',
                                    background: '#F3F3F3',
                                    borderRadius: '5px',
                                    color: '#333',
                                }}
                            >
                                {faq.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>

        </div>
    );
};

export default TwoWheeler;
