import React, { useState } from 'react';
import Image30 from '../../images/passport_image.svg'; 
import circleIcon from '../../images/circle1.svg'; 
import documentsIcon from '../../images/documents.svg'; 
import howIcon from '../../images/Time.svg'; 
import Price from '../../images/Price Tag.svg'

const Passport = () => {
    
    const [openIndex, setOpenIndex] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [isCompleted, setIsCompleted] = useState(false);
    const closePopup = () => {
        setShowPopup(false);
        setCurrentStep(1);
        setIsCompleted(false);
      };
    
      // Navigate steps
      const nextStep = () => {
        if (currentStep < 7) setCurrentStep(currentStep + 1);
      };
    
      const prevStep = () => {
        if (currentStep > 1) setCurrentStep(currentStep - 1);
      };

      const finishSubmission = () => {
        setIsCompleted(true); 
      };

      const [current, setCurrent] = useState(1); 
      const [otp, setOtp] = useState(["", "", "", ""]);
      const [resendCountdown, setResendCountdown] = useState(30); // Countdown timer for resend button
      const [isResending, setIsResending] = useState(false);
    
      // Handle OTP input
      const handleChange = (value, index) => {
        if (!/^\d*$/.test(value)) return; // Allow only numbers
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
    
        // Automatically move to the next input
        if (value && index < otp.length - 1) {
          document.getElementById(`otp-input-${index + 1}`).focus();
        }
      };
    
    
      const handleBackspace = (e, index) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
          document.getElementById(`otp-input-${index - 1}`).focus();
          const newOtp = [...otp];
          newOtp[index - 1] = "";
          setOtp(newOtp);
        }
      };
    
      // Resend OTP functionality
      const handleResend = () => {
        setIsResending(true);
        setTimeout(() => {
          setResendCountdown(30); // Reset countdown
          setIsResending(false);
        }, 1000); // Simulate resend delay
      };
    
      // Verify OTP
      const handleVerify = () => {
        const enteredOtp = otp.join("");
        if (enteredOtp.length === 4) {
          alert(`OTP Verified: ${enteredOtp}`);
        } else {
          alert("Please enter a valid 4-digit OTP.");
        }
      };
    
      // Countdown timer for resend button
      React.useEffect(() => {
        if (resendCountdown > 0) {
          const timer = setInterval(() => setResendCountdown(resendCountdown - 1), 1000);
          return () => clearInterval(timer);
        }
      }, [resendCountdown]);





      const faqs = [
        {
            question: "Benefits of Rental Agreement?",
            answer: (
              <ul style={{ listStyleType: 'disc' }}>
                <li>Minimizes the conflicts between a tenant and the owner</li>
                <li>Rental/lease agreement acts as an address proof</li>
                <li>Acts as a proof for Bank loans</li>
                <li>Helps in investment & loan</li>
                <li>Vehicle registration</li>
              </ul>
            ),
          },
        {
            question: "Difference between comprehensive and third-party liability?",
            answer: "Comprehensive insurance covers both third-party liabilities and damage to your own vehicle, while third-party liability only covers damage caused to others.",
        },
        {
            question: "What is the different between rental/lease agreement?",
            answer: "Leasing is defined as a contract between lessor and lessee whereby the lessor buys the asset and lets the lessee use the asset for a particular period. Renting is to allow the other party to occupy or use the asset for a short time, in return for a fixed payment.",
        },
        {
            question: "what is stamp paper?",
            answer: "Stamp papers which are used for execution of documents are called as Non-Judicial Stamp Papers. ... Stamp Duty paid in respect of Non-Judicial Stamp Paper is paid under The Indian Stamp Act, 1899 and Stamp Duty paid in respect of Judicial Stamp Paper is paid under the Court Fees Act, 1870",
        },
        {
            question: "Do I need to visit any office to get Rental agreement?",
            answer: "No its completely online process once you registered in our website we create draft and it will be print on stamp paper, and it get dispatched to address.",
        },
        {
            question: "How will I get my Rental agreement?",
            answer: "It will get dispatched through courier.",
        },
        {
            question: "What is the value of stamp paper for Rental agreement?",
            answer: "The Rental/Lease agreement must be printed on a Non-Judicial Stamp Paper with a value of Rs.100/- or more.",
        },
        {
            question: "What is validity of Rental agreement?",
            answer: "In the usual practice, a landlord and a tenant make a rent agreement for a period of 11 months, with an option for a periodic renewal.",
        },
        {
            question: "what is Notarized Rental agreement?",
            answer: "A notarized agreement is a document that has been marked with a notary stamp, which indicates that the signature on the document is legal. A notary officers is the witness when you sign and then places the stamp near your signature.",
        },
        {
            question: "Is agreement required to be notarised?",
            answer: "A contract typically does not have to be notarized. A notary public  provides an acknowledgment that the signature appearing on the document is that of the person whose signature it purports to be. There is a requirement that some documents be notarized, such as a real property deed.",
        },
    ];

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };


    return (
        <>
            <div style={{
                background: 'linear-gradient(182.42deg, #FCA505 2.01%, #FFFFFF)',
                minHeight: '60vh',
                paddingTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 20px'
            }}>
                <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold' }}>
                    <p>"Ready to Explore the World? Get Expert</p>
                    <p>Assistance for Your Passport Application Today!"</p>
                </div>
                <div>
                    <img src={Image30} alt="Rental Agreement" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
            </div>

            <div style={{ padding: '10px', textAlign: 'left',marginLeft:"8%" }}>
                <h2 style={{ color: '#1A76D8', fontWeight: 'bold', marginLeft:"20px" , fontSize:"25px"}}>Documents Required For Fresh Passport
                </h2>
                <ul
        style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)', 
            columnGap: '40px', 
            rowGap: '-4px', 
            listStyleType: 'disc', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
        <li style={{ fontWeight: 'bold' }}>Proof Identify (Any 01)</li>
        <li style={{ fontWeight: '' }}>Aadhar Card</li>
       
        <li style={{ fontWeight: '' }}>Voter id </li>
        <li style={{ fontWeight: '' }}>Pan Card</li>
        <li style={{ fontWeight: '' }}>Driving Licence</li>
    </ul>


    <ul
        style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)', 
            columnGap: '40px', 
            rowGap: '-4px', 
            listStyleType: 'disc', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
        <li style={{ fontWeight: 'bold' }}>Proof Identify (Any 02)</li>
        <li style={{ fontWeight: '' }}>Aadhar Card</li>
       <li style={{ fontWeight: '' }}>Voter id </li>
        <li style={{ fontWeight: '' }}>Bank Account Passbook </li>
        <li style={{ fontWeight: '' }}>Gas Connection Bill / Electricity Bill / Telephone Bill / Water Bill </li>
        <li style={{ fontWeight: '' }}>Parents Passport / Spouse Passport</li>
    </ul>


    <ul
        style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)', 
            columnGap: '40px', 
            rowGap: '-4px', 
            listStyleType: 'disc', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
        <li style={{ fontWeight: 'bold' }}>Proof Identify (Any 03)</li>
        <li style={{ fontWeight: '' }}>10th Certificate</li>
        <li style={{ fontWeight: '' }}>12th Certificate </li>
        <li style={{ fontWeight: '' }}>Higher Education Pass Certificate</li>
        <li style={{ fontWeight: '' }}>School leaving Certificate</li>
        <li style={{ fontWeight: '' }}>Income Tax Assessment Order</li>
    </ul>
            </div>

            
            <div style={{ padding: '10px', textAlign: 'left',marginLeft:"8%" }}>
                <h2 style={{ color: '#1A76D8', fontWeight: 'bold', marginLeft:"20px", fontSize:"22px" }}>Document Required for Renewal / Reissue of Passport</h2>
                <ul
        style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)', 
            columnGap: '40px', 
            rowGap: '-4px', 
            listStyleType: 'disc', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
        <li style={{ fontWeight: '' }}>Original Old Passport</li>
        <li style={{ fontWeight: '' }}>Id And Present Address Proof</li>
        
    </ul>
            </div>

            
            <div style={{ padding: '10px', textAlign: 'left',marginLeft:"8%" }}>
                <h2 style={{ color: '#1A76D8', fontWeight: 'bold', marginLeft:"20px", fontSize:"22px" }}>Document Required for Minor Passport</h2>
                <ul
        style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)', 
            columnGap: '40px', 
            rowGap: '-4px', 
            listStyleType: 'disc', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
        <li style={{ fontWeight: '' }}>Birth Certificate</li>
        <li style={{ fontWeight: '' }}>
        Both Parents Passport</li>
        
    </ul>
            </div>
            
         

            
            <div
                className="content-section"
                style={{
                    backgroundColor: '#fffff',
                    padding: '30px 15px',
                    borderRadius: '10px',
                    margin: '-1% auto',
                    marginRight:'72%',
                }}
            >
                <div className="row justify-content-center">
                    {/* Main Column for Vertical Layout */}
                    <div className="col-12 col-md-8 position-relative">
                        {/* First Section: Documents */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={documentsIcon}
                                    alt="Documents Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div> 
                        </div>
                    
           
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '100%',
                                // margin: '0 auto',
                                width: '4px',
                                marginTop:'-20%',
                                marginLeft:'50%'
                            }}
                        ></div>

                        {/* Second Section: How It Works */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={howIcon}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>

                        {/* Blue Line */}
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '150%',
                                // margin: '0 auto',
                                marginLeft:'49.8%',
                                marginTop:'-48%',
                                width: '4px',
                            }}
                        ></div>

                        {/* Third Section */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={Price}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>
                       
            
                    </div>
                </div>
             <>
             <div
    className="mb-5"
    style={{
        marginTop: '-45%',
        marginLeft: '70%',
    }}
>
    <h4
        style={{
            color: '#007BFF',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
        }}
    >
        How It Works
    </h4>
    <ul
        style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)', 
            columnGap: '40px', 
            rowGap: '20px', 
            listStyleType: 'disc', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
        <li style={{ fontWeight: 'bold' }}>Register Online & Making Payment</li>
        <li style={{ fontWeight: 'bold' }}>Upload Documents</li>
        <li style={{ fontWeight: 'bold' }}>Get Appointment </li>
        <li style={{ fontWeight: 'bold' }}>Visit Psk</li>
        <li style={{ fontWeight: 'bold' }}>Get Delivered</li>
    </ul>
</div>
                <div style={{marginLeft:'72%',marginTop:'36%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>Time Duration</h4>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>15-20 working days(Normal)</li>
                    <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>5-0 working days(Tatkal)</li>
                    </ul>
                </div>
                <div style={{marginLeft:'72%',marginTop:'53%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>Charges</h4>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Rs. 2,499 For (Normal Application)</li>
        <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Rs. 4,499 For (Tatkal Application)</li>
        <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Rs. 99 as booking fee. Need to pay while submitting online form 
        (This fee is non-refundable and will be adjusted in the total bill.) </li>
       
                    </ul>
                </div>
                </>
            </div>
            <div>
   
   {/* Get Quotes Button */}
   <div style={{ textAlign: "center", marginTop: "4%" }}>
     <button
       style={{
         backgroundColor: "#FCA505",
         color: "#000000",
         padding: "12px 50px",
         border: "none",
         borderRadius: "30px",
         fontWeight: "bold",
         cursor: "pointer",
         fontSize: "16px",
         marginRight:"40%",
         marginTop:"-30%",
       }}
       onClick={() => setShowPopup(true)}
     >
       CONTINUE
     </button>
   
   
 </div>
   {/* Modal Popup */}
   {showPopup && (
     <div
       style={{
        position: "fixed",
            top: "100px",
            left: "0",
            width: "100%",
            height: "86%",
            backgroundColor: "rgba(26, 118, 216, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "9999",
       }}
     >
       <div
         style={{
           backgroundColor: "#FFFFFF",
           padding: "40px",
           borderRadius: "28px",
           width: "70%",
           maxHeight: "90%", // Maximum height of the popup
           overflowY: "auto", // Enable scrolling if content overflows
           textAlign: "center",
           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
         }}
       >
         {/* Stepper */}
       

         {!isCompleted ? (
           <>
   <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
  {Array.from({ length: 7 }).map((_, index) => (
    <React.Fragment key={index}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <button
          onClick={() => setCurrentStep(index + 1)} // Make step clickable
          style={{
            width: "50px",
            height: "50px",
            backgroundColor: currentStep >= index + 1 ? "#4285F4" : "#ccc",
            color: "white",
            borderRadius: "50%",
            lineHeight: "50px",
            fontWeight: "bold",
            border: "none",
            cursor: "pointer",
            outline: "none",
          }}
        >
          {index + 1}
        </button>
        {/* Optional Step Labels */}
        {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step {index + 1}</span> */}
      </div>

      {/* Add Connection Divider Between Steps */}
      {index < 6 && (
        <div
          style={{
            height: "2px",
            flex: 1,
            backgroundColor: currentStep > index + 1 ? "#4285F4" : "#ccc",
            alignSelf: "center",
          }}
        />
      )}
    </React.Fragment>
  ))}
</div>



      
         <div style={{ marginBottom: "20px" }}>
         {currentStep === 1 && (
  <div style={{ textAlign: "center" }}>
  
    <h5 style={{ color: "#007BFF", fontWeight: "bold" }}>
Applying For
      <span style={{ color: "red" }}>*</span>
    </h5>

    {/* Radio Buttons */}
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "4%",
        width: "fit-content",
        gap: "20px",
      }}
    >
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="flatHouseRentalAgreement"
          style={{ marginRight: "10px" }}
        />
    Fresh Passport 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Re-issue of Passport 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Change In Existing Personal Particulars 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
       Damaged Passport 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Exhaustion Of Pages  
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Lost Passport  
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Validity Expired More Than 3 Years Ago 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Validity Expired Within 3 Years/Due To Expire
      </label>
    </div>
  </div>
)}



{currentStep === 2 && (
  <div style={{ textAlign: "center" }}>
    {/* Step 3 Heading */}
    <h5 style={{ color: "#007BFF", fontWeight: "bold" }}>
        Application Details
      <span style={{ color: "red" }}>*</span>
    </h5>

  
  
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "4%",
        width: "fit-content",
        gap: "20px",
      }}
    >
          <h5 style={{fontWeight:"800"}}>Type of application</h5>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="identityOption"
          value="owner"
          style={{ marginRight: "10px" }}
        />
        Normal
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="identityOption"
          value="tenant"
          style={{ marginRight: "10px" }}
        />
        Tatkal
      </label>
    </div>
    <br></br>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "4%",
        width: "fit-content",
        gap: "20px",
      }}
    >
          <h5 style={{fontWeight:"800"}}>Type of Passport Booklet</h5>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="identityOption"
          value="owner"
          style={{ marginRight: "10px" }}
        />
        36 Pages
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="identityOption"
          value="tenant"
          style={{ marginRight: "10px" }}
        />
        60 Pages
      </label>
    </div>
    <br></br>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "4%",
        width: "fit-content",
        gap: "20px",
      }}
    >
          <h5 style={{fontWeight:"800"}}>Gender<span style={{color:"red"}}>*</span></h5>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="identityOption"
          value="owner"
          style={{ marginRight: "10px" }}
        />
        Female
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="identityOption"
          value="tenant"
          style={{ marginRight: "10px" }}
        />
        Male
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="identityOption"
          value="tenant"
          style={{ marginRight: "10px" }}
        />
        Transgender
      </label>
    </div>
  </div>
)}


{currentStep === 3 && (
  <div style={{ textAlign: "center" }}>
    {/* Step 3 Heading */}
    <p style={{ color: "#1A76D8", fontWeight: "600" }}>
      Application Details<span style={{ color: "red" }}>*</span>
    </p>

    {/* Owner's Name & Age */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="givenname"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Given Name 
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        id="givernname"
        placeholder=""
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="surname"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Surname
      </label>
      <input
        type="text"
        id="ownerFatherName"
        placeholder=""
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    <div style={{ marginBottom: "20px", textAlign: "left" }}>
  <label
    htmlFor="dateOfBirth"
    style={{
      display: "block",
      marginBottom: "10px",
      fontSize: "16px",
      fontWeight: "500",
    }}
  >
    Date of Birth
    <span style={{ color: "red" }}>*</span>
  </label>
  <input
    type="date"
    id="dateOfBirth"
    placeholder="Enter Date of Birth"
    style={{
      width: "100%",
      height: "45px",
      padding: "10px",
      fontSize: "16px",
      border: "2px solid #FCA505",
      borderRadius: "4px",
    }}
  />
</div>
<div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="place"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Place of Birth (Village/ Town / City) *
      </label>
      <input
        type="text"
        id="place"
        placeholder=""
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>


    
  </div>
)}


{currentStep === 4 && (
           <div style={{ textAlign: "center" }}>
        
        <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "4%",
        width: "fit-content",
        gap: "20px",
      }}
    >
      
  <p style={{fontWeight:"600"}}>Education qualification</p> 
    
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Graduate And Above 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        10th Pass And Above
              </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
       Below 10th
      </label>
      <>
      <p style={{fontWeight:"600"}}>Employment Type</p> 
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
      Government
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Private
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Self Employed 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Student
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Home Maker
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Retired
      </label>
      </>
    </div>
       
           
         </div>
)}
          {currentStep === 5 && (
           <div style={{ textAlign: "center" }}>
           <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "4%",
        width: "fit-content",
        gap: "20px",
      }}
    >
           <p style={{ color: "#1A76D8", fontWeight: "600" }}>
            Marital Status <span style={{ color: "red" }}>*</span>
           </p>
       
           <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Singal
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Married
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Widow/widower
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Seprated
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Divorced
      </label>
      </div>
           
         </div>
)}
{currentStep === 6 && (
  <div style={{ textAlign: "center" }}>
    <div style={{ marginBottom: "20px" }}>
      <label style={{ fontSize: "20px", fontWeight: "600" }}>
      Present Residential Address<span style={{ color: "red" }}>*</span>
      </label>
   
    </div>
    <div style={{ marginBottom: "40px" ,textAlign:"left", marginTop:"26px"}}>
      <label style={{ fontSize: "16px", fontWeight: "bold" }}>
      Father's Given Name<span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        placeholder=""
        style={{
          width: "100%",
          padding: "10px",
          marginTop: "10px",
          border: "1px solid orange",
          borderRadius: "4px",
        }}
      />
    </div>

    <div style={{ marginBottom: "40px" ,textAlign:"left"}}>
      <label style={{ fontSize: "16px", fontWeight: "bold" }}>
      Mother's Given Name  <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        placeholder=""
        style={{
          width: "100%",
          padding: "10px",
          marginTop: "10px",
          border: "1px solid orange",
          borderRadius: "4px",
        }}
      />
    </div>
  </div>
)}


          {currentStep === 7 && (
         <div style={{ textAlign: "center" }}>
         {/* Step 3 Heading */}
         <p style={{ color: "#1A76D8", fontWeight: "600" }}>
         Present Residential Address<span style={{ color: "red" }}>*</span>
         </p>
        
         <div style={{ marginBottom: "20px", textAlign: "left" }}>
           <label
            //  htmlFor="ownerName"
             style={{
               display: "block",
               marginBottom: "10px",
               fontSize: "16px",
               fontWeight: "500",
             }}
           >
            House No. and Street Name
             <span style={{ color: "red" }}>*</span>
           </label>
           <input
             type="text"
             id=""
             placeholder=""
             style={{
               width: "100%",
               height: "45px",
               padding: "10px",
               fontSize: "16px",
               border: "2px solid #FCA505",
               borderRadius: "4px",
             }}
           />
         </div>

         <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="state"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        State
        <span style={{ color: "red" }}>*</span>
      </label>
      <select
        id="state"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      >
        <option value="">--Select State--</option>
        {/* Add all India states here as options */}
        <option value="AndhraPradesh">Andhra Pradesh</option>
        <option value="ArunachalPradesh">Arunachal Pradesh</option>
        <option value="Assam">Assam</option>
        <option value="Bihar">Bihar</option>
        <option value="Chhattisgarh">Chhattisgarh</option>
        {/* Continue adding other states */}
      </select>
    </div>
        {/* District Dropdown */}
        <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="district"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        District
        <span style={{ color: "red" }}>*</span>
      </label>
      <select
        id="district"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      >
        <option value="">--Select District--</option>
        {/* Add district options dynamically based on state selection */}
      </select>
    </div>
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="pincode"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Pin Code 
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        id="pincode"
        placeholder=""
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>
         <div style={{ marginBottom: "20px", textAlign: "left" }}>
           <label
             htmlFor=""
             style={{
               display: "block",
               marginBottom: "10px",
               fontSize: "16px",
               fontWeight: "500",
             }}
           >
            Email ID
           </label>
           <input
             type="text"
             id=""
             placeholder=""
             style={{
               width: "100%",
               height: "45px",
               padding: "10px",
               fontSize: "16px",
               border: "2px solid #FCA505",
               borderRadius: "4px",
             }}
           />
         </div>
     
         <div style={{ marginBottom: "20px", textAlign: "left" }}>
           <label
            //  htmlFor="ownerName"
             style={{
               display: "block",
               marginBottom: "10px",
               fontSize: "16px",
               fontWeight: "500",
             }}
           >
           Nearest Police Station 
             <span style={{ color: "red" }}>*</span>
           </label>
           <input
             type="text"
             id=""
             placeholder=""
             style={{
               width: "100%",
               height: "45px",
               padding: "10px",
               fontSize: "16px",
               border: "2px solid #FCA505",
               borderRadius: "4px",
             }}
           />
         </div>
         <div style={{ marginBottom: "20px", textAlign: "left" }}>
           <label
            //  htmlFor="ownerName"
             style={{
               display: "block",
               marginBottom: "10px",
               fontSize: "16px",
               fontWeight: "500",
             }}
           >
            Mobile Number
             <span style={{ color: "red" }}>*</span>
           </label>
           <input
             type="text"
             id=""
             placeholder=""
             style={{
               width: "100%",
               height: "45px",
               padding: "10px",
               fontSize: "16px",
               border: "2px solid #FCA505",
               borderRadius: "10px",
             }}
           />
         </div>
     
         <p style={{ marginTop: "20px", fontSize: "14px", textAlign: "left" }}>
            By clicking submit, you agree to our{" "}
            <a href="/terms" style={{ color: "#007BFF", textDecoration: "underline" }}>
              Terms & Conditions
            </a>{" "}
            and{" "}
            <a
              href="/privacy-policy"
              style={{ color: "#007BFF", textDecoration: "underline" }}
            >
              Privacy Policy
            </a>.
          </p>
       </div>
          )}

         </div>
         <div>
          
           {currentStep < 7 ? (
             <button
               onClick={nextStep}
               style={{
                 padding: "10px 20px",
                 backgroundColor: "#FCA505",
                 color: "#000000",
                 border: "none",
                 borderRadius: "5px",
                 cursor: "pointer",
               }}
             >
               Next
             </button>
           ) : (
             <button
             onClick={finishSubmission}
               style={{
                 padding: "10px 20px",
                 backgroundColor: "FCA505",
                 color: "#000000",
                 border: "none",
                 borderRadius: "5px",
                 cursor: "pointer",
               }}
             >
               SUBMIT
             </button>
           )} 
         </div>
         </>
         ) : (
            <div style={{ textAlign: "center", padding: "20px" }}>
            <h4 style={{ color: "#007BFF", fontWeight: "bold", display: "inline-block", marginRight: "10px" }}>
        OTP sent on 97******8
      </h4>
      <a
        href="#"
        onClick={() => setCurrent(1)} // Redirect to step 1
        style={{ fontSize: "14px", color: "#007BFF", textDecoration: "none" }}
      >
        Edit
      </a>
      
            <div style={{ margin: "20px 0" }}>
              <label style={{ display: "block", fontWeight: "500", marginBottom: "10px" }}>
                Enter OTP <span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleChange(e.target.value, index)}
                    onKeyDown={(e) => handleBackspace(e, index)}
                    style={{
                      width: "50px",
                      height: "50px",
                      textAlign: "center",
                      fontSize: "18px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  />
                ))}
              </div>
            </div>
      
            <p style={{ fontSize: "14px", color: "#888" }}>
              {resendCountdown > 0 ? (
                <>Not Received? Resend in {resendCountdown}s</>
              ) : (
                <a
                  href="#"
                  onClick={handleResend}
                  style={{
                    textDecoration: "none",
                    color: isResending ? "#ccc" : "#007BFF",
                    pointerEvents: isResending ? "none" : "auto",
                  }}
                >
                  Resend OTP
                </a>
              )}
            </p>
      
            <button
              onClick={handleVerify}
              style={{
                backgroundColor: "#FFA500",
                color: "#00000",
                border: "none",
                borderRadius: "5px",
                padding: "10px 20px",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              VERIFY
            </button>
          </div>
         )}

         {/* Close Button */}
         <button
           onClick={closePopup}
           style={{
             position: "absolute",
             top: "10px",
             right: "10px",
             background: "none",
             border: "none",
             fontSize: "20px",
             cursor: "pointer",
           }}
         >
           &times;
         </button>
       </div>
     </div>
   )}
 </div>

 <div className="faq-section" style={{ margin: '50px auto', padding: '20px', background: '#FFFFFF', borderRadius: '10px', width: '80%' }}>
            <h4 style={{ textAlign: 'center', fontWeight: 'bold', color: '#007BFF', marginBottom: '20px' }}>FAQs</h4>
            <p style={{ textAlign: 'center', fontWeight: '500', marginBottom: '30px' }}>Need help? Contact us for any queries related to us</p>
            <div className="faq-list">
                {faqs.map((faq, index) => (
                    <div key={index} className="faq-item" style={{ marginBottom: '10px' }}>
                        <button
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                padding: '10px 20px',
                                border: '1px solid #007BFF',
                                borderRadius: '5px',
                                background: '#F9F9F9',
                                fontWeight: '500',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleToggle(index)}
                        >
                            {faq.question}
                            <span style={{ fontWeight: 'bold', fontSize: '16px', marginLeft: '10px' }}>
                                {openIndex === index ? '▲' : '▼'}
                            </span>
                        </button>
                        {openIndex === index && (
                            <div
                                style={{
                                    marginTop: '10px',
                                    padding: '10px 20px',
                                    background: '#F3F3F3',
                                    borderRadius: '5px',
                                    color: '#333',
                                }}
                            >
                                {faq.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
<br></br>
            <>
            <p>Welcome to Make My Documents, your one-stop destination for all your rental agreement needs. 
            We understand that renting a property can be a complex process, which is why we offer a hassle-free 
            solution to help you secure your rental agreement quickly and easily.</p>

            <p>Our team of legal experts has extensive experience in drafting rental agreements that meet all legal requirements. 
            We provide comprehensive rental agreement services, including new rental agreements, 
            rental agreement renewals, and rental agreement amendments. We also offer customized rental agreement 
            solutions tailored to your unique needs.</p>

            <p>At Make My Documents, we take pride in providing exceptional customer service. We understand that 
            your time is valuable, which is why we offer a seamless online rental agreement application process.</p>

            <p>Our online rental agreement form allows you to apply from the comfort of your own home, and our team will 
            guide you through every step of the process. Our rental agreement services include all the necessary legal clauses 
            and terms to protect both the landlord and the tenant. We also provide guidance on security deposits, 
            rent payment schedules, and any other special requirements you may have.</p>

            <p>Our rental agreement services are competitively priced, so you won't have to break the bank to secure your 
            rental agreement. Plus, our fast turnaround times mean that you can have your rental agreement in hand as soon as possible.</p>

            <p>Whether you need a rental agreement for your house, room, or commercial property, Make My Documents has 
            got you covered. We offer e-registration of rental agreements, lease agreements, and notarized rental 
            agreements to suit your specific requirements. Our services also include guidance on stamp duty for 
            rental agreements, rent agreement validity, rental agreement cancellation, and rental agreement renewal.</p>

            <p>If you're in need of a rental agreement, look no further than Make My Documents. Contact us today to 
            learn more about our rental agreement services and to schedule an appointment with one of our legal experts. 
            We look forward to helping you secure your rental agreement and enjoy a hassle-free renting experience.</p>

            <p>We also ensure that our rental agreement services are compliant with the latest legal and regulatory requirements, 
            giving you peace of mind. Our easy-to-use online platform and step-by-step support make the entire process 
            smooth and efficient. At Make My Documents, we are committed to delivering reliable, transparent, and customer-focused 
            solutions to cater to your rental agreement needs. With our expertise and dedication, you can trust us to simplify your 
            rental agreement process, saving you time and effort.</p>
        </>
        </div>

        
        
        </>
    );
};

export default Passport;
