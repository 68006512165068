import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from "./Header";
import Footer from "./Footer";
import Home from "./home";
import About from "./About";
import Insurance from "./Services/Insurance.jsx";
import TwoWheeler from "./Services/TwoWheeler.jsx";
import FourWheeler from "./Services/FourWheeler.jsx";
import CommercialVehicle from "./Services/CommercialVehicle.jsx";
import Health from "./Services/Health.jsx";
import Life from "./Services/Life.jsx";
import Career from "../src/Carrer.jsx";
import Contact from "../src/Contact.jsx";
import Rental from "./Services/rental/Rental.jsx";
import Lease from "./Services/lease/Lease.jsx";
import Affidavit from "./Services/affidavit/Affidavit.jsx";
import Pancard from "./Services/pancard/Pancard.jsx";
import Passport from "./Services/passport/Passport.jsx";
import SeniorCitizen from "./Services/senior/SeniorCitizen.jsx";
import Msme from "./Services/msme/Msme.jsx";
import Food from "./Services/food/Food.jsx";
import GST from "./Services/gst/Gst.js";
import PoliceClearance from "./Services/police/PoliceClearance.jsx";
import TravelVisa from "./Services/visa/TravelVisa.jsx";
import DestinationPage from "./Services/visa/DestinationPage.jsx";

const Layout = ({ children }) => {
  const location = useLocation();

  return (
    <>
      <Header />
      <div style={{ minHeight: "calc(100vh - 66px)" }}>{children}</div>

      {location.pathname === "/" && <Footer />}
      {location.pathname === "/about" && <Footer />}
    </>
  );
};

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/insurance" element={<Insurance />} />
          <Route path="/two-wheeler" element={<TwoWheeler />} />
          <Route path="/four-wheeler" element={<FourWheeler />} />
          <Route path="/commercial-vehicle" element={<CommercialVehicle />} />
          <Route path="/health" element={<Health />} />
          <Route path="/about" element={<About />} />
          <Route path="/career" element={<Career />} />
          <Route path="/life" element={<Life />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/rental-agreement" element={<Rental />} />
          <Route path="/lease-agreement" element={<Lease />} />
          <Route path="/affidavits" element={<Affidavit />} />
          <Route path="/pan-card" element={<Pancard />} />
          <Route path="passport" element={<Passport />} />
          <Route path="senior-citizen" element={<SeniorCitizen />} />
          <Route path="msme" element={<Msme />} />
          <Route path="food-license" element={<Food />} />
          <Route path="gst" element={<GST />} />
          <Route path="police-clearance" element={<PoliceClearance />} />
          <Route path="visa" element={<TravelVisa />} />
          <Route path="/destination" element={<DestinationPage />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
