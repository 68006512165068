import React, { useState } from "react";
import Image30 from "../../images/pancard_image.svg";
import circleIcon from "../../images/circle1.svg";
import documentsIcon from "../../images/documents.svg";
import howIcon from "../../images/Time.svg";
import Price from "../../images/Price Tag.svg";
import { FaArrowLeft } from "react-icons/fa";
import axios from "axios";

const Pancard = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [current, setCurrent] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isCompleted, setIsCompleted] = useState(false);
  const closePopup = () => {
    setShowPopup(false);
    setCurrentStep(1);
    setIsCompleted(false);
  };

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [fullName, setFullName] = useState("");
  const [dob, setDob] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [selectedParent, setSelectedParent] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");

  const [houseStreetName, setHouseStreetName] = useState("");
  const [villageTownCity, setVillageTownCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [pincode, setPincode] = useState("");
  const [error, setError] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const handleHouseStreetNameChange = (e) => setHouseStreetName(e.target.value);
  const handleVillageTownCityChange = (e) => setVillageTownCity(e.target.value);
  const handleStateChange = (e) => setSelectedState(e.target.value);
  const handleDistrictChange = (e) => setSelectedDistrict(e.target.value);
  const handlePincodeChange = (e) => setPincode(e.target.value);
  const handleFatherNameChange = (e) => setFatherName(e.target.value);
  const handleMotherNameChange = (e) => setMotherName(e.target.value);
  const handleAadharChange = (e) => setAadharNumber(e.target.value);
  const handleParentSelection = (e) => setSelectedParent(e.target.value);
  const handleMobileNumberChange = (e) => setMobileNumber(e.target.value);
  const handleEmailIdChange = (e) => setEmailId(e.target.value);

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleDobChange = (event) => {
    setDob(event.target.value);
  };

  const handleAgreementChange = (event) => {
    setSelectedAgreement(event.target.value);
    // console.log("Selected Agreement:", event.target.value); // Debug log
  };

  const [otp, setOtp] = useState(["", "", "", ""]); // OTP input fields
  const [maskedNumber, setMaskedNumber] = useState("XXXX-XXXX-XXXX"); // Masked number for OTP display
  const [resendCountdown, setResendCountdown] = useState(30); // Countdown for resend OTP
  const [isResending, setIsResending] = useState(false); // To handle resend state
  const [isOtpSent, setIsOtpSent] = useState(false); // To check if OTP has been sent

  // Handle OTP input change
  const handleChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  // Handle backspace for OTP input
  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);
    }
  };

  // Send OTP API call
  const handleSendOtp = async () => {
    if (!mobileNumber) {
      alert("Please enter your mobile number.");
      return;
    }
  
    try {
      const response = await fetch(
        "https://makemydocuments.nakshatranamahacreations.in/otp.php",
        {
          method: "POST",
          mode:'no-cors',
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            mobilenumber: mobileNumber,
          }),
        }
      );
  
      console.log("Response status:", response.status);
      console.log("Response headers:", [...response.headers]);
  
      // Debug the raw response text
      const rawResponse = await response.text();
      console.log("Raw response:", rawResponse);
  
      // Attempt to parse JSON only if the response is not empty
      if (rawResponse) {
        const data = JSON.parse(rawResponse); // Safely parse JSON
        console.log("Parsed response:", data);
  
        if (data.success) {
          setIsOtpSent(true);
          setMaskedNumber(
            mobileNumber.replace(/(\d{4})(\d{4})(\d{4})/, "$1-XXXX-XXXX") // Mask the actual mobile number
          );
          startResendCountdown();
          alert("OTP sent successfully!");
        } else {
          alert("Error sending OTP. Please try again.");
        }
      } else {
        alert("Empty response from server. Please try again later.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      alert("An error occurred while sending OTP.");
    }
  };
  

  // Resend OTP API call
  const handleResend = async () => {
    if (!mobileNumber) {
      alert("Mobile number is required to resend OTP.");
      return;
    }

    if (isResending) return; // Prevent multiple resend requests
    setIsResending(true);

    try {
      const response = await fetch(
        "https://makemydocuments.nakshatranamahacreations.in/resendotp.php",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            mobilenumber: mobileNumber, // Use the actual state variable
          }),
        }
      );

      const data = await response.json();
      if (data.success) {
        setResendCountdown(30); // Reset countdown to 30 seconds
        startResendCountdown();
        alert("OTP resent successfully!");
      } else {
        alert("Error resending OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
      alert("An error occurred while resending OTP.");
    }

    setIsResending(false);
  };

  // Start resend countdown
  const startResendCountdown = () => {
    const interval = setInterval(() => {
      setResendCountdown((prev) => {
        if (prev === 1) {
          clearInterval(interval);
        }
        return prev - 1;
      });
    }, 1000);
  };

  // Verify OTP API call
  const handleVerify = async () => {
    const enteredOtp = otp.join(""); // Combine OTP input values into a single string

    if (!mobileNumber) {
      alert("Mobile number is required to verify OTP.");
      return;
    }

    if (enteredOtp.length !== 4) {
      alert("Please enter all 4 digits of OTP.");
      return;
    }

    try {
      const response = await fetch(
        "https://makemydocuments.nakshatranamahacreations.in/verifyotp.php",
        {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            mobilenumber: mobileNumber, // Use the actual state variable
            otp: enteredOtp, // Use the OTP entered by the user
          }),
        }
      );

      const data = await response.json();
      if (data.success) {
        alert("OTP Verified Successfully!");
        // Proceed to the next step or handle success
      } else {
        alert("Incorrect OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      alert("An error occurred while verifying OTP.");
    }
  };
  // Navigate steps
  // Navigate steps
  const nextStep = () => {
    // Validate all required fields for the current step
    if (currentStep === 2) {
      if (!selectedGender) {
        setError("Please select your gender.");
        return;
      }
      if (!fullName) {
        setError("Please enter your full name.");
        return;
      }
      if (!dob) {
        setError("Please select your date of birth.");
        return;
      }
      if (
        (selectedAgreement === "correctionInPanCard" ||
          selectedAgreement === "lostDamagePanCard") &&
        !existingPanCardNumber
      ) {
        setError("Please enter your existing Pan Card number.");
        return;
      }
    }

    if (currentStep === 3) {
      if (!fatherName) {
        setError("Please enter Father's Name.");
        return;
      }
      if (!motherName) {
        setError("Please enter Mother's Name.");
        return;
      }
      if (!selectedParent) {
        setError(
          "Please select the name (Father or Mother) to print on the PAN card."
        );
        return;
      }
      if (!aadharNumber) {
        setError("Please enter your Aadhar number.");
        return;
      }
    }

    if (currentStep === 4) {
      if (!houseStreetName) {
        setError("Please enter House No. and Street Name.");
        return;
      }
      if (!villageTownCity) {
        setError("Please enter Village / Town / City.");
        return;
      }
      if (!selectedState) {
        setError("Please select your State.");
        return;
      }
      if (!selectedDistrict) {
        setError("Please select your District.");
        return;
      }
      if (!pincode) {
        setError("Please enter your Pin Code.");
        return;
      }
    }

    if (currentStep === 5) {
      const mobileRegex = /^[0-9]{10}$/;
      if (!mobileNumber) {
        setError("Please enter your mobile number.");
        return;
      }
      if (!mobileRegex.test(mobileNumber)) {
        setError("Please enter a valid 10-digit mobile number.");
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailId && !emailRegex.test(emailId)) {
        setError("Please enter a valid email address.");
        return;
      }
    }

    // Proceed to the next step
    if (currentStep < 5) {
      setCurrentStep(currentStep + 1);
      setError(""); // Clear the error message on valid form submission
    }
  };

  const submitDataToAPI = async () => {
    const data = {
      name: fullName || "", 
      mobilenumber: mobileNumber || "", 
      email: emailId || "",
      services: selectedOption || "", 
      address: houseStreetName || "", 
      district: villageTownCity || "",
      date: dob || "", 
      paidamount: "0.00",
      qualification: "", 
      gender: selectedGender || "", 
      fathername: fatherName || "", 
      mothername: motherName || "", 
      pincode: pincode || "", 
      adharnumber: aadharNumber || "", 
      pancard: "", 
      time: "00:00:00", 
      comment: "", 
      status: "",
      service: "pancard ",
      followuptime: "00:00:00", 
      existingpancardnumber: existingPanCardNumber || "", 
      village: villageTownCity || "", 
      "pancard-state": selectedState || "", 
      "pancard-district": selectedDistrict || "", 
    };

    console.log("Data being sent to API:", data); // For debugging

    try {
      const response = await axios.post(
        "https://makemydocuments.nakshatranamahacreations.in/create.php",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("API Response:", response.data); // Log API response
      if (response.data.success) {
        alert("Details saved successfully!");
      } else {
      }
    } catch (error) {
      console.error("Error while saving data:", error);
      alert("An error occurred while saving your details. Please try again.");
    }
  };

  const prevStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const getMaskedMobileNumber = (number) => {
    if (!number || number.length < 3) return ""; // Return empty if number is too short
    const firstTwo = number.slice(0, 2); // First two digits
    const lastDigit = number.slice(-1); // Last digit
    const masked = `${firstTwo}******${lastDigit}`; // Mask the middle digits
    return masked;
  };

  const finishSubmission = () => {
    submitDataToAPI();
    if (mobileNumber) {
      setMaskedNumber(getMaskedMobileNumber(mobileNumber)); // Mask the mobile number
    }
    setIsCompleted(true);
  };

  const [selectedAgreement, setSelectedAgreement] = useState("");
  const [existingPanCardNumber, setExistingPanCardNumber] = useState("");

  const handleExistingPanCardChange = (event) => {
    setExistingPanCardNumber(event.target.value);
  };

  // Countdown timer for resend button
  React.useEffect(() => {
    if (resendCountdown > 0) {
      const timer = setInterval(
        () => setResendCountdown(resendCountdown - 1),
        1000
      );
      return () => clearInterval(timer);
    }
  }, [resendCountdown]);

  const faqs = [
    {
      question: "Benefits of Lease Agreement?",
      answer: (
        <ul style={{ listStyleType: "disc" }}>
          <li>Minimizes the conflicts between a tenant and the owner</li>
          <li>Rental/lease agreement acts as an address proof</li>
          <li>Acts as a proof for Bank loans</li>
          <li>Helps in investment & loan</li>
          <li>Vehicle registration</li>
        </ul>
      ),
    },
    {
      question: "Difference between comprehensive and third-party liability?",
      answer:
        "Comprehensive insurance covers both third-party liabilities and damage to your own vehicle, while third-party liability only covers damage caused to others.",
    },
    {
      question: "What is the different between rental/lease agreement?",
      answer:
        "Leasing is defined as a contract between lessor and lessee whereby the lessor buys the asset and lets the lessee use the asset for a particular period. Renting is to allow the other party to occupy or use the asset for a short time, in return for a fixed payment.",
    },
    {
      question: "what is stamp paper?",
      answer:
        "Stamp papers which are used for execution of documents are called as Non-Judicial Stamp Papers. ... Stamp Duty paid in respect of Non-Judicial Stamp Paper is paid under The Indian Stamp Act, 1899 and Stamp Duty paid in respect of Judicial Stamp Paper is paid under the Court Fees Act, 1870",
    },
    {
      question: "Do I need to visit any office to get lease agreement?",
      answer:
        "No its completely online process once you registered in our website we create draft and it will be print on stamp paper, and it get dispatched to address.",
    },
    {
      question: "How will I get my lease agreement?",
      answer: "It will get dispatched through courier.",
    },
    {
      question: "What is the value of stamp paper for lease agreement?",
      answer:
        "The Rental/Lease agreement must be printed on a Non-Judicial Stamp Paper with a value of Rs.100/- or more.",
    },
    {
      question: "What is validity of lease agreement?",
      answer:
        "In the usual practice, a landlord and a tenant make a rent agreement for a period of 11 months, with an option for a periodic renewal.",
    },
    {
      question: "what is Notarized lease agreement?",
      answer:
        "A notarized agreement is a document that has been marked with a notary stamp, which indicates that the signature on the document is legal. A notary officers is the witness when you sign and then places the stamp near your signature.",
    },
    {
      question: "Is agreement required to be notarised?",
      answer:
        "A contract typically does not have to be notarized. A notary public  provides an acknowledgment that the signature appearing on the document is that of the person whose signature it purports to be. There is a requirement that some documents be notarized, such as a real property deed.",
    },
  ];

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      <div
        style={{
          background: "linear-gradient(182.42deg, #FCA505 2.01%, #FFFFFF)",
          minHeight: "60vh",
          paddingTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 20px",
        }}
      >
        <div style={{ flex: 1, textAlign: "left", fontWeight: "bold" }}>
          {/* <h2 style={{marginLeft:"25%", marginBottom:"60px",fontWeight:"bold"}}>Lease Agreement Online</h2> */}
          <p>"Expert Assistance for Quick and Convenient</p>
          <p>PAN Card Services - Apply Now!"</p>
        </div>
        <div>
          <img
            src={Image30}
            alt="Lease Agreement"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      </div>

      <div style={{ padding: "10px", textAlign: "left", marginLeft: "8%" }}>
        <h2
          style={{
            color: "#1A76D8",
            fontWeight: "bold",
            marginLeft: "20px",
            fontSize: "22px",
          }}
        >
          Documents Required For New Pan Card
        </h2>
        <p style={{ textAlign: "left", padding: "2%", fontWeight: "600" }}>
          <span style={{}}>Aadhaar card</span>
          <br />
          {/* <span style={{ }}>Service available only in Karnataka.</span><br />
                    <span style={{  }}>Draft will be shared to your mail id within 60min.</span> */}
        </p>
      </div>

      <div style={{ padding: "10px", textAlign: "left", marginLeft: "8%" }}>
        <h2
          style={{
            color: "#1A76D8",
            fontWeight: "bold",
            marginLeft: "20px",
            fontSize: "22px",
          }}
        >
          Documents Required For Correction/Lost/damage Pan Card
        </h2>
        <p style={{ textAlign: "left", padding: "2%", fontWeight: "600" }}>
          <span style={{}}>Aadhaar card</span>
          <br />
          <span style={{}}>Existing Pan copy</span>
          <br />
          {/* <span style={{  }}>Draft will be shared to your mail id within 60min.</span> */}
        </p>
      </div>

      <div
        className="content-section"
        style={{
          backgroundColor: "#fffff",
          padding: "30px 15px",
          borderRadius: "10px",
          margin: "-1% auto",
          marginRight: "72%",
        }}
      >
        <div className="row justify-content-center">
          {/* Main Column for Vertical Layout */}
          <div className="col-12 col-md-8 position-relative">
            {/* First Section: Documents */}
            <div className="text-center mb-5">
              <div style={{ position: "relative" }}>
                <img
                  src={circleIcon}
                  alt="Circle Background"
                  className="img-fluid"
                />
                <img
                  src={documentsIcon}
                  alt="Documents Icon"
                  style={{
                    position: "absolute",
                    top: "45%",
                    left: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
            </div>

            <div
              style={{
                borderLeft: "3px solid #007BFF",
                height: "100%",
                // margin: '0 auto',
                width: "4px",
                marginTop: "-20%",
                marginLeft: "50%",
              }}
            ></div>

            {/* Second Section: How It Works */}
            <div className="text-center mb-5">
              <div style={{ position: "relative" }}>
                <img
                  src={circleIcon}
                  alt="Circle Background"
                  className="img-fluid"
                />
                <img
                  src={howIcon}
                  alt="How It Works Icon"
                  style={{
                    position: "absolute",
                    top: "45%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
            </div>

            {/* Blue Line */}
            <div
              style={{
                borderLeft: "3px solid #007BFF",
                height: "150%",
                // margin: '0 auto',
                marginLeft: "49.8%",
                marginTop: "-48%",
                width: "4px",
              }}
            ></div>

            {/* Third Section */}
            <div className="text-center mb-5">
              <div style={{ position: "relative" }}>
                <img
                  src={circleIcon}
                  alt="Circle Background"
                  className="img-fluid"
                />
                <img
                  src={Price}
                  alt="How It Works Icon"
                  style={{
                    position: "absolute",
                    top: "45%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <>
          <div
            className="mb-5"
            style={{
              marginTop: "-45%",
              marginLeft: "70%",
            }}
          >
            <h4
              style={{
                color: "#007BFF",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
            >
              How It Works <span color="#1A76D8">*</span>
            </h4>
            <ul
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                columnGap: "40px",
                rowGap: "20px",
                listStyleType: "disc",
                paddingLeft: "20px",
                whiteSpace: "nowrap",
                margin: "20px 0",
              }}
            >
              <li style={{ fontWeight: "bold" }}>Register and Pay Online</li>
              <li style={{ fontWeight: "bold" }}>Upload Documents</li>
              <li style={{ fontWeight: "bold" }}>Online KYC </li>
              <li style={{ fontWeight: "bold" }}>Get Delivered</li>
            </ul>
          </div>
          <div style={{ marginLeft: "72%", marginTop: "36%" }}>
            <h4
              style={{
                color: "#007BFF",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
            >
              Time Duration
            </h4>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "20px",
                lineHeight: "1.8",
                whiteSpace: "nowrap",
              }}
            >
              <li style={{ fontWeight: "bold", marginBottom: "10px" }}>
                15-20 working days (New Physical Pan Card)
              </li>
              <li style={{ fontWeight: "bold", marginBottom: "10px" }}>
                20-30 working days (Lost/Correction)
              </li>
              <li style={{ fontWeight: "bold", marginBottom: "10px" }}>
                E- Pan Card Copy 24 - 48 hrs (New Application Only)
              </li>
              <li style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Applicant Will Receive Both E- Pan And Physical Card
              </li>
            </ul>
          </div>
          <div style={{ marginLeft: "72%", marginTop: "28%" }}>
            <h4
              style={{
                color: "#007BFF",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
            >
              Charges
            </h4>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "20px",
                lineHeight: "1.8",
                whiteSpace: "nowrap",
              }}
            >
              <li style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Rs.399, Rs.350
              </li>
              <li style={{ fontWeight: "bold", marginBottom: "10px" }}>
                If you decide to cancel your order after payment, please note
                that a cancellation fee of Rs. 50 will apply.
              </li>
              {/* <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Note: Additional charges for stamp paper</li> */}
            </ul>
          </div>
        </>
      </div>
      <div>
        {/* Get Quotes Button */}
        <div style={{ textAlign: "center", marginTop: "4%" }}>
          <button
            style={{
              backgroundColor: "#FCA505",
              color: "#000000",
              padding: "12px 50px",
              border: "none",
              borderRadius: "30px",
              fontWeight: "bold",
              cursor: "pointer",
              fontSize: "16px",
              marginRight: "40%",
              marginTop: "-30%",
            }}
            onClick={() => setShowPopup(true)}
          >
            CONTINUE
          </button>
        </div>
        {/* Modal Popup */}
        {showPopup && (
          <div
            style={{
              position: "fixed",
              top: "100px",
              left: "0",
              width: "100%",
              height: "86%",
              backgroundColor: "rgba(26, 118, 216, 0.9)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "9999",
            }}
          >
            <div
              style={{
                backgroundColor: "#FFFFFF",
                padding: "40px",
                borderRadius: "28px",
                width: "70%",
                maxHeight: "90%", // Maximum height of the popup
                overflowY: "auto", // Enable scrolling if content overflows
                textAlign: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              {/* Stepper */}

              {!isCompleted ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "30px",
                    }}
                  >
                    {Array.from({ length: 5 }).map((_, index) => (
                      <React.Fragment key={index}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            flex: 1,
                          }}
                        >
                          <button
                            onClick={() => setCurrentStep(index + 1)} // Make step clickable
                            style={{
                              width: "50px",
                              height: "50px",
                              backgroundColor:
                                currentStep >= index + 1 ? "#4285F4" : "#ccc",
                              color: "white",
                              borderRadius: "50%",
                              lineHeight: "50px",
                              fontWeight: "bold",
                              border: "none",
                              cursor: "pointer",
                              outline: "none",
                            }}
                          >
                            {index + 1}
                          </button>
                          {/* Optional Step Labels */}
                          {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step {index + 1}</span> */}
                        </div>

                        {/* Add Connection Divider Between Steps */}
                        {index < 4 && (
                          <div
                            style={{
                              height: "2px",
                              flex: 1,
                              backgroundColor:
                                currentStep > index + 1 ? "#4285F4" : "#ccc",
                              alignSelf: "center",
                            }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </div>

                  <div style={{ marginBottom: "20px" }}>
                    {currentStep === 1 && (
                      <div style={{ textAlign: "center" }}>
                        <h5 style={{ color: "#007BFF", fontWeight: "bold" }}>
                          Applying for <span style={{ color: "red" }}>*</span>
                        </h5>
                        {error && (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {error}
                          </p>
                        )}{" "}
                        {/* Show error message */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            marginLeft: "4%",
                            width: "fit-content",
                            gap: "20px",
                          }}
                        >
                          <label style={{ fontSize: "18px", color: "#333" }}>
                            <input
                              type="radio"
                              name="agreementOption"
                              value="newPanCard"
                              onChange={handleAgreementChange}
                              required
                              style={{ marginRight: "10px" }}
                            />
                            New Pan Card
                          </label>
                          <label style={{ fontSize: "18px", color: "#333" }}>
                            <input
                              type="radio"
                              name="agreementOption"
                              value="correctionInPanCard"
                              onChange={handleAgreementChange}
                              required
                              style={{ marginRight: "10px" }}
                            />
                            Correction in Pan Card
                          </label>
                          <label style={{ fontSize: "18px", color: "#333" }}>
                            <input
                              type="radio"
                              name="agreementOption"
                              value="lostDamagePanCard"
                              onChange={handleAgreementChange}
                              required
                              style={{ marginRight: "10px" }}
                            />
                            Lost/Damage Pan Card
                          </label>
                        </div>
                      </div>
                    )}

                    {currentStep === 2 && (
                      <div style={{ textAlign: "center" }}>
                        <h5 style={{ color: "#007BFF", fontWeight: "bold" }}>
                          I Am <span style={{ color: "red" }}>*</span>
                        </h5>
                        {error && (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {error}
                          </p>
                        )}

                        {/* Existing Pan Card Number */}
                        {(selectedAgreement === "correctionInPanCard" ||
                          selectedAgreement === "lostDamagePanCard") && (
                          <div
                            style={{ marginBottom: "33px", textAlign: "left" }}
                          >
                            <label
                              htmlFor="existingPanCardNumber"
                              style={{
                                display: "block",
                                marginBottom: "10px",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              Existing Pan Card Number
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              id="existingPanCardNumber"
                              value={existingPanCardNumber}
                              onChange={handleExistingPanCardChange}
                              style={{
                                width: "100%",
                                padding: "10px",
                                fontSize: "16px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                              }}
                            />
                          </div>
                        )}

                        {/* Applicant's Full Name */}
                        <div
                          style={{ marginBottom: "33px", textAlign: "left" }}
                        >
                          <label
                            htmlFor="fullName"
                            style={{
                              display: "block",
                              marginBottom: "10px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Applicant’s Full Name (Mention Name As Per Aadhaar)
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="fullName"
                            value={fullName}
                            onChange={handleFullNameChange}
                            style={{
                              width: "100%",
                              padding: "10px",
                              fontSize: "16px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                            }}
                          />
                        </div>

                        {/* Gender */}
                        <div
                          style={{
                            marginBottom: "33px",
                            textAlign: "left",
                            gap: "10px",
                          }}
                        >
                          <label
                            style={{ fontSize: "16px", fontWeight: "500" }}
                          >
                            Gender <span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          <div>
                            <label style={{ marginRight: "20px" }}>
                              <input
                                type="radio"
                                name="gender"
                                value="female"
                                onChange={handleGenderChange}
                                style={{ marginRight: "10px" }}
                              />
                              Female
                            </label>
                            <br />
                            <label style={{ marginRight: "20px" }}>
                              <input
                                type="radio"
                                name="gender"
                                value="male"
                                onChange={handleGenderChange}
                                style={{ marginRight: "10px" }}
                              />
                              Male
                            </label>
                            <br />
                            <label style={{ marginRight: "20px" }}>
                              <input
                                type="radio"
                                name="gender"
                                value="transgender"
                                onChange={handleGenderChange}
                                style={{ marginRight: "10px" }}
                              />
                              Transgender
                            </label>
                          </div>
                        </div>

                        {/* Date of Birth */}
                        <div
                          style={{ marginBottom: "33px", textAlign: "left" }}
                        >
                          <label
                            htmlFor="dob"
                            style={{
                              display: "block",
                              marginBottom: "10px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Date of Birth{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            id="dob"
                            value={dob}
                            onChange={handleDobChange}
                            style={{
                              width: "100%",
                              padding: "10px",
                              fontSize: "16px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {currentStep === 3 && (
                      <div style={{ textAlign: "center" }}>
                        {/* Step 3 Heading */}
                        <p style={{ color: "#1A76D8", fontWeight: "600" }}>
                          Parents Details<span style={{ color: "red" }}>*</span>
                        </p>
                        {error && (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {error}
                          </p>
                        )}{" "}
                        {/* Show error message */}
                        {/* Father's Name */}
                        <div
                          style={{ marginBottom: "20px", textAlign: "left" }}
                        >
                          <label
                            htmlFor="fathername"
                            style={{
                              display: "block",
                              marginBottom: "10px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Father's Name
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="fathername"
                            value={fatherName}
                            onChange={handleFatherNameChange}
                            style={{
                              width: "100%",
                              height: "45px",
                              padding: "10px",
                              fontSize: "16px",
                              border: "2px solid #FCA505",
                              borderRadius: "4px",
                            }}
                          />
                        </div>
                        {/* Mother's Name */}
                        <div
                          style={{ marginBottom: "20px", textAlign: "left" }}
                        >
                          <label
                            htmlFor="mothername"
                            style={{
                              display: "block",
                              marginBottom: "10px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Mother's Name{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="mothername"
                            value={motherName}
                            onChange={handleMotherNameChange}
                            style={{
                              width: "100%",
                              height: "45px",
                              padding: "10px",
                              fontSize: "16px",
                              border: "2px solid #FCA505",
                              borderRadius: "4px",
                            }}
                          />
                        </div>
                        {/* Parent Name Selection */}
                        <div
                          style={{
                            display: "flex",
                            textAlign: "left",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            width: "fit-content",
                            gap: "20px",
                            fontWeight: "500",
                          }}
                        >
                          <h5>
                            Select the name either father or mother which you
                            may like to print on PAN CARD
                            <span style={{ color: "red" }}>*</span>
                          </h5>
                          <label style={{ fontSize: "16px", color: "#333" }}>
                            <input
                              type="radio"
                              name="parentName"
                              value="father"
                              onChange={handleParentSelection}
                              style={{ marginRight: "10px" }}
                            />
                            Father
                          </label>
                          <label style={{ fontSize: "16px", color: "#333" }}>
                            <input
                              type="radio"
                              name="parentName"
                              value="mother"
                              onChange={handleParentSelection}
                              style={{ marginRight: "10px" }}
                            />
                            Mother
                          </label>
                        </div>
                        <br />
                        {/* Aadhar Number */}
                        <div
                          style={{ marginBottom: "20px", textAlign: "left" }}
                        >
                          <label
                            htmlFor="aadhar"
                            style={{
                              display: "block",
                              marginBottom: "10px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Please Mention your AADHAR Number
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="aadhar"
                            value={aadharNumber}
                            onChange={handleAadharChange}
                            style={{
                              width: "100%",
                              height: "45px",
                              padding: "10px",
                              fontSize: "16px",
                              border: "2px solid #FCA505",
                              borderRadius: "4px",
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {currentStep === 4 && (
                      <div style={{ textAlign: "center" }}>
                        {/* Address Heading */}
                        <p style={{ color: "#1A76D8", fontWeight: "600" }}>
                          Address
                        </p>
                        {error && (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {error}
                          </p>
                        )}{" "}
                        {/* Show error message */}
                        {/* House No. and Street Name */}
                        <div
                          style={{ marginBottom: "20px", textAlign: "left" }}
                        >
                          <label
                            htmlFor="houseStreetName"
                            style={{
                              display: "block",
                              marginBottom: "10px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            House No. and Street Name{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="houseStreetName"
                            placeholder="Enter House No. and Street Name"
                            value={houseStreetName}
                            onChange={handleHouseStreetNameChange}
                            style={{
                              width: "100%",
                              height: "45px",
                              padding: "10px",
                              fontSize: "16px",
                              border: "2px solid #FCA505",
                              borderRadius: "4px",
                            }}
                          />
                        </div>
                        {/* Village / Town / City */}
                        <div
                          style={{ marginBottom: "20px", textAlign: "left" }}
                        >
                          <label
                            htmlFor="Village"
                            style={{
                              display: "block",
                              marginBottom: "10px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Village / Town / City{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="Village"
                            placeholder="Enter Village / Town / City"
                            value={villageTownCity}
                            onChange={handleVillageTownCityChange}
                            style={{
                              width: "100%",
                              height: "45px",
                              padding: "10px",
                              fontSize: "16px",
                              border: "2px solid #FCA505",
                              borderRadius: "4px",
                            }}
                          />
                        </div>
                        {/* States Dropdown */}
                        <div
                          style={{ marginBottom: "20px", textAlign: "left" }}
                        >
                          <label
                            htmlFor="state"
                            style={{
                              display: "block",
                              marginBottom: "10px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            State <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id="state"
                            value={selectedState}
                            onChange={handleStateChange}
                            style={{
                              width: "100%",
                              height: "45px",
                              padding: "10px",
                              fontSize: "16px",
                              border: "2px solid #FCA505",
                              borderRadius: "4px",
                            }}
                          >
                            <option value="">--Select State--</option>
                            {/* Add all India states here as options */}
                            <option value="AndhraPradesh">
                              Andhra Pradesh
                            </option>
                            <option value="ArunachalPradesh">
                              Arunachal Pradesh
                            </option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            {/* Continue adding other states */}
                          </select>
                        </div>
                        {/* District Dropdown */}
                        <div
                          style={{ marginBottom: "20px", textAlign: "left" }}
                        >
                          <label
                            htmlFor="district"
                            style={{
                              display: "block",
                              marginBottom: "10px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            District <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id="district"
                            value={selectedDistrict}
                            onChange={handleDistrictChange}
                            style={{
                              width: "100%",
                              height: "45px",
                              padding: "10px",
                              fontSize: "16px",
                              border: "2px solid #FCA505",
                              borderRadius: "4px",
                            }}
                          >
                            <option value="">--Select District--</option>

                            <option value="AndhraPradesh">Mysuru</option>
                            <option value="ArunachalPradesh">Bidar</option>
                            <option value="Assam">Bengaluru urban</option>
                            <option value="Bihar">Bengaluru Rural</option>
                            <option value="Chhattisgarh">Hunsur</option>
                          </select>
                        </div>
                        {/* Pin Code */}
                        <div
                          style={{ marginBottom: "20px", textAlign: "left" }}
                        >
                          <label
                            htmlFor="pincode"
                            style={{
                              display: "block",
                              marginBottom: "10px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Pin Code <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="pincode"
                            placeholder="Enter Pin Code"
                            value={pincode}
                            onChange={handlePincodeChange}
                            style={{
                              width: "100%",
                              height: "45px",
                              padding: "10px",
                              fontSize: "16px",
                              border: "2px solid #FCA505",
                              borderRadius: "4px",
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {currentStep === 5 && (
                      <div style={{ textAlign: "center" }}>
                        <p style={{ color: "#1A76D8", fontWeight: "600" }}>
                          Contact Details<span style={{ color: "red" }}>*</span>
                        </p>
                        {error && (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {error}
                          </p>
                        )}

                        <div
                          style={{
                            marginBottom: "20px",
                            textAlign: "left",
                          }}
                        >
                          <label
                            htmlFor="mobilenumber"
                            style={{
                              display: "block",
                              marginBottom: "10px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Mobile Number{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="mobilenumber"
                            placeholder="Enter Mobile Number"
                            value={mobileNumber}
                            onChange={handleMobileNumberChange}
                            style={{
                              width: "100%",
                              height: "45px",
                              padding: "10px",
                              fontSize: "16px",
                              border: "2px solid #FCA505",
                              borderRadius: "4px",
                            }}
                          />
                        </div>

                        {/* Email Id */}
                        <div
                          style={{ marginBottom: "20px", textAlign: "left" }}
                        >
                          <label
                            htmlFor="emailid"
                            style={{
                              display: "block",
                              marginBottom: "10px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Email Id
                          </label>
                          <input
                            type="text"
                            id="emailid"
                            placeholder="Enter Email Id"
                            value={emailId}
                            onChange={handleEmailIdChange}
                            style={{
                              width: "100%",
                              height: "45px",
                              padding: "10px",
                              fontSize: "16px",
                              border: "2px solid #FCA505",
                              borderRadius: "4px",
                            }}
                          />
                        </div>

                        <p
                          style={{
                            marginTop: "20px",
                            fontSize: "14px",
                            textAlign: "left",
                          }}
                        >
                          By clicking submit, you agree to our{" "}
                          <a
                            href="/terms"
                            style={{
                              color: "#007BFF",
                              textDecoration: "underline",
                            }}
                          >
                            Terms & Conditions
                          </a>{" "}
                          and{" "}
                          <a
                            href="/privacy-policy"
                            style={{
                              color: "#007BFF",
                              textDecoration: "underline",
                            }}
                          >
                            Privacy Policy
                          </a>
                          .
                        </p>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    {currentStep > 1 && (
                      <button
                        onClick={prevStep}
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#FCA505",
                          color: "#000000",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Back
                      </button>
                    )}

                    {currentStep < 5 ? (
                      <button
                        onClick={nextStep}
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#FCA505",
                          color: "#000000",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          if (!mobileNumber) {
                            setError("Mobile number is required."); 
                            return;
                          }
                          handleSendOtp(); 
                          finishSubmission();
                        }}
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#FCA505",
                          color: "#000000",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        SUBMIT
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <div style={{ textAlign: "center", padding: "20px" }}>
                  <h4
                    style={{
                      color: "#007BFF",
                      fontWeight: "bold",
                      display: "inline-block",
                      marginRight: "10px",
                    }}
                  >
                    OTP sent on {maskedNumber}
                  </h4>
                  <a
                    href="#"
                    onClick={() => setCurrent(1)} // Redirect to step 1
                    style={{
                      fontSize: "14px",
                      color: "#007BFF",
                      textDecoration: "none",
                    }}
                  >
                    Edit
                  </a>

                  <div style={{ margin: "20px 0" }}>
                    <label
                      style={{
                        display: "block",
                        fontWeight: "500",
                        marginBottom: "10px",
                      }}
                    >
                      Enter OTP <span style={{ color: "red" }}>*</span>
                    </label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          id={`otp-input-${index}`}
                          type="text"
                          maxLength="1"
                          value={digit}
                          onChange={(e) => handleChange(e.target.value, index)}
                          onKeyDown={(e) => handleBackspace(e, index)}
                          style={{
                            width: "50px",
                            height: "50px",
                            textAlign: "center",
                            fontSize: "18px",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                          }}
                        />
                      ))}
                    </div>
                  </div>

                  <p style={{ fontSize: "14px", color: "#888" }}>
                    {resendCountdown > 0 ? (
                      <>Not Received? Resend in {resendCountdown}s</>
                    ) : (
                      <a
                        href="#"
                        onClick={handleResend}
                        style={{
                          textDecoration: "none",
                          color: isResending ? "#ccc" : "#007BFF",
                          pointerEvents: isResending ? "none" : "auto",
                        }}
                      >
                        Resend OTP
                      </a>
                    )}
                  </p>

                  <button
                    onClick={handleVerify}
                    style={{
                      backgroundColor: "#FFA500",
                      color: "#00000",
                      border: "none",
                      borderRadius: "5px",
                      padding: "10px 20px",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                  >
                    VERIFY
                  </button>
                </div>
              )}

              {/* Close Button */}
              <button
                onClick={closePopup}
                style={{
                  position: "absolute",
                  top: "25px",
                  left: "8%",
                  background: "#000000",
                  border: "1px solid #FCA505",
                  fontSize: "20px",
                  padding: "8px", // Adjust padding as needed
                  borderRadius: "5px",
                  cursor: "pointer",
                  color: "#fff", // Icon color
                }}
              >
                <FaArrowLeft /> {/* Displaying the back arrow icon */}
              </button>
            </div>
          </div>
        )}
      </div>

      <div
        className="faq-section"
        style={{
          margin: "50px auto",
          padding: "20px",
          background: "#FFFFFF",
          borderRadius: "10px",
          width: "80%",
        }}
      >
        <h4
          style={{
            textAlign: "center",
            fontWeight: "bold",
            color: "#007BFF",
            marginBottom: "20px",
          }}
        >
          FAQs
        </h4>
        <p
          style={{
            textAlign: "center",
            fontWeight: "500",
            marginBottom: "30px",
          }}
        >
          Need help? Contact us for any queries related to us
        </p>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="faq-item"
              style={{ marginBottom: "10px" }}
            >
              <button
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  padding: "10px 20px",
                  border: "1px solid #007BFF",
                  borderRadius: "5px",
                  background: "#F9F9F9",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={() => handleToggle(index)}
              >
                {faq.question}
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginLeft: "10px",
                  }}
                >
                  {openIndex === index ? "▲" : "▼"}
                </span>
              </button>
              {openIndex === index && (
                <div
                  style={{
                    marginTop: "10px",
                    padding: "10px 20px",
                    background: "#F3F3F3",
                    borderRadius: "5px",
                    color: "#333",
                  }}
                >
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
        <br></br>
        <>
          <p>
            Welcome to Make My Documents, your one-stop destination for all your
            PAN card needs. A PAN card is an essential document that serves as a
            unique identification number for individuals and businesses in
            India. It is required for various financial transactions and other
            purposes.
          </p>

          <p>
            At Make My Douments, we understand the importance of having a PAN
            card, which is why we offer a hassle-free solution to help you apply
            for your PAN card quickly and easily. Our online PAN card
            application process is simple and user-friendly, allowing you to
            apply for a new PAN card, update your existing PAN card, or apply
            for a duplicate PAN card.
          </p>

          <p>
            Our team of experts will guide you through every step of the
            process, ensuring that your PAN card application is completed
            accurately and efficiently. We provide PAN card application services
            for individuals, businesses, and NRIs. You can also check the status
            of your PAN card application online.
          </p>

          <p>
            We also offer a range of additional PAN card services, including PAN
            card corrections, lost or damaged PAN card replacements, PAN card
            verification, and PAN card updates. Our services are competitively
            priced, and our fast turnaround times mean that you can have your
            PAN card in hand as soon as possible.
          </p>

          <p>
            Applying for a PAN card has never been easier. You can apply for PAN
            card online through our website, which offers a PAN card online
            application form that is easy to fill out. We also provide a PAN
            card form that can be downloaded for offline applications. Our team
            will take care of the rest and keep you updated on the PAN card
            status.
          </p>

          <p>
            The PAN card fees are reasonable and our PAN card services are
            reliable. We ensure that our PAN card services cater to the needs of
            individuals, businesses.
          </p>

          <p>
            If you have any questions about our PAN card services, our team is
            always ready to assist you. Contact us today to learn more about our
            PAN card services, fees, and to schedule an appointment with one of
            our experts. We look forward to helping you obtain your PAN card and
            enjoy a stress-free experience.
          </p>
        </>
      </div>
    </>
  );
};

export default Pancard;
