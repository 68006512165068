import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Contact = () => {
  return (
    <div className="py-5" style={styles.container}>
      <div className="container">
        {/* <h1 className="text-center mb-4">Contact Us</h1> */}
        
        <div className="row">
          {/* Google Map */}
          <div className="col-md-6 mb-4">
            <h2>Our Location</h2>
            <iframe
              width="100%"
              height="300"
              style={{ borderRadius: '10px' }}
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src={`https://www.google.com/maps/embed/v1/place?q=No%20344%2C%202nd%20Main%20Rd%2C%20Manjunath%20Nagar%2C%20Mookambika%20Nagar%2C%20Dattatreya%20Nagar%2C%20Hosakerehalli%2C%20Bengaluru%2C%20Karnataka%20560085&key=YOUR_GOOGLE_MAPS_API_KEY`}
              allowFullScreen
            ></iframe>
          </div>

          {/* Contact Details */}
          <div className="col-md-6 mb-4 d-flex flex-column align-items-center">
            <h2>Contact Details</h2>
            <p className="ml-4" style={{marginTop:"10%"}}><strong>Mobile:</strong> +91 942 969 0973</p>
            <p className="ml-8" style={{marginLeft:"25%"}} ><strong>Email:</strong> support@makemydocuments.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    background: 'linear-gradient(to right, #00bfff, #ffffff)', // Blue and white gradient
    color: 'black',
  },
};

export default Contact;
