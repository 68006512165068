import React, { useState } from "react";

const Carrer = () => {


    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const [formData, setFormData] = useState({
      name: "",
      mobile: "",
      email: "",
      resume: null,
    });
  
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
   
    const handleFileChange = (e) => {
      setFormData((prevData) => ({
        ...prevData,
        resume: e.target.files[0],
      }));
    };

    const handleSubmit = (e) => {
      e.preventDefault();
    
      console.log("Form Data Submitted:", formData);
      setIsPopupOpen(false);
    };


  return (
    <>
      <div
        className="container-fluid"
        style={{
          background: "linear-gradient(182.42deg, #FCA505 2.01%, #FFFFFF)",
          minHeight: "65vh",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          color: "#333",
        }}
      >
        <h1 style={{ fontSize: "3rem", fontWeight: "bold", marginBottom: "1rem" }}>
          Career
        </h1>
        <p style={{ fontSize: "1.2rem", marginBottom: "2rem" }}>
          Join our team and help us build amazing solutions!
        </p>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            maxWidth: "600px",
            width: "100%",
          }}
        >
          {/* Job Position 1 */}
          <div
            style={{
              padding: "1rem",
              background: "#FFF",
              borderRadius: "8px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <h3 style={{ margin: 0 }}>Business Development Manager</h3>
              <p style={{ margin: "0.5rem 0 0", fontSize: "0.9rem", color: "#666" }}>
                Location: Bangalore | Experience: 1+ years
              </p>
            </div>
            <button
              style={{
                padding: "0.5rem 1rem",
                background: "#FCA505",
                border: "none",
                borderRadius: "4px",
                color: "#FFF",
                cursor: "pointer",
              }}
               onClick={() => setIsPopupOpen(true)}
            >
              Apply
            </button>
          </div>

          {/* Job Position 2 */}
          {isPopupOpen && (
            <div
              style={{
                position: "fixed",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: "#fff",
                  padding: "1.5rem", // Reduced height of the form
                  borderRadius: "8px",
                  width: "500px", // Increased form width
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <h3 style={{ marginBottom: "1rem", fontSize: "1.5rem" }}>
  Business Development Manager
</h3>
                <form onSubmit={handleSubmit}>
                  {/* Row 1: Name and Mobile */}
                  <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
                    <div style={{ flex: "1" }}>
                      <label style={{ display: "block", marginBottom: "0.5rem" }}>
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        style={{
                          width: "100%",
                          padding: "0.5rem",
                          borderRadius: "4px",
                        }}
                        required
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <label style={{ display: "block", marginBottom: "0.5rem" }}>
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleInputChange}
                        style={{
                          width: "100%",
                          padding: "0.5rem",
                          borderRadius: "4px",
                        }}
                        required
                      />
                    </div>
                  </div>

                  {/* Row 2: Email and Resume */}
                  <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
                    <div style={{ flex: "1" }}>
                      <label style={{ display: "block", marginBottom: "0.5rem" }}>
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        style={{
                          width: "100%",
                          padding: "0.5rem",
                          borderRadius: "4px",
                        }}
                        required
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <label style={{ display: "block", marginBottom: "0.5rem" }}>
                        Resume Upload (.docx, .pdf, .txt) *
                      </label>
                      <input
                        type="file"
                        accept=".docx, .pdf, .txt"
                        onChange={handleFileChange}
                        style={{
                          width: "100%",
                          padding: "0.5rem",
                          borderRadius: "4px",
                        }}
                        required
                      />
                    </div>
                  </div>

                  {/* Submit Button */}
                  <div style={{ textAlign: "center" }}>
                    <button
                      type="submit"
                      style={{
                        padding: "0.5rem 1rem",
                        background: "#1A76D8",
                        border: "none",
                        borderRadius: "4px",
                        color: "#FFF",
                        cursor: "pointer",
                      }}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      onClick={() => setIsPopupOpen(false)} 
                      style={{
                        padding: "0.5rem 1rem",
                        background: "gray",
                        border: "none",
                        borderRadius: "4px",
                        color: "#FFF",
                        cursor: "pointer",
                        marginLeft: "1rem",
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Carrer;
