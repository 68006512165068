import React, { useState } from 'react';
import Image30 from '../../images/pancard_image.svg'; 
import circleIcon from '../../images/circle1.svg'; 
import documentsIcon from '../../images/documents.svg'; 
import howIcon from '../../images/Time.svg'; 
import Price from '../../images/Price Tag.svg';

const PoliceClearance = () => {
    
    const [openIndex, setOpenIndex] = useState(null);
    const [current, setCurrent] = useState(1); 
    const [showPopup, setShowPopup] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [isCompleted, setIsCompleted] = useState(false);
    const closePopup = () => {
        setShowPopup(false);
        setCurrentStep(1);
        setIsCompleted(false);
      };
    
      // Navigate steps
      const nextStep = () => {
        if (currentStep < 3) setCurrentStep(currentStep + 1);
      };
    
      const prevStep = () => {
        if (currentStep > 1) setCurrentStep(currentStep - 1);
      };

      const finishSubmission = () => {
        setIsCompleted(true); 
      };

      const [otp, setOtp] = useState(["", "", "", ""]);
      const [resendCountdown, setResendCountdown] = useState(30); // Countdown timer for resend button
      const [isResending, setIsResending] = useState(false);
    
      // Handle OTP input
      const handleChange = (value, index) => {
        if (!/^\d*$/.test(value)) return; // Allow only numbers
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
    
        // Automatically move to the next input
        if (value && index < otp.length - 1) {
          document.getElementById(`otp-input-${index + 1}`).focus();
        }
      };
    
    
      const handleBackspace = (e, index) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
          document.getElementById(`otp-input-${index - 1}`).focus();
          const newOtp = [...otp];
          newOtp[index - 1] = "";
          setOtp(newOtp);
        }
      };
    
      // Resend OTP functionality
      const handleResend = () => {
        setIsResending(true);
        setTimeout(() => {
          setResendCountdown(30); // Reset countdown
          setIsResending(false);
        }, 1000); // Simulate resend delay
      };
    
      // Verify OTP
      const handleVerify = () => {
        const enteredOtp = otp.join("");
        if (enteredOtp.length === 4) {
          alert(`OTP Verified: ${enteredOtp}`);
        } else {
          alert("Please enter a valid 4-digit OTP.");
        }
      };
    
      // Countdown timer for resend button
      React.useEffect(() => {
        if (resendCountdown > 0) {
          const timer = setInterval(() => setResendCountdown(resendCountdown - 1), 1000);
          return () => clearInterval(timer);
        }
      }, [resendCountdown]);



  

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };


    return (
        <>
            <div style={{
                background: 'linear-gradient(182.42deg, #FCA505 2.01%, #FFFFFF)',
                minHeight: '60vh',
                paddingTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 20px'
            }}>
                <div style={{ flex: 1, textAlign: 'left', fontWeight:"bold"}}>

                    {/* <h2 style={{marginLeft:"25%", marginBottom:"60px",fontWeight:"bold"}}>Lease Agreement Online</h2> */}
                    <p style={{fontSize:"2rem"}}>Police Clearance Certificate(PCC)</p>
                    {/* <p>PAN Card Services - Apply Now!"</p> */}
                </div>
                <div>
                    <img src={Image30} alt="Lease Agreement" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
            </div>
            <div
                className="content-section"
                style={{
                    backgroundColor: '#fffff',
                    padding: '30px 15px',
                    borderRadius: '10px',
                    margin: '-1% auto',
                    marginRight:'72%',
                }}
            >
                <div className="row justify-content-center">
                    {/* Main Column for Vertical Layout */}
                    <div className="col-12 col-md-8 position-relative">
                        {/* First Section: Documents */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={documentsIcon}
                                    alt="Documents Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div> 
                        </div>
                    
           
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '100%',
                                // margin: '0 auto',
                                width: '4px',
                                marginTop:'-20%',
                                marginLeft:'50%'
                            }}
                        ></div>

                        {/* Second Section: How It Works */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={howIcon}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>

                        {/* Blue Line */}
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '150%',
                                // margin: '0 auto',
                                marginLeft:'49.8%',
                                marginTop:'-48%',
                                width: '4px',
                            }}
                        ></div>

                        {/* Third Section */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={Price}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>
                       
            
                    </div>
                </div>
             <>
             <div
    className="mb-5"
    style={{
        marginTop: '-45%',
        marginLeft: '70%',
    }}
>
    <h4
        style={{
            color: '#007BFF',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
        }}
    >
        Documents Required(Any One Addres Proof)<span color='#1A76D8'>*</span>
    </h4>
    <ul
        style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)', 
            columnGap: '40px', 
            rowGap: '20px', 
            listStyleType: 'disc', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
        <li style={{ fontWeight: 'bold' }}>Existing Passport (M)</li>
        <li style={{ fontWeight: 'bold' }}>Aadhaar card</li>
        <li style={{ fontWeight: 'bold' }}>Bank statement</li>
        <li style={{ fontWeight: 'bold' }}>Voter id</li>
    </ul>
</div>
                <div style={{marginLeft:'72%',marginTop:'8%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>How It Works</h4>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Register online</li>
                    <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Upload Documents</li>
                    <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Payment</li>
                    <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Get Appointment</li>
                    <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Visit Psk</li>
                    <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Get Delivered</li>
                    </ul>
                </div>
                <div style={{marginLeft:'72%',marginTop:'13%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>Charges</h4>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Rs.399, Rs.350</li>
        <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>If you decide to cancel your order after payment, please note that a cancellation fee of Rs. 50 will apply.</li>
        {/* <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Note: Additional charges for stamp paper</li> */}
       
                    </ul>
                </div>
                </>
            </div>
            <div>
   
   {/* Get Quotes Button */}
   <div style={{ textAlign: "center", marginTop: "4%" }}>
     <button
       style={{
         backgroundColor: "#FCA505",
         color: "#000000",
         padding: "12px 50px",
         border: "none",
         borderRadius: "30px",
         fontWeight: "bold",
         cursor: "pointer",
         fontSize: "16px",
         marginRight:"40%",
         marginTop:"-30%",
       }}
       onClick={() => setShowPopup(true)}
     >
       CONTINUE
     </button>
   
   
 </div>
   {/* Modal Popup */}
   {showPopup && (
     <div
       style={{
        position: "fixed",
        top: "100px",
        left: "0",
        width: "100%",
        height: "86%",
        backgroundColor: "rgba(26, 118, 216, 0.9)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "9999",
       }}
     >
       <div
         style={{
           backgroundColor: "#FFFFFF",
           padding: "40px",
           borderRadius: "28px",
           width: "70%",
           maxHeight: "90%", // Maximum height of the popup
           overflowY: "auto", // Enable scrolling if content overflows
           textAlign: "center",
           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
         }}
       >
         {/* Stepper */}
       

         {!isCompleted ? (
           <>
   <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
  {Array.from({ length: 3 }).map((_, index) => (
    <React.Fragment key={index}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <button
          onClick={() => setCurrentStep(index + 1)} // Make step clickable
          style={{
            width: "50px",
            height: "50px",
            backgroundColor: currentStep >= index + 1 ? "#4285F4" : "#ccc",
            color: "white",
            borderRadius: "50%",
            lineHeight: "50px",
            fontWeight: "bold",
            border: "none",
            cursor: "pointer",
            outline: "none",
          }}
        >
          {index + 1}
        </button>
        {/* Optional Step Labels */}
        {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step {index + 1}</span> */}
      </div>

      {/* Add Connection Divider Between Steps */}
      {index < 2 && (
        <div
          style={{
            height: "2px",
            flex: 1,
            backgroundColor: currentStep > index + 1 ? "#4285F4" : "#ccc",
            alignSelf: "center",
          }}
        />
      )}
    </React.Fragment>
  ))}
</div>



      
         <div style={{ marginBottom: "20px" }}>
 {currentStep === 1 && (
  <div style={{ textAlign: "center" }}>
   
    {/* <h4 style={{ color: "#00000", fontWeight: "400" }}>
      Agreement
    </h4> */}


    <h5 style={{ color: "#007BFF", fontWeight: "bold" }}>
      Applying for      <span style={{ color: "red" }}>*</span>
    </h5>

    {/* Radio Buttons */}
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "4%",
        width: "fit-content",
        gap: "20px",
        fontWeight:"bold"
      }}
    >
      <label style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="flatHouseLeaseAgreement"
          style={{ marginRight: "10px" }}
        />
        Citizenship/Nationality Application 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopLeaseAgreement"
          style={{ marginRight: "10px" }}
        />
       Education/Research 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopLeaseAgreement"
          style={{ marginRight: "10px" }}
        />
        Employment Visa/Work Permit 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopLeaseAgreement"
          style={{ marginRight: "10px" }}
        />
       Immigration Purposes Other Than Citizenship 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopLeaseAgreement"
          style={{ marginRight: "10px" }}
        />
       Long Term Visa/Stay 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopLeaseAgreement"
          style={{ marginRight: "10px" }}
        />
       Residence Permit 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopLeaseAgreement"
          style={{ marginRight: "10px" }}
        />
     Tourist Visa 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopLeaseAgreement"
          style={{ marginRight: "10px" }}
        />
      Others 
      </label>
    </div>
  </div>
)}



{currentStep === 2 && (
  <div style={{ textAlign: "center" }}>
    {/* Step 3 Heading */}
    <p style={{ color: "#1A76D8", fontWeight: "600" }}>
      Application Details<span style={{ color: "red" }}>*</span>
    </p>

    {/* Given Name */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="givenname"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Given Name
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        id="givernname"
        placeholder=""
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    {/* Gender */}
    <div style={{ marginBottom: "33px", textAlign: "left", gap: "10px" }}>
      <label style={{ fontSize: "16px", fontWeight: "500" }}>
        Gender
        <span style={{ color: "red" }}>*</span>
      </label>
      <div>
        <label>
          <input type="radio" name="gender" value="female" style={{ marginRight: "10px" }} />
          Female
        </label>
        <label>
          <input type="radio" name="gender" value="male" style={{ marginRight: "10px" }} />
          Male
        </label>
        <label>
          <input type="radio" name="gender" value="transgender" style={{ marginRight: "10px" }} />
          Transgender
        </label>
      </div>
    </div>

    {/* Date of Birth */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="dateOfBirth"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Date of Birth
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="date"
        id="dateOfBirth"
        placeholder="Enter Date of Birth"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    {/* Place of Birth */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="place"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Place of Birth (Village/ Town / City) <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        id="place"
        placeholder=""
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    {/* Employment Type */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="employmentType"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Employment Type <span style={{ color: "red" }}>*</span>
      </label>
      <select
        id="employmentType"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      >
        <option value="">Choose</option>
        <option value="fulltime">Government</option>
        <option value="parttime">Private</option>
        <option value="selfemployed">Student</option>
        <option value="unemployed">Homemaker</option>
        <option value="unemployed">Retired</option>
      </select>
    </div>

    {/* Education Qualification */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="educationQualification"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Education Qualification <span style={{ color: "red" }}>*</span>
      </label>
      <select
        id="educationQualification"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      >
        <option value="">Choose</option>
        <option value="highschool">Grduate and Above</option>
        <option value="graduate">10th Pass and Above</option>
        <option value="postgraduate">Below 10th</option>
      
      </select>
    </div>
  </div>
)}




{currentStep === 3 && (
 <div style={{ textAlign: "center" }}>
 {/* Step 3 Heading */}
 <p style={{ color: "#1A76D8", fontWeight: "600" }}>
 Present Residential Address<span style={{ color: "red" }}>*</span>
 </p>

 <div style={{ marginBottom: "20px", textAlign: "left" }}>
   <label
    //  htmlFor="ownerName"
     style={{
       display: "block",
       marginBottom: "10px",
       fontSize: "16px",
       fontWeight: "500",
     }}
   >
    House No. and Street Name
     <span style={{ color: "red" }}>*</span>
   </label>
   <input
     type="text"
     id=""
     placeholder=""
     style={{
       width: "100%",
       height: "45px",
       padding: "10px",
       fontSize: "16px",
       border: "2px solid #FCA505",
       borderRadius: "4px",
     }}
   />
 </div>

 <div style={{ marginBottom: "20px", textAlign: "left" }}>
<label
htmlFor="state"
style={{
  display: "block",
  marginBottom: "10px",
  fontSize: "16px",
  fontWeight: "500",
}}
>
State
<span style={{ color: "red" }}>*</span>
</label>
<select
id="state"
style={{
  width: "100%",
  height: "45px",
  padding: "10px",
  fontSize: "16px",
  border: "2px solid #FCA505",
  borderRadius: "4px",
}}
>
<option value="">--Select State--</option>
{/* Add all India states here as options */}
<option value="AndhraPradesh">Andhra Pradesh</option>
<option value="ArunachalPradesh">Arunachal Pradesh</option>
<option value="Assam">Assam</option>
<option value="Bihar">Bihar</option>
<option value="Chhattisgarh">Chhattisgarh</option>
{/* Continue adding other states */}
</select>
</div>
{/* District Dropdown */}
<div style={{ marginBottom: "20px", textAlign: "left" }}>
<label
htmlFor="district"
style={{
  display: "block",
  marginBottom: "10px",
  fontSize: "16px",
  fontWeight: "500",
}}
>
District
<span style={{ color: "red" }}>*</span>
</label>
<select
id="district"
style={{
  width: "100%",
  height: "45px",
  padding: "10px",
  fontSize: "16px",
  border: "2px solid #FCA505",
  borderRadius: "4px",
}}
>
<option value="">--Select District--</option>
{/* Add district options dynamically based on state selection */}
</select>
</div>
<div style={{ marginBottom: "20px", textAlign: "left" }}>
<label
htmlFor="pincode"
style={{
  display: "block",
  marginBottom: "10px",
  fontSize: "16px",
  fontWeight: "500",
}}
>
Pin Code 
<span style={{ color: "red" }}>*</span>
</label>
<input
type="text"
id="pincode"
placeholder=""
style={{
  width: "100%",
  height: "45px",
  padding: "10px",
  fontSize: "16px",
  border: "2px solid #FCA505",
  borderRadius: "4px",
}}
/>
</div>
 <div style={{ marginBottom: "20px", textAlign: "left" }}>
   <label
     htmlFor=""
     style={{
       display: "block",
       marginBottom: "10px",
       fontSize: "16px",
       fontWeight: "500",
     }}
   >
    Email ID
   </label>
   <input
     type="text"
     id=""
     placeholder=""
     style={{
       width: "100%",
       height: "45px",
       padding: "10px",
       fontSize: "16px",
       border: "2px solid #FCA505",
       borderRadius: "4px",
     }}
   />
 </div>

 <div style={{ marginBottom: "20px", textAlign: "left" }}>
   <label
    //  htmlFor="ownerName"
     style={{
       display: "block",
       marginBottom: "10px",
       fontSize: "16px",
       fontWeight: "500",
     }}
   >
   Nearest Police Station 
     <span style={{ color: "red" }}>*</span>
   </label>
   <input
     type="text"
     id=""
     placeholder=""
     style={{
       width: "100%",
       height: "45px",
       padding: "10px",
       fontSize: "16px",
       border: "2px solid #FCA505",
       borderRadius: "4px",
     }}
   />
 </div>
 <div style={{ marginBottom: "20px", textAlign: "left" }}>
   <label
    //  htmlFor="ownerName"
     style={{
       display: "block",
       marginBottom: "10px",
       fontSize: "16px",
       fontWeight: "500",
     }}
   >
    Mobile Number
     <span style={{ color: "red" }}>*</span>
   </label>
   <input
     type="text"
     id=""
     placeholder=""
     style={{
       width: "100%",
       height: "45px",
       padding: "10px",
       fontSize: "16px",
       border: "2px solid #FCA505",
       borderRadius: "10px",
     }}
   />
 </div>

 <p style={{ marginTop: "20px", fontSize: "14px", textAlign: "left" }}>
    By clicking submit, you agree to our{" "}
    <a href="/terms" style={{ color: "#007BFF", textDecoration: "underline" }}>
      Terms & Conditions
    </a>{" "}
    and{" "}
    <a
      href="/privacy-policy"
      style={{ color: "#007BFF", textDecoration: "underline" }}
    >
      Privacy Policy
    </a>.
  </p>
</div>
)}



         </div>
         <div>
          
           {currentStep < 3 ? (
             <button
               onClick={nextStep}
               style={{
                 padding: "10px 20px",
                 backgroundColor: "#FCA505",
                 color: "#000000",
                 border: "none",
                 borderRadius: "5px",
                 cursor: "pointer",
               }}
             >
               Next
             </button>
           ) : (
             <button
             onClick={finishSubmission}
               style={{
                 padding: "10px 20px",
                 backgroundColor: "FCA505",
                 color: "#000000",
                 border: "none",
                 borderRadius: "5px",
                 cursor: "pointer",
               }}
             >
               SUBMIT
             </button>
           )} 
         </div>
         </>
         ) : (
            <div style={{ textAlign: "center", padding: "20px" }}>
            <h4 style={{ color: "#007BFF", fontWeight: "bold", display: "inline-block", marginRight: "10px" }}>
        OTP sent on 97******8
      </h4>
      <a
        href="#"
        onClick={() => setCurrent(1)} // Redirect to step 1
        style={{ fontSize: "14px", color: "#007BFF", textDecoration: "none" }}
      >
        Edit
      </a>
      
            <div style={{ margin: "20px 0" }}>
              <label style={{ display: "block", fontWeight: "500", marginBottom: "10px" }}>
                Enter OTP <span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleChange(e.target.value, index)}
                    onKeyDown={(e) => handleBackspace(e, index)}
                    style={{
                      width: "50px",
                      height: "50px",
                      textAlign: "center",
                      fontSize: "18px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  />
                ))}
              </div>
            </div>
      
            <p style={{ fontSize: "14px", color: "#888" }}>
              {resendCountdown > 0 ? (
                <>Not Received? Resend in {resendCountdown}s</>
              ) : (
                <a
                  href="#"
                  onClick={handleResend}
                  style={{
                    textDecoration: "none",
                    color: isResending ? "#ccc" : "#007BFF",
                    pointerEvents: isResending ? "none" : "auto",
                  }}
                >
                  Resend OTP
                </a>
              )}
            </p>
      
            <button
              onClick={handleVerify}
              style={{
                backgroundColor: "#FFA500",
                color: "#00000",
                border: "none",
                borderRadius: "5px",
                padding: "10px 20px",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              VERIFY
            </button>
          </div>
         )}

         {/* Close Button */}
         <button
           onClick={closePopup}
           style={{
             position: "absolute",
             top: "10px",
             right: "10px",
             background: "none",
             border: "none",
             fontSize: "20px",
             cursor: "pointer",
           }}
         >
           &times;
         </button>
       </div>
     </div>
   )}
 </div>



        
        
        </>
    );
};

export default PoliceClearance;
