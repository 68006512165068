import React, { useState } from 'react';
    import "./css/home.css"; 
    import { Link } from 'react-router-dom';
    import { ReactComponent as HomeImage } from "./images/homeimage.svg"; 
    import { ReactComponent as BookImage } from "./images/book.svg"; 
    import { ReactComponent as NewImage1 } from "./images/Insurance.svg"; 
    import { ReactComponent as NewImage2 } from "./images/visa.svg";  
    import { ReactComponent as NewImage3 } from "./images/rentalagreement.svg";  
    import { ReactComponent as NewImage4 } from "./images/leaseagreement.svg";  
    import { ReactComponent as NewImage5 } from "./images/affidavits.svg";  
    import { ReactComponent as NewImage6 } from "./images/pancard.svg";  
    import { ReactComponent as AdditionalImage1 } from "./images/passport.svg";  
    import { ReactComponent as AdditionalImage2 } from "./images/seniorcitizen.svg";  
    import { ReactComponent as AdditionalImage3 } from "./images/policeverification.svg"; 
    import { ReactComponent as AdditionalImage4 } from "./images/foodlicence.svg";  
    import { ReactComponent as AdditionalImage5 } from "./images/msmecertification.svg";  
    import { ReactComponent as AdditionalImage6 } from "./images/policeclearence.svg"; 
    import { ReactComponent as CircleImage } from "./images/circle.svg";
    import { ReactComponent as Wave } from "./images/wave.svg";
    import oneImage from './images/1.svg';
    import twoImage from './images/2.svg';
    import threeImage from './images/3.svg';
    import RectangleSlidingImage from "./images/rectangle sliding.svg";

    import girlImage from './images/girl image.jpg';
    import man1Image from './images/man1.jpg';
import man2Image from './images/man2.jpg';
import ColorCircle1 from '../src/images/cirle-image.svg';
import Step1Image from '../src/images/step 1 1.svg'
import Step2Image from '../src/images/step 2.svg'
import Step3Image from '../src/images/step3.svg'
import { ReactComponent as PaytmImage } from "./images/Paytm_logo 1.svg";
import {ReactComponent as CscImage} from "./images/csc.svg"
import { ReactComponent as TurtleImage } from "./images/TurtlemintProLogo.svg";
import { ReactComponent as ImageIcon } from "./images/image.svg";
import { ReactComponent as GromoImage } from "./images/gromo.svg";
import {ReactComponent as DigitalIndiaImage} from "./images/Digital_india_logo.svg"

import Slider from "react-slick"; 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 
import LadyImage from "./images/ladyimage.svg";
import AirplaneImage from "./images/airoplane.svg";


    const Home = () => {
      

    return (
        <>
        <section className="home-section">
            <div className="container home-container">
            {/* Text Content */}
            <div className="home-text">
                <h1>We are India's</h1>
                <h1>#1* Online Documents Consultancy.</h1>
                <p>
                Efficiently Organized, Expertly Delivered <br />
                Accurate Drafting, Fast Turnaround, Hassle-Free Execution
                </p>
                <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search and select which documents you want to get ready!"
                />
                </div>
            </div>
            <div className="home-images" style={{marginTop:"17%"}}>
                <HomeImage className="home-main-image" />
            </div>    
            <BookImage className="home-book-image" />
            </div>
        </section>

        <section className="boxes-section">
            <div className="boxes-container" >
            <div className="box-container" >
            <Link to="/insurance" className="box-link" style={{ textDecoration: "none" }}>
        <div className="box">
        <NewImage1 className="box-image scale-tilt" />
        </div>
        <p className="box-name" style={{fontSize:"16px" ,wordWrap:"nowrap"}}>Insurance</p>
      </Link>
        </div>
        <div className="box-container">
        <Link to="/visa" className="box-link" style={{ textDecoration: "none" }}>
        <div className="box">
            <NewImage2 className="box-image scale-tilt" />
        </div>
        <p className="box-name" style={{fontSize:"16px" ,wordWrap:"nowrap"}}>Travel Visa</p>
        
</Link>
        </div>
        <div className="box-container">
          <Link to="/rental-agreement" className="box-link" style={{ textDecoration: "none" }}>
          <div className="box">
            <NewImage3 className="box-image scale-tilt" />
        </div>
        <p className="box-name" style={{fontSize:"16px" ,wordWrap:"nowrap"}}>Rental<br/> Agreement</p>
          </Link>
        
        </div>
        <div className="box-container">
        <Link to="/lease-agreement" className="box-link" style={{ textDecoration: "none" }}>
        <div className="box">
            <NewImage4 className="box-image scale-tilt" />
        </div>
        <p className="box-name" style={{fontSize:"16px" ,wordWrap:"nowrap"}}>Lease <br/>Agreement</p>
        </Link>
        </div>
        <div className="box-container">
        <Link to="/affidavits" className="box-link" style={{ textDecoration: "none" }}>
        <div className="box">
            <NewImage5 className="box-image scale-tilt" />
        </div>
        <p className="box-name" style={{fontSize:"16px" ,wordWrap:"nowrap"}}>Affidavits/<br/>Annexture</p>
        </Link>
        </div>
        <div className="box-container">
          <Link to ="/pan-card" className="box-link" style={{ textDecoration: "none" }}>
        <div className="box">
            <NewImage6 className="box-image scale-tilt" />
        </div>
        <p className="box-name" style={{fontSize:"16px" ,wordWrap:"nowrap"}}>Pan Card</p>
        </Link>
        </div>
            </div>
        </section>
        <section className="boxes-section second-boxes-section">
  <div className="boxes-container">
    <div className="box-container">
      <Link to="passport" className="box-link" style={{ textDecoration: "none" }}>
      <div className="box">
        <AdditionalImage1 className="box-image scale-tilt" />
      </div>
      <p className="box-name" style={{fontSize:"16px" ,wordWrap:"nowrap"}}>Pass Port</p>
</Link>
    </div>
    <div className="box-container">
      <Link to="senior-citizen" className="box-link" style={{ textDecoration: "none" }}>
      <div className="box">
        <AdditionalImage2 className="box-image scale-tilt" />
      </div>
      <p className="box-name" style={{fontSize:"16px" ,wordWrap:"nowrap"}}>Senior Citizen <br/>Card</p>
      </Link>
    </div>
    <div className="box-container">
    <Link to="gst" className="box-link" style={{ textDecoration: "none" }}>
      <div className="box">
        <AdditionalImage3 className="box-image scale-tilt" />
      </div>
      <p className="box-name" style={{fontSize:"16px" ,}}>Police<br/> Verification</p>
      </Link>
    </div>
    <div className="box-container">
  <Link to="food-license" className="box-link" style={{ textDecoration: "none" }}>
    <div className="box">
      <AdditionalImage4 className="box-image scale-tilt" />
    </div>
    <p className="box-name" style={{ fontSize: "16px", textAlign: "center" }}>
      Food License <br />(FSSAI)
    </p> 
  </Link>
</div>


    <div className="box-container">
    <Link to="senior-citizen" className="box-link" style={{ textDecoration: "none" }}>
      <div className="box">
        <AdditionalImage5 className="box-image scale-tilt" />
      </div>
      <p className="box-name" style={{fontSize:"16px" ,wordWrap:"nowrap"}}>MSME <br/> Certification</p>
      </Link>
    </div>
    <div className="box-container">
    <Link to="police-clearance" className="box-link" style={{ textDecoration: "none" }}>
      <div className="box">
        <AdditionalImage6 className="box-image scale-tilt" />
      </div>
      <p className="box-name" style={{fontSize:"16px" ,wordWrap:"nowrap"}}>
  Police Clearance <br />
  Certificate
</p>
</Link>
    </div>
  </div>
</section>


<section className="circle-section">
<div className="container">
  <div className="row">
    <div className="image-text-wrapper">
      <CircleImage className="circle-image" />
      <span className="how-it-works-text">HOW IT WORKS</span>
      <span className="circle-text">
        Make My Documents Is <br />
        <strong style={{ color: "#007bff" }}>
          The Smartest Way To Get Your Documents
        </strong>
        <span style={{ color: "#333", fontWeight: "normal" }}> Done.</span>
      </span>
    </div>
  </div>
</div>



    
  <section className="wave-section">
        <div className="wave-wrapper">
          {/* Wave SVG */}
          <Wave className="wave-svg" />
          <div className="color-circle start-circle position-relative">
  <img 
    src={ColorCircle1} 
    alt="Color Circle 1" 
    className="circle-img" 
    style={{ height: "30%", marginTop: "26px" , marginLeft:"-10%"}} 
  />
  <img 
    src={Step1Image} 
    alt="Step 1" 
    className="step-img position-absolute" 
    style={{
      height: "115%", 
      top: "12%", 
      left: "-3%", 
      transform: "translate(-48%, -50%)",
      zIndex: 1,
    }} 
  />
</div>

          <div className="color-circle middle-circle position-relative">
         <img src={ColorCircle1} alt="Color Circle 2" className="" style={{height:"30%",}}/>
         <img 
    src={Step2Image} 
    alt="Step 1" 
    className="step-img position-absolute" 
    style={{
      height: "174%", 
      top: "12%", 
      left: "8%", 
      transform: "translate(-48%, -50%)",
      zIndex: 1,
    }} 
    />
          </div>
          <div className="color-circle end-circle position-relative">
          <img src={ColorCircle1} alt="Color Circle 3" className="" style={{height:"30%",}} />
          <img 
    src={Step3Image} 
    alt="Step 1" 
    className="step-img position-absolute" 
    style={{
      height: "171%", 
      top: "12%", 
      left: "15%", 
      transform: "translate(-48%, -50%)",
      zIndex: 1,
    }} 
    />
          </div>  
        </div>
       
      </section>
      <div className="why-make-my-documents text-center my-10">
        <div className="row justify-content-center">
          <div className="col-12 col-md-4 " style={{marginTop:"1%" , marginLeft:"8%"}}>
            <img
              src={oneImage}
              alt="Complete Online Process"
              className="mb-3 feature-img img-fluid" 
            />
            <h5 style={{ fontWeight: 'bold' }}>COMPLETE ONLINE PROCESS</h5>
            <p>Upload the documents as mentioned and leave the rest to us.</p>
          </div>
          <div className="col-12 col-md-4 text-center" style={{marginTop:"-10%" , marginLeft:"1px"}}>
            <img
              src={twoImage}
              alt="Dedicated Team"
              className="mb-3 feature-img img-fluid"
            />
            <h5 style={{ fontWeight: 'bold' }}>DEDICATED TEAM</h5>
            <p>Prompt notifications on every stage of visa.</p>
          </div>
          <div className="col-12 col-md-4 text-center" style={{marginTop:"-12%", marginRight:"-10%"}}>
            <img
              src={threeImage}
              alt="99% Approval Rate"
              className="mb-3 feature-img img-fluid" 
            />
            <h5 style={{ fontWeight: 'bold' }}>99% APPROVAL RATE</h5>
            <p>Based on visas  <br/> processed so far.</p>
          </div>
        </div>
      </div>
</section>



<section className="rectangle-section">
  <div className="rectangle-container">
    <div className="rectangle-background">
      <div className="rectangle-content">
        <div className="rectangle-text">
          <h1>Ready for your next destination?</h1>
          <p>Leave the visa paperwork to us!</p>
        </div>
        <img src={AirplaneImage} alt="Airplane" className="airplane-image" />
        <img src={LadyImage} alt="Lady" className="lady-image" />
      </div>
    </div>
  </div>
</section>

<section className="sliding-section" style={{ zIndex: "1" }}>
  
  <div
    id="slidingCarousel"
    className="carousel slide"
    data-bs-ride="carousel"
    data-bs-interval="3000"
    data-bs-pause="false"
  >
    <div className="carousel-inner">
      {/* Slide 1 */}
      <div className="carousel-item active">
        <div className="slide-container">
          <img
            src={RectangleSlidingImage}
            className="d-block slide-image"
            alt="Slide 1" style={{zIndex:"1"}}
          />
          <div className="carousel-caption">
            <h2 className="slide-title" style={{whiteSpace:"nowrap", marginLeft:"-22px"}}>
              Get Insured Without The Paperwork Headaches!
            </h2>
            <p className="slide-description">
              We make it simple and quick to secure insurance, ensuring you
              receive your policy in just a matter of days without the stress of
              handling paperwork.
            </p>
            <img
              src={require("./images/paperwork.svg").default}
              alt="Paperwork Icon"
              className="paperwork-icon"
            />
          </div>
        </div>
      </div>

      {/* Slide 2 */}
      <div className="carousel-item">
        <div className="slide-container">
          <img
            src={RectangleSlidingImage}
            className="d-block slide-image"
            alt="Slide 2"
          />
          <div className="carousel-caption">
            <h2 className="slide-title" style={{whiteSpace:"nowrap", marginTop:"-22px"}}>Need a rental agreement fast?</h2>
            <p className="slide-description">
            With our hassle-free service, you can get your rental agreement without stepping out. 
            Your legal document will be delivered to you in just a few days
            </p>
            <img
              src={require("./images/rental-image-slide.svg").default}
              alt="Paperwork Icon"
              className="paperwork-icon"
            />
          </div>
        </div>
      </div>

      {/* Slide 3 */}
      <div className="carousel-item">
        <div className="slide-container">
          <img
            src={RectangleSlidingImage}
            className="d-block slide-image"
            alt="Slide 3"
          />
          <div className="carousel-caption">
            <h2 className="slide-title">Starting a food business? You’ll need a food license!</h2>
            <p className="slide-description">
            Our service takes the hassle out of the process, ensuring you receive your food 
license promptly so you can focus on building your business.

            </p>
            <img
              src={require("./images/food-image-slide.svg").default}
              alt="Paperwork Icon"
              className="paperwork-icon"
            />
          </div>
        </div>
      </div>
      <div className="carousel-item">
        <div className="slide-container">
          <img
            src={RectangleSlidingImage}
            className="d-block slide-image"
            alt="Slide 3"
          />
          <div className="carousel-caption">
            <h2 className="slide-title">Secure Your Senior Citizen Card, Stress-Free!</h2>
            <p className="slide-description">
            We handle the process while you relax.
            </p>
            <img
              src={require("./images/senior-image-slide.svg").default}
              alt="Paperwork Icon"
              className="paperwork-icon"
            />
          </div>
        </div>
      </div>
      <div className="carousel-item">
        <div className="slide-container">
          <img
            src={RectangleSlidingImage}
            className="d-block slide-image"
            alt="Slide 3"
          />
          <div className="carousel-caption">
            <h2 className="slide-title">Your Passport, Simplified.</h2>
            <p className="slide-description">
            Apply now and leave the paperwork to us.
            </p>
            <img
            src={require("./images/passport-image-slide.svg").default}
              alt="Paperwork Icon"
              className="paperwork-icon"
            />
          </div>
        </div>
      </div>
      <div className="carousel-item">
        <div className="slide-container">
          <img
            src={RectangleSlidingImage}
            className="d-block slide-image"
            alt="Slide 3"
          />
          <div className="carousel-caption">
            <h2 className="slide-title">Your Pancard, Simplified.</h2>
            <p className="slide-description">
            Apply now and leave the paperwork to us.
            </p>
            <img
              src={require("./images/pancard-image-slide.svg").default}
              alt="Paperwork Icon"
              className="paperwork-icon"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="review-section" style={{  }}>
  <p style={{fontWeight:"bold", fontSize:"30px",}}>Customer Reviews</p>
  <div
    id="reviewCarousel"
    className="carousel slide"
    data-bs-ride="carousel"
    data-bs-interval="3000"
  >
    <div className="carousel-inner">
      {/* Slide 1 */}
      <div className="carousel-item active">
        <div className="row" style={{ justifyContent: "center" , marginTop:'3%'}}>
          <div className="col-md-3">
            <div className="review-box">
              <img src={girlImage} alt="Customer 1" />
              <div className="stars">★★★★★</div>
              <p>
                Fakespot uses Artificial Intelligence to analyze thousands of
                reviews and consumer ratings before assigning your own authentic
                rating on e-commerce products. The platform was created to help
                consumers of online products recognize genuine products in a
                context.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="review-box">
              <img src={man1Image} alt="Customer 2" />
              <div className="stars">★★★★☆</div>
              <p>
                Fakespot uses Artificial Intelligence to analyze thousands of
                reviews and consumer ratings before assigning your own authentic
                rating on e-commerce products. The platform was created to help
                consumers of online products recognize genuine products in a
                context.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="review-box">
              <img src={man2Image} alt="Customer 3" />
              <div className="stars">★★★★★</div>
              <p>
                Fakespot uses Artificial Intelligence to analyze thousands of
                reviews and consumer ratings before assigning your own authentic
                rating on e-commerce products. The platform was created to help
                consumers of online products recognize genuine products in a
                context.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="review-box">
              <img src={man1Image} alt="Customer 4" />
              <div className="stars">★★★★☆</div>
              <p>
                Fakespot uses Artificial Intelligence to analyze thousands of
                reviews and consumer ratings before assigning your own authentic
                rating on e-commerce products. The platform was created to help
                consumers of online products recognize genuine products in a
                context.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Slide 2 */}
      <div className="carousel-item">
        <div className="row" style={{ justifyContent: "center" , marginTop:'3%'}}>
          <div className="col-md-3">
            <div className="review-box">
              <img src={man2Image} alt="Customer 5" />
              <div className="stars">★★★★★</div>
              <p>
                Fakespot uses Artificial Intelligence to analyze thousands of
                reviews and consumer ratings before assigning your own authentic
                rating on e-commerce products. The platform was created to help
                consumers of online products recognize genuine products in a
                context.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="review-box">
              <img src={girlImage} alt="Customer 6" />
              <div className="stars">★★★★★</div>
              <p>
                Fakespot uses Artificial Intelligence to analyze thousands of
                reviews and consumer ratings before assigning your own authentic
                rating on e-commerce products. The platform was created to help
                consumers of online products recognize genuine products in a
                context.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="review-box">
              <img src={man1Image} alt="Customer 7" />
              <div className="stars">★★★★☆</div>
              <p>
                Fakespot uses Artificial Intelligence to analyze thousands of
                reviews and consumer ratings before assigning your own authentic
                rating on e-commerce products. The platform was created to help
                consumers of online products recognize genuine products in a
                context.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="review-box">
              <img src={man2Image} alt="Customer 8" />
              <div className="stars">★★★★★</div>
              <p>
                Fakespot uses Artificial Intelligence to analyze thousands of
                reviews and consumer ratings before assigning your own authentic
                rating on e-commerce products. The platform was created to help
                consumers of online products recognize genuine products in a
                context.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Carousel Controls */}
    <button
      className="carousel-control-prev"
      type="button"
      data-bs-target="#reviewCarousel"
      data-bs-slide="prev"
    >
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button
      className="carousel-control-next"
      type="button"
      data-bs-target="#reviewCarousel"
      data-bs-slide="next"
    >
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Next</span>
    </button>
  </div>
</section>


<section className="additional-section" style={{marginTop:"30px"}}>
  <div className="additional-container">
    {/* New Header */}
    <h2 className="additional-header">ASSOCIATED WITH</h2>
    {/* SVG Images Row */}
    <div className="svg-images-row">
      <div className="svg-image-container">
        <PaytmImage className="svg-image" />
      </div>
      <div className="svg-image-container">
        <CscImage className="svg-image" />
      </div>
      <div className="svg-image-container">
        <GromoImage className="svg-image" />
      </div>
      <div className="svg-image-container">
        <DigitalIndiaImage className="svg-image" />
      </div>
      <div className="svg-image-container">
        <ImageIcon className="svg-image" />
      </div>
      <div className="svg-image-container">
        <TurtleImage className="svg-image" />
      </div>
      
    </div>
  </div>
</section>
<br></br>

        </>
    );
    };

    export default Home;
