import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';  
import logo from "./images/logo.svg";
import { ReactComponent as InsuranceIcon } from "./images/service/insurance.svg";
import { ReactComponent as VisaIcon } from "./images/service/visa.svg";
import { ReactComponent as RentalIcon } from "./images/service/rental.svg";
import { ReactComponent as LeaseIcon } from "./images/service/lease.svg";
import { ReactComponent as PassportIcon } from "./images/service/Passport.svg";
import { ReactComponent as SeniorCardIcon } from "./images/service/senior.svg";
import { ReactComponent as GSTIcon } from "./images/service/gst.svg";
import { ReactComponent as FoodLicenseIcon } from "./images/service/food.svg";
import { ReactComponent as MSMEIcon } from "./images/service/msme.svg";
import { ReactComponent as PoliceIcon } from "./images/service/police.svg";
import { ReactComponent as AffidavitsIcon } from "./images/service/affidavits.svg";
import { ReactComponent as Pancard } from "./images/service/leasecertification.svg";

const Header = () => {
  return (
    <header className="bg-white">
      {/* Navbar with Images before content */}
      <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top ">
       
      <img 
  src={logo} 
  alt="Logo" 
  className="logo-icon" 
  style={{
    marginRight: "20px" // Add margin to the right
  }} 
/>

    
   

        <div className="collapse navbar-collapse me-12" id="navbarSupportedContent" style={{
    marginLeft: "0",           
    transform: "translateX(55%)",
    
  }} >
          <ul className="navbar-nav  ">
          <li className="nav-item active">
  <Link 
    className="nav-link" 
    to="/" 
    style={{
      color: "#1A76D8",       
      textDecoration: "none", 
      marginRight: "20px"
    }}
  >
    Home
  </Link>
</li>

           
<li className="nav-item dropdown">
  <Link
    className="nav-link dropdown-toggle"
    to="#"
    id="navbarDropdown"
    role="button"
    data-bs-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    style={{
      backgroundColor: "#1A76D8",
      color: "white",
      padding: "8px 15px",
      borderRadius: "5px",
      textDecoration: "none",
      marginRight: "20px",
    }}
  >
    Services
  </Link>
  <div
    className="dropdown-menu"
    aria-labelledby="navbarDropdown"
    style={{
      width: "525px",
      borderRadius: "25px",
      left: "0",
      padding: "10px",
      transform: "translateX(-50%)", // Adjusted for better centering
    }}
  >
    <div className="row">
      {/* First Column */}
      <div className="col-6">
        <Link className="dropdown-item d-flex align-items-center" to="/insurance">
          <InsuranceIcon className="me-2" />
          Insurance
        </Link>
        <Link className="dropdown-item d-flex align-items-center" to="/visa">
          <VisaIcon className="me-2" />
          Travel Visa
        </Link>
        <Link className="dropdown-item d-flex align-items-center" to="/rental-agreement">
          <RentalIcon className="me-2" />
          Rental Agreement
        </Link>
        <Link className="dropdown-item d-flex align-items-center" to="/lease-agreement">
          <LeaseIcon className="me-2" />
          Lease Agreement
        </Link>
        <Link className="dropdown-item d-flex align-items-center" to="/passport">
          <PassportIcon className="me-2" />
          Passport
        </Link>
        <Link className="dropdown-item d-flex align-items-center" to="/pan-card">
          <Pancard className="me-2" />
          Pancard
        </Link>
      </div>
      {/* Second Column */}
      <div className="col-6">
        <Link className="dropdown-item d-flex align-items-center" to="/senior-citizen">
          <SeniorCardIcon className="me-2" />
          Senior Citizen Card
        </Link>
        <Link className="dropdown-item d-flex align-items-center" to="/gst">
          <GSTIcon className="me-2" />
          Police Verification
        </Link>
        <Link className="dropdown-item d-flex align-items-center" to="/food-license">
          <FoodLicenseIcon className="me-2" />
          Food License (FSSAI)
        </Link>
        <Link className="dropdown-item d-flex align-items-center" to="/msme">
          <MSMEIcon className="me-2" />
          MSME Certificate
        </Link>
        <Link className="dropdown-item d-flex align-items-center" to="/police-clearance">
  <PoliceIcon className="me-2" />
  Police Clearance
  <br />
  Certificate
</Link>

        <Link className="dropdown-item d-flex align-items-center" to="/affidavits">
          <AffidavitsIcon className="me-2" />
          Affidavits/Annexure
        </Link>
      </div>
    </div>
  </div>
</li>

            <li className="nav-item">
              <Link className="nav-link" to="/about"  style={{
      color: "#1A76D8",       // Set text color to blue
      textDecoration: "none",
      marginRight: "20px" // Remove underline (optional)
    }}>
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/career"  style={{
      color: "#1A76D8",       // Set text color to blue
      textDecoration: "none",
      marginRight: "20px" // Remove underline (optional)
    }}>
                Careers
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact"  style={{
                  backgroundColor: "#1A76D8", // Set background color
                  color: "white",          // Set text color
                  padding: "8px 15px",     // Add padding for button-like appearance
                  borderRadius: "5px",     // Add border radius for rounded edges
                  textDecoration: "none" ,
                  marginRight: "20px"  // Remove underline
                }}>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
