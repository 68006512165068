import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "./images/logo.svg";
import "./css//footer.css";

const Footer = () => {
  return (
    <>
    <footer className="footer mt-auto py-2" style={{ backgroundColor: "#eaf3ff" }}>
      <div className="container">
        <div className="row text-center text-md-start align-items-center">
          {/* Logo Section */}
          <div className="col-md-3 mb-3">
         
              <img
                src={logo}
                alt="Logo"
                className="me-2"
                style={{ width: "210px", height: "auto" }} // Optional styling
              />
              
           
          </div>
          {/* Company Links */}
          <div className="col-md-3 mb-3">
            <h6 className="mb-3">Company</h6>
            <ul className="list-unstyled">
              <li><a href="#home" className="text-decoration-none text-dark">Home</a></li>
              <li><a href="#about" className="text-decoration-none text-dark">About Us</a></li>
              <li><a href="#careers" className="text-decoration-none text-dark">Careers</a></li>
              <li><a href="#blogs" className="text-decoration-none text-dark">Blogs</a></li>
            </ul>
          </div>

          {/* Legal Links */}
          <div className="col-md-3 mb-3">
            <h6 className="mb-3">Legal</h6>
            <ul className="list-unstyled">
              <li><a href="#terms" className="text-decoration-none text-dark">Terms & Conditions</a></li>
              <li><a href="#privacy" className="text-decoration-none text-dark">Privacy Policy</a></li>
              <li><a href="#disclaimer" className="text-decoration-none text-dark">Disclaimer</a></li>
            </ul>
          </div>

          {/* Follow Us */}
          <div className="col-md-3 text-center text-md-start mt-3">
  <h6 className="mb-3">Follow Us</h6>
  <a
    href="#facebook"
    className="me-3 text-decoration-none"
    style={{ color: "#1877F2" }} // Facebook Blue
  >
    <i className="bi bi-facebook fs-4"></i>
  </a>
  <a
    href="#instagram"
    className="text-decoration-none"
    style={{
      color: "#E4405F", // Instagram Gradient (Primary Red-Pink Color)
    }}
  >
    <i className="bi bi-instagram fs-4"></i>
  </a>
</div>


        </div>
      </div>
    </footer>
    <div style={{ backgroundColor: "#1A76D8", height: "66px", width: "100%" }}></div>
    </>
  );
};

export default Footer;
