import React from 'react';
import './InsurancePage.css'; 

import mmd1Image from '../images/MMD 1.svg';
import mmd2Image from '../images/MMD 2.svg';
import mmd3Image from '../images/MMD 3.svg';
import mmd4Image from '../images/MMD 4.svg';
import checklistIcon from '../images/notebook.svg'; 
import vehiclesIcon from '../images/image 29.svg'; 
import oneImage from '../images/1.svg';
import twoImage from '../images/2.svg';
import threeImage from '../images/3.svg';

import bikeImage from '../images/bike.svg';
import carImage from '../images/car.svg';
import tempoImage from '../images/tempo.svg';
import healthImage from '../images/health.svg';
import lifeImage from '../images/life.svg';

const InsurancePage = () => {
    // Array for insurance types
    const insuranceTypes = [
        { name: "Two - Wheeler", image: bikeImage, route: "/two-wheeler" },
        { name: "Four - Wheeler", image: carImage, route: "/four-wheeler" },
        { name: "Commercial Vehicle", image: tempoImage, route: "/commercial-vehicle" },
        { name: "Health", image: healthImage, route: "/health" },
        { name: "Life", image: lifeImage, route: "/life" },
    ];

    return (
        <div
            className="container-fluid"
            style={{
                background: 'linear-gradient(182.42deg, #FCA505 2.01%, #FFFFFF)',
                minHeight: '65vh',
                position: 'relative'
            }}
        >
            <div className="row justify-content-start align-items-center" style={{ minHeight: '125%' }}>
                {/* Left side: Text content */}
                <div className="col-12 col-md-6 text-left">
                    <div className="text-content">
                        <h1>Protect Your Future with Ease</h1>
                        <p>Insurance applications made simple and stress-free.</p>
                    </div>

                    {/* Checklist icon at the bottom of the left side */}
                    <img src={checklistIcon} alt="Checklist Icon" className="img-fluid checklist-icon" />
                </div>

                {/* Right side: Vehicles and Phone Icon */}
                <div className="col-12 col-md-6 text-center">
                    <img src={vehiclesIcon} alt="Vehicles and Phone" className="img-fluid" />
                </div>
            </div>
         
            {/* Insurance type selection */}
            <div className="insurance-cards-container row justify-content-center">
  <div className="text-center mt-4">
    <h2 className="insurance-section-title">Pick the Type of Insurance</h2>
  </div>
  {insuranceTypes.map((type, index) => (
    <div
      key={index}
      className="insurance-card col-6 col-md-3 text-center p-3"
      onClick={() => window.location.href = type.route}
    >
      <div className="card-content">
        <img src={type.image} alt={type.name} className="insurance-icon img-fluid" />
        <h5 className="insurance-title">{type.name}</h5>
        <button className="get-quotes-btn">GET QUOTES</button>
      </div>
    </div>
  ))}
</div>

            <div
                className="associated-with-section text-center"
                style={{
                    backgroundColor: '#FFF2DA',
                    width: '100%',
                    height: '230px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <h3 className="associated-title" style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                    ASSOCIATED WITH
                </h3>
                <div className="row justify-content-center">
                    <div className="col-6 col-md-2">
                        <img src={mmd1Image} alt="MMD 1" className="associated-img img-fluid" />
                    </div>
                    <div className="col-6 col-md-2">
                        <img src={mmd2Image} alt="MMD 2" className="associated-img img-fluid" />
                    </div>
                    <div className="col-6 col-md-2">
                        <img src={mmd3Image} alt="MMD 3" className="associated-img img-fluid" />
                    </div>
                    <div className="col-6 col-md-2">
                        <img src={mmd4Image} alt="MMD 4" className="associated-img img-fluid" />
                    </div>
                </div>
            </div>
            <div className="why-make-my-documents text-center my-5">
                <h3 className="mb-4" style={{ color: '#007BFF', fontWeight: 'bold' }}>Why Make My Documents?</h3>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-4 text-center">
                        <img src={oneImage} alt="Complete Online Process" className="mb-3 feature-img img-fluid" />
                        <h5 style={{ fontWeight: 'bold' }}>COMPLETE ONLINE PROCESS</h5>
                        <p>Upload the documents as mentioned and leave the rest to us.</p>
                    </div>
                    <div className="col-12 col-md-4 text-center">
                        <img src={twoImage} alt="Dedicated Team" className="mb-3 feature-img img-fluid" />
                        <h5 style={{ fontWeight: 'bold' }}>DEDICATED TEAM</h5>
                        <p>Prompt notifications on every stage of visa.</p>
                    </div>
                    <div className="col-12 col-md-4 text-center">
                        <img src={threeImage} alt="99% Approval Rate" className="mb-3 feature-img img-fluid" />
                        <h5 style={{ fontWeight: 'bold' }}>99% APPROVAL RATE</h5>
                        <p>Based on visas processed so far.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InsurancePage;
