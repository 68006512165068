import React from 'react';
// import './InsurancePage.css'; 
import { Link } from 'react-router-dom';
import vehiclesIcon from '../../images/Visa_image1.svg';
import oneImage from '../../images/1.svg';
import twoImage from '../../images/2.svg';
import threeImage from '../../images/3.svg';
import visaImage2 from '../../images/Visa_image2.svg';
import '../visa/TravelVisa.css';
import arabImage from '../../images/Group.svg';
import unitedarab from '../../images/arab.svg'
import unitedflag from '../../images/arabflag.svg'
import singapur from '../../images/singapur.svg'
import singapurflag from '../../images/singapur-flag.svg'
import unitedkingdom from '../../images/uk.svg'
import ukflag from '../../images/uk-flag.svg'
import australia from '../../images/australia.svg'
import australiaflag from '../../images/australia-flag.svg'
import malaysia from '../../images/malysia.svg';
import malaysiaflag from '../../images/malaysia-flag.svg'
import egypt from '../../images/egypt.svg'
import egyptflag from '../../images/egypt-flag.svg'
import vietnam from '../../images/vietnam.svg'
import vietnamflag from '../../images/vietnm-flag.svg'
import hangkong from '../../images/hangkong.svg'
import hangkongflag from '../../images/hangkong-flag.svg'
import indonesia from '../../images/indonesia.svg'
import indonesiaflag from '../../images/indonesia-flag.svg'
import azerbaijan from '../../images/azerbijan.svg';
import azerbaijanflag from '../../images/azerbijan-flag.svg'
import Oman from '../../images/oman.svg';
import omanflag from '../../images/oman-flag.svg';
import morocco from '../../images/moroco.svg';
import moroccoflag from '../../images/moroco-flag.svg'
import baharin from '../../images/baharin.svg';
import baharinflag from '../../images/baharin-flag.svg';
import qatar from '../../images/qatar.svg';
import qatarflag from '../../images/qatar-flag.svg';
import russia from '../../images/russia.svg';
import russiaflag from '../../images/russia-flag.svg';
import uzbekistan from '../../images/uzerbekstan.svg';
import uzbekistanflag from '../../images/uzerbekstan-flag.svg'



const TravelVisa = () => {
  return (
    <div
      className="container-fluid"
      style={{
        background: 'linear-gradient(182.42deg, #FCA505 2.01%, #FFFFFF)',
        minHeight: '65vh',
        position: 'relative',
      }}
    >
      <div
        className="row justify-content-start align-items-center"
        style={{ minHeight: '100%' }}
      >
        <div className="col-12 col-md-6 text-left">
          <div className="text-content">
            <h1 style={{ color: '#1A76D8' }}>We Make Visas Easy For You</h1>
          </div>
        </div>

        <div className="col-12 col-md-6 text-center">
          <img
            src={vehiclesIcon}
            alt="Vehicles and Phone"
            className="img-fluid"
            style={{ maxWidth: '82%' }}
          />
          <img
            src={visaImage2}
            alt="Visa Image 2"
            className="img-fluid"
            style={{ marginTop: '-145px', maxWidth: '81%' }}
          />
        </div>
      </div>

      {/* Destination Cards */}
      <div className="destination-cards-container row justify-content-center">
      
      <div className="row justify-content-center" style={{ marginTop: "28px" }}>
  {/* UAE Card */}
  <div className="destination-card col-12 col-md-4 text-center" style={{ position: "relative" }}>
    <img
      src={arabImage}
      alt="UAE Destination"
      className="img-fluid"
      style={{ width: "100%", height: "auto" }}
    />
    <img
      src={unitedarab}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "27%",  
        top: "-41%",    
        width: "60%",
        height: "139px", 
        zIndex: 1      
      }}
    />
    <img
      src={unitedflag}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "20%",  
        top: "-54%",    
        height: "28px", 
        zIndex: 1      
      }}
    />
    
    {/* Paragraph below the second flag */}
    <p style={{
      position: "relative", 
      right: "-9%", 
      top: "-65%", 
      fontSize: "16px", 
      fontWeight: "bold"
    }}>
      United Arab Emirates
    </p>

    {/* Apply Now Button */}
    <button 
  className="apply-now-btn"
  style={{
    position: "absolute", 
    right: "28%", 
    bottom: "200px", 
    backgroundColor: "#FF9800", 
    color: "#fff", 
    padding: "15px 10px", 
    border: "none", 
    borderRadius: "8px"
  }}
>
  <Link to="/destination" style={{ color: "white", textDecoration: "none" }}>APPLY NOW</Link>
</button>
  </div>

  {/* Singapore Card */}
  <div className="destination-card col-12 col-md-4 text-center" style={{ position: "relative" }}>
    <img
      src={arabImage}
      alt="UAE Destination"
      className="img-fluid"
      style={{ width: "100%", height: "auto" }}
    />
    <img
      src={singapur}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "27%",  
        top: "-41%",    
        width: "60%",
        height: "139px", 
        zIndex: 1      
      }}
    />
    <img
      src={singapurflag}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "20%",  
        top: "-54%",    
        height: "28px", 
        zIndex: 1      
      }}
    />
    
    {/* Paragraph below the second flag */}
    <p style={{
      position: "relative", 
      right: "-9%", 
      top: "-65%", 
      fontSize: "16px", 
      fontWeight: "bold"
    }}>
      Singapore
    </p>

    {/* Apply Now Button */}
    <button 
      className="apply-now-btn"
      style={{
        position: "absolute", 
        right: "28%", 
        bottom: "200px", 
        backgroundColor: "#FF9800", 
        color: "#fff", 
        padding: "15px 10px", 
        border: "none", 
        borderRadius: "8px"
      }}
    >
     <Link to="/destination" style={{ color: "white", textDecoration: "none" }}>APPLY NOW</Link>
    </button>
  </div>

  {/* UK Card */}
  <div className="destination-card col-12 col-md-4 text-center" style={{ position: "relative" }}>
    <img
      src={arabImage}
      alt="UAE Destination"
      className="img-fluid"
      style={{ width: "100%", height: "auto" }}
    />
    <img
      src={unitedkingdom}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "27%",  
        top: "-41%",    
        width: "60%",
        height: "139px", 
        zIndex: 1      
      }}
    />
    <img
      src={ukflag}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "20%",  
        top: "-54%",    
        height: "28px", 
        zIndex: 1      
      }}
    />
    
    {/* Paragraph below the second flag */}
    <p style={{
      position: "relative", 
      right: "-9%", 
      top: "-65%", 
      fontSize: "16px", 
      fontWeight: "bold"
    }}>
      United kingdom
    </p>

    {/* Apply Now Button */}
    <button 
      className="apply-now-btn"
      style={{
        position: "absolute", 
        right: "28%", 
        bottom: "200px", 
        backgroundColor: "#FF9800", 
        color: "#fff", 
        padding: "15px 10px", 
        border: "none", 
        borderRadius: "8px"
      }}
    >
 <Link to="/destination" style={{ color: "white", textDecoration: "none" }}>APPLY NOW</Link>
    </button>
  </div>
</div>

        <br></br>
        <div className="row justify-content-center" style={{marginTop:"-10%"}}>
          {/* UAE Card */}
          <div className="destination-card col-12 col-md-4 text-center" style={{ position: "relative" }}>
    <img
      src={arabImage}
      alt="UAE Destination"
      className="img-fluid"
      style={{ width: "100%", height: "auto" }}
    />
    <img
      src={australia}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "27%",  
        top: "-41%",    
        width: "60%",
        height: "139px", 
        zIndex: 1      
      }}
    />
    <img
      src={australiaflag}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "20%",  
        top: "-54%",    
        height: "28px", 
        zIndex: 1      
      }}
    />
    
    {/* Paragraph below the second flag */}
    <p style={{
      position: "relative", 
      right: "-9%", 
      top: "-65%", 
      fontSize: "16px", 
      fontWeight: "bold"
    }}>
      Australia
    </p>

    {/* Apply Now Button */}
    <button 
      className="apply-now-btn"
      style={{
        position: "absolute", 
        right: "28%", 
        bottom: "200px", 
        backgroundColor: "#FF9800", 
        color: "#fff", 
        padding: "15px 10px", 
        border: "none", 
        borderRadius: "8px"
      }}
    >
 <Link to="/destination" style={{ color: "white", textDecoration: "none" }}>APPLY NOW</Link>
    </button>
  </div>



          {/* Singapore Card */}
          <div className="destination-card col-12 col-md-4 text-center" style={{ position: "relative" }}>
    <img
      src={arabImage}
      alt="UAE Destination"
      className="img-fluid"
      style={{ width: "100%", height: "auto" }}
    />
    <img
      src={malaysia}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "27%",  
        top: "-41%",    
        width: "60%",
        height: "139px", 
        zIndex: 1      
      }}
    />
    <img
      src={malaysiaflag}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "20%",  
        top: "-54%",    
        height: "28px", 
        zIndex: 1      
      }}
    />
    
    {/* Paragraph below the second flag */}
    <p style={{
      position: "relative", 
      right: "-9%", 
      top: "-65%", 
      fontSize: "16px", 
      fontWeight: "bold"
    }}>
      Malaysia
    </p>

    {/* Apply Now Button */}
    <button 
      className="apply-now-btn"
      style={{
        position: "absolute", 
        right: "28%", 
        bottom: "200px", 
        backgroundColor: "#FF9800", 
        color: "#fff", 
        padding: "15px 10px", 
        border: "none", 
        borderRadius: "8px"
      }}
    >
      <Link to="/destination" style={{ color: "white", textDecoration: "none" }}>APPLY NOW</Link>
    </button>
  </div>

          {/* UK Card */}
          <div className="destination-card col-12 col-md-4 text-center" style={{ position: "relative" }}>
    <img
      src={arabImage}
      alt="UAE Destination"
      className="img-fluid"
      style={{ width: "100%", height: "auto" }}
    />
    <img
      src={egypt}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "27%",  
        top: "-41%",    
        width: "60%",
        height: "139px", 
        zIndex: 1      
      }}
    />
    <img
      src={egyptflag}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "20%",  
        top: "-54%",    
        height: "28px", 
        zIndex: 1      
      }}
    />
    
    {/* Paragraph below the second flag */}
    <p style={{
      position: "relative", 
      right: "-9%", 
      top: "-65%", 
      fontSize: "16px", 
      fontWeight: "bold"
    }}>
     Egypt
    </p>

    {/* Apply Now Button */}
    <button 
      className="apply-now-btn"
      style={{
        position: "absolute", 
        right: "28%", 
        bottom: "200px", 
        backgroundColor: "#FF9800", 
        color: "#fff", 
        padding: "15px 10px", 
        border: "none", 
        borderRadius: "8px"
      }}
    >
      <Link to="/destination" style={{ color: "white", textDecoration: "none" }}>APPLY NOW</Link>
    </button>
  </div>
        </div>
        <br></br>
        <div className="row justify-content-center" style={{marginTop:"-10%"}}>
          {/* UAE Card */}
          <div className="destination-card col-12 col-md-4 text-center" style={{ position: "relative" }}>
    <img
      src={arabImage}
      alt="UAE Destination"
      className="img-fluid"
      style={{ width: "100%", height: "auto" }}
    />
    <img
      src={vietnam}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "27%",  
        top: "-41%",    
        width: "60%",
        height: "139px", 
        zIndex: 1      
      }}
    />
    <img
      src={vietnamflag}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "20%",  
        top: "-54%",    
        height: "28px", 
        zIndex: 1      
      }}
    />
    
    {/* Paragraph below the second flag */}
    <p style={{
      position: "relative", 
      right: "-9%", 
      top: "-65%", 
      fontSize: "16px", 
      fontWeight: "bold"
    }}>
     Vietnam
    </p>

    {/* Apply Now Button */}
    <button 
      className="apply-now-btn"
      style={{
        position: "absolute", 
        right: "28%", 
        bottom: "200px", 
        backgroundColor: "#FF9800", 
        color: "#fff", 
        padding: "15px 10px", 
        border: "none", 
        borderRadius: "8px"
      }}
    >
       <Link to="/destination" style={{ color: "white", textDecoration: "none" }}>APPLY NOW</Link>
    </button>
  </div>
          <div className="destination-card col-12 col-md-4 text-center" style={{ position: "relative" }}>
    <img
      src={arabImage}
      alt="UAE Destination"
      className="img-fluid"
      style={{ width: "100%", height: "auto" }}
    />
    <img
      src={hangkong}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "27%",  
        top: "-41%",    
        width: "60%",
        height: "139px", 
        zIndex: 1      
      }}
    />
    <img
      src={hangkongflag}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "20%",  
        top: "-54%",    
        height: "28px", 
        zIndex: 1      
      }}
    />
    
    {/* Paragraph below the second flag */}
    <p style={{
      position: "relative", 
      right: "-9%", 
      top: "-65%", 
      fontSize: "16px", 
      fontWeight: "bold"
    }}>
     Hong Kong 
    </p>

    {/* Apply Now Button */}
    <button 
      className="apply-now-btn"
      style={{
        position: "absolute", 
        right: "28%", 
        bottom: "200px", 
        backgroundColor: "#FF9800", 
        color: "#fff", 
        padding: "15px 10px", 
        border: "none", 
        borderRadius: "8px"
      }}
    >
     <Link to="/destination" style={{ color: "white", textDecoration: "none" }}>APPLY NOW</Link>
    </button>
  </div>

          {/* UK Card */}
          <div className="destination-card col-12 col-md-4 text-center" style={{ position: "relative" }}>
    <img
      src={arabImage}
      alt="UAE Destination"
      className="img-fluid"
      style={{ width: "100%", height: "auto" }}
    />
    <img
      src={indonesia}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "27%",  
        top: "-41%",    
        width: "60%",
        height: "139px", 
        zIndex: 1      
      }}
    />
    <img
      src={indonesiaflag}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "20%",  
        top: "-54%",    
        height: "28px", 
        zIndex: 1      
      }}
    />
    
    {/* Paragraph below the second flag */}
    <p style={{
      position: "relative", 
      right: "-9%", 
      top: "-65%", 
      fontSize: "16px", 
      fontWeight: "bold"
    }}>
      Indonesia
    </p>

    {/* Apply Now Button */}
    <button 
      className="apply-now-btn"
      style={{
        position: "absolute", 
        right: "28%", 
        bottom: "200px", 
        backgroundColor: "#FF9800", 
        color: "#fff", 
        padding: "15px 10px", 
        border: "none", 
        borderRadius: "8px"
      }}
    >
     <Link to="/destination" style={{ color: "white", textDecoration: "none" }}>APPLY NOW</Link>
    </button>
  </div>
        </div>
        <br></br>
        <div className="row justify-content-center" style={{marginTop:"-10%"}}>
        <div className="destination-card col-12 col-md-4 text-center" style={{ position: "relative" }}>
    <img
      src={arabImage}
      alt="UAE Destination"
      className="img-fluid"
      style={{ width: "100%", height: "auto" }}
    />
    <img
      src={azerbaijan}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "27%",  
        top: "-41%",    
        width: "60%",
        height: "139px", 
        zIndex: 1      
      }}
    />
    <img
      src={azerbaijanflag}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "20%",  
        top: "-54%",    
        height: "28px", 
        zIndex: 1      
      }}
    />
    
    {/* Paragraph below the second flag */}
    <p style={{
      position: "relative", 
      right: "-9%", 
      top: "-65%", 
      fontSize: "16px", 
      fontWeight: "bold"
    }}>
      Azerbaijan
    </p>

    {/* Apply Now Button */}
    <button 
      className="apply-now-btn"
      style={{
        position: "absolute", 
        right: "28%", 
        bottom: "200px", 
        backgroundColor: "#FF9800", 
        color: "#fff", 
        padding: "15px 10px", 
        border: "none", 
        borderRadius: "8px"
      }}
    >
     <Link to="/destination" style={{ color: "white", textDecoration: "none" }}>APPLY NOW</Link>
    </button>
  </div>



          {/* Singapore Card */}
          <div className="destination-card col-12 col-md-4 text-center" style={{ position: "relative" }}>
    <img
      src={arabImage}
      alt="UAE Destination"
      className="img-fluid"
      style={{ width: "100%", height: "auto" }}
    />
    <img
      src={Oman}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "27%",  
        top: "-41%",    
        width: "60%",
        height: "139px", 
        zIndex: 1      
      }}
    />
    <img
      src={omanflag}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "20%",  
        top: "-54%",    
        height: "28px", 
        zIndex: 1      
      }}
    />
    
    {/* Paragraph below the second flag */}
    <p style={{
      position: "relative", 
      right: "-9%", 
      top: "-65%", 
      fontSize: "16px", 
      fontWeight: "bold"
    }}>
      Oman
    </p>

    {/* Apply Now Button */}
    <button 
      className="apply-now-btn"
      style={{
        position: "absolute", 
        right: "28%", 
        bottom: "200px", 
        backgroundColor: "#FF9800", 
        color: "#fff", 
        padding: "15px 10px", 
        border: "none", 
        borderRadius: "8px"
      }}
    >
     <Link to="/destination" style={{ color: "white", textDecoration: "none" }}>APPLY NOW</Link>
    </button>
  </div>

          {/* UK Card */}
          <div className="destination-card col-12 col-md-4 text-center" style={{ position: "relative" }}>
    <img
      src={arabImage}
      alt="UAE Destination"
      className="img-fluid"
      style={{ width: "100%", height: "auto" }}
    />
    <img
      src={morocco}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "27%",  
        top: "-41%",    
        width: "60%",
        height: "139px", 
        zIndex: 1      
      }}
    />
    <img
      src={moroccoflag}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "20%",  
        top: "-54%",    
        height: "28px", 
        zIndex: 1      
      }}
    />
    
    {/* Paragraph below the second flag */}
    <p style={{
      position: "relative", 
      right: "-9%", 
      top: "-65%", 
      fontSize: "16px", 
      fontWeight: "bold"
    }}>
      Morocco
    </p>

    {/* Apply Now Button */}
    <button 
      className="apply-now-btn"
      style={{
        position: "absolute", 
        right: "28%", 
        bottom: "200px", 
        backgroundColor: "#FF9800", 
        color: "#fff", 
        padding: "15px 10px", 
        border: "none", 
        borderRadius: "8px"
      }}
    >
     <Link to="/destination" style={{ color: "white", textDecoration: "none" }}>APPLY NOW</Link>
    </button>
  </div>
        </div>
        <br></br>
        <div className="row justify-content-center" style={{marginTop:"-10%"}}>
        <div className="destination-card col-12 col-md-4 text-center" style={{ position: "relative" }}>
    <img
      src={arabImage}
      alt="UAE Destination"
      className="img-fluid"
      style={{ width: "100%", height: "auto" }}
    />
    <img
      src={baharin}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "27%",  
        top: "-41%",    
        width: "60%",
        height: "139px", 
        zIndex: 1      
      }}
    />
    <img
      src={baharinflag}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "20%",  
        top: "-54%",    
        height: "28px", 
        zIndex: 1      
      }}
    />
    
    {/* Paragraph below the second flag */}
    <p style={{
      position: "relative", 
      right: "-9%", 
      top: "-65%", 
      fontSize: "16px", 
      fontWeight: "bold"
    }}>
      Bahrain
    </p>

    {/* Apply Now Button */}
    <button 
      className="apply-now-btn"
      style={{
        position: "absolute", 
        right: "28%", 
        bottom: "200px", 
        backgroundColor: "#FF9800", 
        color: "#fff", 
        padding: "15px 10px", 
        border: "none", 
        borderRadius: "8px"
      }}
    >
    <Link to="/destination" style={{ color: "white", textDecoration: "none" }}>APPLY NOW</Link>
    </button>
  </div>



          {/* Singapore Card */}
          <div className="destination-card col-12 col-md-4 text-center" style={{ position: "relative" }}>
    <img
      src={arabImage}
      alt="UAE Destination"
      className="img-fluid"
      style={{ width: "100%", height: "auto" }}
    />
    <img
      src={qatar}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "27%",  
        top: "-41%",    
        width: "60%",
        height: "139px", 
        zIndex: 1      
      }}
    />
    <img
      src={qatarflag}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "20%",  
        top: "-54%",    
        height: "28px", 
        zIndex: 1      
      }}
    />
    
    {/* Paragraph below the second flag */}
    <p style={{
      position: "relative", 
      right: "-9%", 
      top: "-65%", 
      fontSize: "16px", 
      fontWeight: "bold"
    }}>
      Qatar
    </p>

    {/* Apply Now Button */}
    <button 
      className="apply-now-btn"
      style={{
        position: "absolute", 
        right: "28%", 
        bottom: "200px", 
        backgroundColor: "#FF9800", 
        color: "#fff", 
        padding: "15px 10px", 
        border: "none", 
        borderRadius: "8px"
      }}
    >
   <Link to="/destination" style={{ color: "white", textDecoration: "none" }}>APPLY NOW</Link>
    </button>
  </div>

          {/* UK Card */}
          <div className="destination-card col-12 col-md-4 text-center" style={{ position: "relative" }}>
    <img
      src={arabImage}
      alt="UAE Destination"
      className="img-fluid"
      style={{ width: "100%", height: "auto" }}
    />
    <img
      src={russia}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "27%",  
        top: "-41%",    
        width: "60%",
        height: "139px", 
        zIndex: 1      
      }}
    />
    <img
      src={russiaflag}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "20%",  
        top: "-54%",    
        height: "28px", 
        zIndex: 1      
      }}
    />
    
    {/* Paragraph below the second flag */}
    <p style={{
      position: "relative", 
      right: "-9%", 
      top: "-65%", 
      fontSize: "16px", 
      fontWeight: "bold"
    }}>
      Russia
    </p>

    {/* Apply Now Button */}
    <button 
      className="apply-now-btn"
      style={{
        position: "absolute", 
        right: "28%", 
        bottom: "200px", 
        backgroundColor: "#FF9800", 
        color: "#fff", 
        padding: "15px 10px", 
        border: "none", 
        borderRadius: "8px"
      }}
    >
    <Link to="/destination" style={{ color: "white", textDecoration: "none" }}>APPLY NOW</Link>
    </button>
  </div>
        </div>
        <br></br>
        <div className="row justify-content-center" style={{marginTop:"-10%"}}>

        <div className="destination-card col-12 col-md-4 text-center" style={{ position: "relative" }}>
    <img
      src={arabImage}
      alt="UAE Destination"
      className="img-fluid"
      style={{ width: "100%", height: "auto" }}
    />
    <img
      src={uzbekistan}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "27%",  
        top: "-41%",    
        width: "60%",
        height: "139px", 
        zIndex: 1      
      }}
    />
    <img
      src={uzbekistanflag}  
      alt="United Arab Flag"
      style={{
        position: "relative", 
        right: "20%",  
        top: "-54%",    
        height: "28px", 
        zIndex: 1      
      }}
    />
    
    {/* Paragraph below the second flag */}
    <p style={{
      position: "relative", 
      right: "-9%", 
      top: "-65%", 
      fontSize: "16px", 
      fontWeight: "bold"
    }}>
     Uzbekistan 
    </p>

    {/* Apply Now Button */}
    <button 
      className="apply-now-btn"
      style={{
        position: "absolute", 
        right: "28%", 
        bottom: "200px", 
        backgroundColor: "#FF9800", 
        color: "#fff", 
        padding: "15px 10px", 
        border: "none", 
        borderRadius: "8px"
      }}
    >
    <Link to="/destination" style={{ color: "white", textDecoration: "none" }}>APPLY NOW</Link>
    </button>
  </div>
        </div>
        <br></br>
      </div>

      {/* Why Choose Section */}
      <div className="why-make-my-documents text-center my-10">
        <h3
          className="mb-4"
          style={{ color: '#007BFF', fontWeight: 'bold' }}
        >
          Why Make My Documents?
        </h3>
        <div className="row justify-content-center">
          <div className="col-12 col-md-4 text-center">
            <img
              src={oneImage}
              alt="Complete Online Process"
              className="mb-3 feature-img img-fluid"
            />
            <h5 style={{ fontWeight: 'bold' }}>COMPLETE ONLINE PROCESS</h5>
            <p>Upload the documents as mentioned and leave the rest to us.</p>
          </div>
          <div className="col-12 col-md-4 text-center">
            <img
              src={twoImage}
              alt="Dedicated Team"
              className="mb-3 feature-img img-fluid"
            />
            <h5 style={{ fontWeight: 'bold' }}>DEDICATED TEAM</h5>
            <p>Prompt notifications on every stage of visa.</p>
          </div>
          <div className="col-12 col-md-4 text-center">
            <img
              src={threeImage}
              alt="99% Approval Rate"
              className="mb-3 feature-img img-fluid"
            />
            <h5 style={{ fontWeight: 'bold' }}>99% APPROVAL RATE</h5>
            <p>Based on visas processed so far.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelVisa;
