import React, { useState } from 'react';
import Image30 from '../../images/msme-image.svg'; 
import circleIcon from '../../images/circle1.svg'; 
import documentsIcon from '../../images/documents.svg'; 
import howIcon from '../../images/Time.svg'; 
import Price from '../../images/Price Tag.svg';
// import Affidavit from '../senior/SeniorCitizen';

const Msme = () => {
    
    const [openIndex, setOpenIndex] = useState(null);
    const [current, setCurrent] = useState(1); 
    const [showPopup, setShowPopup] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [isCompleted, setIsCompleted] = useState(false);
    const closePopup = () => {
        setShowPopup(false);
        setCurrentStep(1);
        setIsCompleted(false);
      };
    
      // Navigate steps
      const nextStep = () => {
        if (currentStep < 2) setCurrentStep(currentStep + 1);
      };
    
      const prevStep = () => {
        if (currentStep > 1) setCurrentStep(currentStep - 1);
      };

      const finishSubmission = () => {
        setIsCompleted(true); 
      };

      const [otp, setOtp] = useState(["", "", "", ""]);
  const [resendCountdown, setResendCountdown] = useState(30); 
  const [isResending, setIsResending] = useState(false);

  // Handle OTP input
  const handleChange = (value, index) => {
    if (!/^\d*$/.test(value)) return; 
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically move to the next input
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };


  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);
    }
  };

  // Resend OTP functionality
  const handleResend = () => {
    setIsResending(true);
    setTimeout(() => {
      setResendCountdown(30); // Reset countdown
      setIsResending(false);
    }, 1000); // Simulate resend delay
  };

  // Verify OTP
  const handleVerify = () => {
    const enteredOtp = otp.join("");
    if (enteredOtp.length === 4) {
      alert(`OTP Verified: ${enteredOtp}`);
    } else {
      alert("Please enter a valid 4-digit OTP.");
    }
  };

  // Countdown timer for resend button
  React.useEffect(() => {
    if (resendCountdown > 0) {
      const timer = setInterval(() => setResendCountdown(resendCountdown - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [resendCountdown]);



      const faqs = [
        {
            question: "Benefits of Lease Agreement?",
            answer: (
              <ul style={{ listStyleType: 'disc' }}>
                <li>Minimizes the conflicts between a tenant and the owner</li>
                <li>Rental/lease agreement acts as an address proof</li>
                <li>Acts as a proof for Bank loans</li>
                <li>Helps in investment & loan</li>
                <li>Vehicle registration</li>
              </ul>
            ),
          },
        {
            question: "Difference between comprehensive and third-party liability?",
            answer: "Comprehensive insurance covers both third-party liabilities and damage to your own vehicle, while third-party liability only covers damage caused to others.",
        },
        {
            question: "What is the different between rental/lease agreement?",
            answer: "Leasing is defined as a contract between lessor and lessee whereby the lessor buys the asset and lets the lessee use the asset for a particular period. Renting is to allow the other party to occupy or use the asset for a short time, in return for a fixed payment.",
        },
        {
            question: "what is stamp paper?",
            answer: "Stamp papers which are used for execution of documents are called as Non-Judicial Stamp Papers. ... Stamp Duty paid in respect of Non-Judicial Stamp Paper is paid under The Indian Stamp Act, 1899 and Stamp Duty paid in respect of Judicial Stamp Paper is paid under the Court Fees Act, 1870",
        },
        {
            question: "Do I need to visit any office to get lease agreement?",
            answer: "No its completely online process once you registered in our website we create draft and it will be print on stamp paper, and it get dispatched to address.",
        },
        {
            question: "How will I get my lease agreement?",
            answer: "It will get dispatched through courier.",
        },
        {
            question: "What is the value of stamp paper for lease agreement?",
            answer: "The Rental/Lease agreement must be printed on a Non-Judicial Stamp Paper with a value of Rs.100/- or more.",
        },
        {
            question: "What is validity of lease agreement?",
            answer: "In the usual practice, a landlord and a tenant make a rent agreement for a period of 11 months, with an option for a periodic renewal.",
        },
        {
            question: "what is Notarized lease agreement?",
            answer: "A notarized agreement is a document that has been marked with a notary stamp, which indicates that the signature on the document is legal. A notary officers is the witness when you sign and then places the stamp near your signature.",
        },
        {
            question: "Is agreement required to be notarised?",
            answer: "A contract typically does not have to be notarized. A notary public  provides an acknowledgment that the signature appearing on the document is that of the person whose signature it purports to be. There is a requirement that some documents be notarized, such as a real property deed.",
        },
    ];

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };


    return (
        <>
          <div style={{
    background: 'linear-gradient(182.42deg, #FCA505 2.01%, #FFFFFF)',
    minHeight: '60vh',
    paddingTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 20px'
}}>
    <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold' }}>
        <h2 style={{ marginLeft: "25%", marginBottom: "60px", fontWeight: "bold" }}>
        MSME registration Online 
        </h2>
        
        {/* Form section */}
      
    </div>
    
    <div>
        <img src={Image30} alt="Lease Agreement" style={{ maxWidth: '100%', height: 'auto' }} />
    </div>
</div>

<div style={{ marginTop: '-37px', marginLeft:'171px' }}>
            <div style={{ marginBottom: '20px' }}>
                <label htmlFor="applyFor" style={{ display: 'block', fontWeight: 'bold',color: '#1A76D8',fontSize:"25px",  }}>
                 Documents Required for Citizen Card
                </label>
                <ul
        style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)', 
            columnGap: '40px', 
            rowGap: '20px', 
            listStyleType: 'disc', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
        <li style={{ fontWeight: '600' }}>Aadhar Card</li>
        <li style={{ fontWeight: '600' }}>Pan Card</li>
       
    </ul>
            </div>

          
        </div>

            <div
                className="content-section"
                style={{
                    backgroundColor: '#fffff',
                    padding: '30px 15px',
                    borderRadius: '10px',
                    margin: '3% auto',
                    marginRight:'72%',
                }}
            >
                <div className="row justify-content-center">
                    {/* Main Column for Vertical Layout */}
                    <div className="col-12 col-md-8 position-relative">
                        {/* First Section: Documents */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={documentsIcon}
                                    alt="Documents Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div> 
                        </div>
                    
           
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '100%',
                                // margin: '0 auto',
                                width: '4px',
                                marginTop:'-20%',
                                marginLeft:'50%'
                            }}
                        ></div>

                        {/* Second Section: How It Works */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={howIcon}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>

                        {/* Blue Line */}
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '150%',
                                // margin: '0 auto',
                                marginLeft:'49.8%',
                                marginTop:'-48%',
                                width: '4px',
                            }}
                        ></div>

                        {/* Third Section */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={Price}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>
                       
            
                    </div>
                </div>
             <>
             <div
    className="mb-5"
    style={{
        marginTop: '-45%',
        marginLeft: '70%',
    }}
>
    <h4
        style={{
            color: '#007BFF',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
        }}
    >
        How It Works
    </h4>
    <ul
        style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)', 
            columnGap: '40px', 
            rowGap: '20px', 
            listStyleType: 'disc', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
        <li style={{ fontWeight: 'bold' }}>Register Online</li>
        <li style={{ fontWeight: 'bold' }}>Upload Documents</li>
        <li style={{ fontWeight: 'bold' }}>Payment</li>
        <li style={{ fontWeight: 'bold' }}>Get delivered</li>
      
    </ul>
</div>
                <div style={{marginLeft:'72%',marginTop:'36%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>Time Duration</h4>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>1-2 working days</li>
                    </ul>
                </div>
                <div style={{marginLeft:'72%',marginTop:'53%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>Charges</h4>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Rs 500</li>
        <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Rs 99 as booking/consulting charge. Need to pay while submitting online form</li>
        {/* <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Note: Additional charges for stamp paper</li> */}
       
                    </ul>
                </div>
                </>
            </div>
            <div>
   
   {/* Get Quotes Button */}
   <div style={{ textAlign: "center", marginTop: "4%" }}>
     <button
       style={{
         backgroundColor: "#FCA505",
         color: "#000000",
         padding: "12px 50px",
         border: "none",
         borderRadius: "30px",
         fontWeight: "bold",
         cursor: "pointer",
         fontSize: "16px",
         marginRight:"40%",
         marginTop:"-30%",
       }}
       onClick={() => setShowPopup(true)}
     >
       CONTINUE
     </button>
   
   
 </div>
   {/* Modal Popup */}
   {showPopup && (
     <div
       style={{
         position: "fixed",
         top: "0",
         left: "0",
         width: "100%",
         height: "100%",
         backgroundColor: "#1A76D8", 
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         zIndex: "9999",
       }}
     >
       <div
         style={{
           backgroundColor: "#FFFFFF",
           padding: "40px",
           borderRadius: "28px",
           width: "70%",
           maxHeight: "90%", // Maximum height of the popup
           overflowY: "auto", // Enable scrolling if content overflows
           textAlign: "center",
           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
         }}
       >
         {/* Stepper */}
       

         {!isCompleted ? (
           <>
   <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
  {Array.from({ length: 2 }).map((_, index) => (
    <React.Fragment key={index}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <button
          onClick={() => setCurrentStep(index + 1)} // Make step clickable
          style={{
            width: "50px",
            height: "50px",
            backgroundColor: currentStep >= index + 1 ? "#4285F4" : "#ccc",
            color: "white",
            borderRadius: "50%",
            lineHeight: "50px",
            fontWeight: "bold",
            border: "none",
            cursor: "pointer",
            outline: "none",
          }}
        >
          {index + 1}
        </button>
        {/* Optional Step Labels */}
        {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step {index + 1}</span> */}
      </div>

      {/* Add Connection Divider Between Steps */}
      {index < 1 && (
        <div
          style={{
            height: "2px",
            flex: 1,
            backgroundColor: currentStep > index + 1 ? "#4285F4" : "#ccc",
            alignSelf: "center",
          }}
        />
      )}
    </React.Fragment>
  ))}
</div>



      
         <div style={{ marginBottom: "20px" }}>
 {currentStep === 1 && (
  <div style={{ textAlign: "center" }}>
    <h4 style={{ color: "#1A76D8", fontWeight: "600" }}>Application</h4>

    <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", marginBottom: "20px" ,textAlign:"start"}}>
      <div style={{ width: "45%" }}>
        <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
          Name<span style={{ color: "red" }}>*</span>
        </label>
        <input type="text" placeholder="Enter your name" style={{ width: "100%", padding: "8px", marginTop: "5px" ,   border: "2px solid #FCA505",
          borderRadius: "10px",}} />
      </div>
      <div style={{ width: "45%" }}>
        <label style={{ fontSize: "16px", fontWeight: "500", color: "#333"  , 
         }}>
          Aadhaar Number<span style={{ color: "red" }}>*</span>
        </label>
        <input type="text" placeholder="Enter your mobile number" style={{ width: "100%", padding: "8px", marginTop: "5px",   border: "2px solid #FCA505",
          borderRadius: "10px", }} />
      </div>
      
    </div>
            
        
        <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", marginBottom: "20px" ,textAlign:"start"}}>
      <div style={{ width: "45%" }}>
        <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
        Name of Enterprise/Business<span style={{ color: "red" }}>*</span>
        </label>
        <input type="text" placeholder="Enter your name" style={{ width: "100%", padding: "8px", marginTop: "5px" ,   border: "2px solid #FCA505",
          borderRadius: "10px",}} />
      </div>
      <div style={{ width: "45%" }}>
        <label style={{ fontSize: "16px", fontWeight: "500", color: "#333"  , 
         }}>
          Type of Organisation<span style={{ color: "red" }}>*</span>
        </label>
        <input type="text" placeholder="Enter your mobile number" style={{ width: "100%", padding: "8px", marginTop: "5px",   border: "2px solid #FCA505",
          borderRadius: "10px", }} />
      </div>
      
    </div>
    <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", marginBottom: "20px" ,textAlign:"start"}}>
      <div style={{ width: "45%" }}>
        <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
        Date of Incorporation / Registration<span style={{ color: "red" }}>*</span>
        </label>
        <input type="text" placeholder="Enter your name" style={{ width: "100%", padding: "8px", marginTop: "5px" ,   border: "2px solid #FCA505",
          borderRadius: "10px",}} />
      </div>
      <div style={{ width: "45%" }}>
        <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" ,whiteSpace:"none" , 
         }}>
          Business PAN Number (In case of proprietorship Submit owner's PAN number)<span style={{ color: "red" }}>*</span>
        </label>
        <input type="text" placeholder="Enter your mobile number" style={{ width: "100%", padding: "8px", marginTop: "5px",   border: "2px solid #FCA505",
          borderRadius: "10px", }} />
      </div>
      
    </div>
  </div>
)}




 {currentStep === 2 && (
   <div style={{ textAlign: "center" }}>
    {/* <h4 style={{ color: "#1A76D8", fontWeight: "600" }}>Application</h4> */}

    <div style={{ marginBottom: "20px", textAlign: "left" }}>
           <label
            //  htmlFor="ownerName"
             style={{
               display: "block",
               marginBottom: "10px",
               fontSize: "16px",
               fontWeight: "500",
             }}
           >
            Address
             <span style={{ color: "red" }}>*</span>
           </label>
           <input
             type="text"
             id=""
             placeholder=""
             style={{
               width: "82%",
               height: "80px",
               padding: "10px",
               fontSize: "16px",
               border: "2px solid #FCA505",
               borderRadius: "10px",
             }}
           />
         </div>





    {/* State and District */}
    <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", textAlign:"start" }}>
      <div style={{ width: "45%" }}>
        <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
          State<span style={{ color: "red" }}>*</span>
        </label>
        <select style={{ width: "100%", padding: "8px", marginTop: "5px",   border: "2px solid #FCA505",
          borderRadius: "10px", }}>
          {/* Add the list of states here */}
          <option value="">Select State</option>
          <option value="state1">State 1</option>
          <option value="state2">State 2</option>
          {/* More states */}
        </select>
      </div>
      <div style={{ width: "45%" }}>
        <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
          District<span style={{ color: "red" }}>*</span>
        </label>
        <select style={{ width: "100%", padding: "8px", marginTop: "5px",  border: "2px solid #FCA505",
          borderRadius: "10px", }}>
          {/* Add the list of districts here */}
          <option value="">Select District</option>
          <option value="district1">District 1</option>
          <option value="district2">District 2</option>
          {/* More districts */}
        </select>
      </div>
    </div>
                <br></br>
        {/* Name, Mobile Number, and Email */}
        <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", marginBottom: "20px" ,textAlign:"start"}}>
      <div style={{ width: "30%" }}>
        <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
          Pincode<span style={{ color: "red" }}>*</span>
        </label>
        <input type="text" placeholder="Enter your name" style={{ width: "100%", padding: "8px", marginTop: "5px" ,   border: "2px solid #FCA505",
          borderRadius: "10px",}} />
      </div>
      <div style={{ width: "30%" }}>
        <label style={{ fontSize: "18px", fontWeight: "500", color: "#333"  , 
         }}>
          Mobile Number<span style={{ color: "red" }}>*</span>
        </label>
        <input type="text" placeholder="Enter your mobile number" style={{ width: "100%", padding: "8px", marginTop: "5px",   border: "2px solid #FCA505",
          borderRadius: "10px", }} />
      </div>
      <div style={{ width: "30%" }}>
        <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
          Email ID<span style={{ color: "red" }}>*</span>
        </label>
        <input type="email" placeholder="Enter your email" style={{ width: "100%", padding: "8px", marginTop: "5px",   border: "2px solid #FCA505",
          borderRadius: "10px", }} />
      </div>
    </div>
  </div>
)}

         </div>
         <div>
          
           {currentStep < 2 ? (
             <button
               onClick={nextStep}
               style={{
                 padding: "10px 20px",
                 backgroundColor: "#FCA505",
                 color: "#000000",
                 border: "none",
                 borderRadius: "5px",
                 cursor: "pointer",
               }}
             >
               Next
             </button>
           ) : (
             <button
             onClick={finishSubmission}
               style={{
                 padding: "10px 20px",
                 backgroundColor: "#FCA505",
                 color: "#000000",
                 border: "none",
                 borderRadius: "5px",
                 cursor: "pointer",
               }}
             >
               NEXT
             </button>
           )} 
         </div>
         </>
         ) : (
            <div style={{ textAlign: "center", padding: "20px" }}>
            <h4 style={{ color: "#007BFF", fontWeight: "bold", display: "inline-block", marginRight: "10px" }}>
        OTP sent on 97******8
      </h4>
      <a
        href="#"
        onClick={() => setCurrent(1)} // Redirect to step 1
        style={{ fontSize: "14px", color: "#007BFF", textDecoration: "none" }}
      >
        Edit
      </a>
      
            <div style={{ margin: "20px 0" }}>
              <label style={{ display: "block", fontWeight: "500", marginBottom: "10px" }}>
                Enter OTP <span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleChange(e.target.value, index)}
                    onKeyDown={(e) => handleBackspace(e, index)}
                    style={{
                      width: "50px",
                      height: "50px",
                      textAlign: "center",
                      fontSize: "18px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  />
                ))}
              </div>
            </div>
      
            <p style={{ fontSize: "14px", color: "#888" }}>
              {resendCountdown > 0 ? (
                <>Not Received? Resend in {resendCountdown}s</>
              ) : (
                <a
                  href="#"
                  onClick={handleResend}
                  style={{
                    textDecoration: "none",
                    color: isResending ? "#ccc" : "#007BFF",
                    pointerEvents: isResending ? "none" : "auto",
                  }}
                >
                  Resend OTP
                </a>
              )}
            </p>
      
            <button
              onClick={handleVerify}
              style={{
                backgroundColor: "#FFA500",
                color: "#00000",
                border: "none",
                borderRadius: "5px",
                padding: "10px 20px",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              VERIFY
            </button>
          </div>
         )}

         {/* Close Button */}
         <button
           onClick={closePopup}
           style={{
             position: "absolute",
             top: "10px",
             right: "10px",
             background: "none",
             border: "none",
             fontSize: "20px",
             cursor: "pointer",
           }}
         >
           &times;
         </button>
       </div>
     </div>
   )}
 </div>

 <div className="faq-section" style={{ margin: '50px auto', padding: '20px', background: '#FFFFFF', borderRadius: '10px', width: '80%' }}>
            <h4 style={{ textAlign: 'center', fontWeight: 'bold', color: '#007BFF', marginBottom: '20px' }}>FAQs</h4>
            <p style={{ textAlign: 'center', fontWeight: '500', marginBottom: '30px' }}>Need help? Contact us for any queries related to us</p>
            <div className="faq-list">
                {faqs.map((faq, index) => (
                    <div key={index} className="faq-item" style={{ marginBottom: '10px' }}>
                        <button
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                padding: '10px 20px',
                                border: '1px solid #007BFF',
                                borderRadius: '5px',
                                background: '#F9F9F9',
                                fontWeight: '500',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleToggle(index)}
                        >
                            {faq.question}
                            <span style={{ fontWeight: 'bold', fontSize: '16px', marginLeft: '10px' }}>
                                {openIndex === index ? '▲' : '▼'}
                            </span>
                        </button>
                        {openIndex === index && (
                            <div
                                style={{
                                    marginTop: '10px',
                                    padding: '10px 20px',
                                    background: '#F3F3F3',
                                    borderRadius: '5px',
                                    color: '#333',
                                }}
                            >
                                {faq.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
<br></br>
        
        </div>

        
        
        </>
    );
};

export default Msme;
